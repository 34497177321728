"use strict";

const MainMap = function () {
  let map;
  let user;
  let currentBasemap;
  const groupList = {};
  let mapLayers = [];
  let blueDot = null;

  var init = function () {
    Tree.select("tool").on("update", async function (e) {
      if (Tree.get("tool") === "assets") {
        if (Tree.get("dataView") === "asset-management") {
          $("#bottomFloatingInputsTable").addClass("asset-management-only");
          $("#filter-row").addClass("asset-management-only");
        }
        await AssetManagementSummaryTableController.getAndSetAssetManagementAttributionPercentage();
      }

      loadMap();
      Table.loadListeners(mapLayers);
    });
  };

  var loadMap = function () {
    mapLayers = LayerFunctions.getDefaultMapLayers();
    resetMapLayers();
    MainMapController.initTool();

    user = Session.getUser();
    if (Session.isRegulator()) {
      loadRegulatorMap();
    } else {
      loadMs4Map();
    }

    initializeModules();
    Table.setDataColTooltips();
    NavToggleController.load(user);
    MapTitle.init();
    ModalMap.init();

    const defaultTab = DataViewFunctions.getCurrentDataViewProperty("defaultTab");
    if (defaultTab === "data") {
      FilterSummary.enable();
    }

    var tool = Tree.get("tool");
    if (tool === "bmpram" || tool === "trashram" || tool === "muni") {
      Benchmark.init();
    } else if (tool === "lid") {
      const ParcelAssessment = require("../lid/parcelAssessment");
      ParcelAssessment.init();
    } else if (tool === "assets") {
      AssetManagementWidgetToggle.showWidgetScreen();
    } else if (tool === "indcom") {
      MapFunctions.getPopupOptions();
    }
  };

  var loadMs4Map = function () {
    const basemap = BasemapFunctions.getBasemap("main", true);

    if (typeof map !== "undefined" && map != null) {
      clearMap();
    }
    map = createMap();
    addBasemap(basemap);
    setMapTreeVariables(map, false);
    loadLayerListeners();
    initCustomLayers();
    startZoomListeners();
    RegionDropdown.setRegionDropdownTitle(Tree.get(["activeGroup", "group"]));
    PopupContent.loadPopupListeners();
    initSettings();
    if (Tree.get("dataView") !== "properties") {
      ReportingTimelineController.init();
    }

    MapPaneFunctions.createCustomMapPanes(map);
  };

  var loadRegulatorMap = function () {
    const basemap = BasemapFunctions.getBasemap("main", true);

    handleRegulatorMapDisplay();
    if (typeof map !== "undefined" && map != null) clearMap();
    map = createMap();
    addBasemap(basemap);
    setMapTreeVariables(map, true);
    loadRegulatorMapListeners();
    loadRegulatorData();
    MapPaneFunctions.createCustomMapPanes(map);
  };

  var setMapTreeVariables = function (map, regulator) {
    const group = Tree.get("activeGroup");
    //temp conditional for orange/sc county re-factor by getting feature layer bounding box
    const zoomLevel = group.groupId == 50 || group.groupId == 37 ? 11 : regulator ? 8 : 14;
    if (group.latlng) {
      map.setView(group.latlng, zoomLevel);
      Tree.set("bbox", map.getBounds().toBBoxString());
    }
    Tree.set("mapCenter", map.getCenter());
    Tree.set("zoomLevel", zoomLevel);
    resetFcsDrainageHighlight();
    if (regulator) setRegulatorTreeVariables();
  };

  var resetFcsDrainageHighlight = function () {
    Tree.set(["layers", "fcs", "selectedId"], null);
  };

  var loadRegulatorMapListeners = function () {
    map.on("moveend zoomend", function () {
      Tree.set("mapCenter", map.getCenter());
      Tree.set("bbox", map.getBounds().toBBoxString());
      Tree.set("zoomLevel", map.getZoom());
    });
  };

  var loadGroupListData = function (data) {
    data.forEach(function (ms4) {
      groupList[ms4.group_id] = {
        groupId: ms4.group_id,
        latlng: [ms4.lat, ms4.lng],
        name: ms4.name,
      };
    });
  };

  var setRegulatorTreeVariables = function () {
    user = Session.getUser();
    Tree.set("spatialScale", "REGION");
    Tree.set(["filters", "statuses"], ["inventory_complete", "planned"]);
    Tree.set(["activeGroup", "name"], user.group);
    Tree.set(["activeGroup", "groupId"], user.trueGroupId);
  };

  var putRegulatorDataOnMap = function (data) {
    loadGroupListData(data);
    RegionDropdown.load(data);
    if (!mapLayers.regionalLayer) {
      loadRegulatorLayers();
      var geoJson = CleanData.cleanGeoJson(data);
      mapLayers.regionalLayer.clearLayers().addData(geoJson);
      Tree.set("MS4BoundaryFeatures", geoJson.features);
    }
    map.addLayer(mapLayers.regionalLayer);
    map.fitBounds(mapLayers.regionalLayer.getBounds());
    loadMS4Table();
  };

  var loadRegulatorData = function () {
    ApiCalls.getAllMS4s(putRegulatorDataOnMap);
  };

  var initSettings = function () {
    if (["trashram"].includes(Tree.get("tool"))) {
      Settings.init();
    }
  };

  var initCustomLayers = function () {
    const ToolSettings = require("../settings/toolSettings");
    ToolSettings.getSetting("customEsriLayerSettings")?.layers.forEach(function (layer) {
      const layerName = CustomLayer.getCustomLayerKey(layer);
      mapLayers[layerName] = null;
      CustomLayer.init("main", map, mapLayers, layer);
    });
  };

  var handleRegulatorMapDisplay = function () {
    $(".reporting-timeline").hide();
    $(".export").hide();
    $("#main-legend").hide();
    $(".action-button").hide();
    $(".filter-bar").hide();
    $(".ms4-view").hide();
    $(".regional-view").show();
    $("#toDoTab").parent().hide();
    $("#settingsTab").parent().hide();
    Misc.toggleDisabledInputs($(".checkbox"), true);
  };

  var resetMapLayers = function () {
    const layers = Tree.get("layers");
    Object.keys(layers).forEach(function (layer) {
      Tree.set(["layers", layer, "isEnabled"], false);
    });
    blueDot = null; //reset blue dot
    if (typeof map !== "undefined") clearMap();
  };

  var disableUserMapLayers = function () {
    var layersConfig = LayerFunctions.getPropertyForAllLayers("doNotReset");
    for (const layerName in layersConfig) {
      if (!layersConfig[layerName]) {
        const layerKey = LayerFunctions.getLayerKeyByLayerName(layerName);

        if (MapFunctions.mapHasLayer(map, mapLayers[layerKey]) || mapLayers[layerKey]) {
          Tree.set(["layers", layerName, "isEnabled"], false);
        }
      }
    }
  };

  var initializeModules = function () {
    DisplayOptions.loadLegendListeners();
    PopupPhotos.loadListeners();
    loadListeners();
    ReopenPopups.loadMapListeners(map);
    Mobile.loadMapListeners(map);
    UserSettingsFunctions.loadListeners();
    ActionDropdown.loadListeners();
    Panels.init();
    ListPanel.init();
    MapPanel.init();
  };

  var createMap = function (options = {}) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    const maxZoom = rasterBasemap ? 19 : 21;
    const thisMap = L.map("map", {
      zoomControl: false,
      tileLayer: {
        maxNativeZoom: maxZoom,
        maxZoom: maxZoom,
      },
      renderer: L.canvas({ pane: "markerPane" }),
      minZoom: 3,
      maxZoom: maxZoom,
      scrollWheelZoom: !options?.noScroll,
    });
    if (options?.noScroll) thisMap.gestureHandling.enable();
    MapFunctions.setMapHeight();
    const activeGroup = getActiveGroupData();
    if (activeGroup.latlng) thisMap.setView(activeGroup.latlng, 14);

    MapFunctions.addMapZoomControl(thisMap);
    startMapListener(thisMap);

    return thisMap;
  };

  // @TODO: remove startMapListener, disableNoZoomLayers, end nableNoZoomLayers when switching to vector basemaps
  // note: these functions are duplicated in 3 other places
  var startMapListener = function (map) {
    map.on("zoomend", function () {
      const zoom = map.getZoom();
      const rasterBasemap = FeatureFlag.enabled("raster-basemap");
      if (rasterBasemap) {
        const currentBasemap = BasemapFunctions.getBasemap("main");

        if (zoom > 16) {
          disableNoZoomLayers();
          if (
            currentBasemap !== "DarkGray" &&
            currentBasemap !== "Gray" &&
            currentBasemap !== "NationalGeographic"
          )
            return;
          Tree.set("basemapBeforeZoomMap", currentBasemap);
          BasemapFunctions.setBasemap("Imagery", "main");
        } else {
          enableNoZoomLayers();
          const basemapBeforeZoom = Tree.get("basemapBeforeZoomMap");
          if (basemapBeforeZoom) {
            BasemapFunctions.setBasemap(basemapBeforeZoom, "main");
          }
          Tree.set("basemapBeforeZoomMap", "");
        }
      }
    });
  };

  var disableNoZoomLayers = function () {
    $("#map-panel").find("[data-style='Gray']").addClass("disabled");
    $("#map-panel").find("[data-style='DarkGray']").addClass("disabled");
    $("#map-panel").find("[data-style='NationalGeographic']").addClass("disabled");
  };

  var enableNoZoomLayers = function () {
    $("#map-panel").find("[data-style='Gray']").removeClass("disabled");
    $("#map-panel").find("[data-style='DarkGray']").removeClass("disabled");
    $("#map-panel").find("[data-style='NationalGeographic']").removeClass("disabled");
  };

  var clearMap = function () {
    mapLayers = LayerFunctions.getDefaultMapLayers();
    map.eachLayer(function (layer) {
      map.removeLayer(layer);
    });
    map.remove();
    map = null;
  };

  var startZoomListeners = function () {
    map.on("moveend zoomend", function () {
      var zoomLevel = map.getZoom();
      Tree.set("zoomLevel", zoomLevel);
      Tree.set("mapCenter", map.getCenter());

      startLegendZoomListener(zoomLevel);
      handleZoomCatchmentPopups(zoomLevel);
    });
  };

  var startLegendZoomListener = function (zoomLevel) {
    const enabled = MapFunctions.isMuniScaleZoom(zoomLevel);

    //remove from map at zoom out past muni scale
    // @FLAG: Parent View Streams
    const isParentViewStreams = FeatureFlag.enabled("parent-view-streams");
    if ((isParentViewStreams || enabled) && (Session.isMmsd() || Session.isCwp())) {
      Misc.toggleDisabledInputs($("#check-bounds-main").parent(), false);
      Misc.toggleDisabledInputs($("#check-flow-routing-main").parent(), false);
      return;
    }
    Misc.toggleDisabledInputs($(".checkbox.disabled-zoomed-out"), !enabled);
  };

  var handleZoomCatchmentPopups = function (zoomLevel) {
    const dataView = Tree.get("dataView");
    if (zoomLevel > 15 && dataView !== "lid-runoff") {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").addClass(
        "non-interactive-catchment",
      );
    } else {
      $(".leaflet-catchment-pane, .leaflet-overCatchment-pane").removeClass(
        "non-interactive-catchment",
      );
    }
  };

  var loadListeners = function () {
    $("#bottomFloatingInputsTable").on("click", ".ms4-table li", ms4TableClick);
    $("#bottomFloatingInputsTable").on("dblclick", ".ms4-table li", ms4TableDoubleClick);
    $("#bottomFloatingInputsTable").on("mouseover", ".ms4-table li", ms4TableDoubleMouseover);
    $("#bottomFloatingInputsTable").on("mouseout", ".ms4-table li", ms4TableDoubleMouseout);
    $(window).on("resize", MapFunctions.setMapHeight);
    $("#main-header-collapse-container").on("shown.bs.collapse", MapFunctions.setMapHeight);
    $("#main-header-collapse-container").on("hidden.bs.collapse", MapFunctions.setMapHeight);
    setPopupGoToMS4ClickListener();
  };

  var setPopupGoToMS4ClickListener = function () {
    $("#mapContent").on("click", ".handleSpatialChange", popupGotoMS4Click);
  };

  var invalidateMapSize = function () {
    if (map) {
      map.invalidateSize();
    }
  };

  var initFitBounds = function () {
    if (Tree.get("initFitBounds")) {
      if (mapLayers?.boundsLayer) {
        if (map) {
          const firstBoundary = mapLayers.boundsLayer.getLayers()[0];
          map.fitBounds(firstBoundary.getBounds());
        }
      }
    }

    Tree.set("initFitBounds", false);
  };

  var showMs4ForRegulator = function () {
    if ($("#settingsTab").parent().hasClass("active")) {
      $("#dataTab").click();
    }
    $("input:checkbox[value=bounds]").prop("checked", true);
    $("#main-legend").show();
    $(".filter-bar").show();
    $("#dataTab").parent().show();
    $("#toDoTab").parent().hide();
    $("#settingsTab").parent().show();
    Misc.toggleDisabledInputs($(".checkbox"), false);
    Filters.disableTrashAssessmentLayers(); // @TODO: Figure out why we need to call this here
    Tree.set("spatialScale", "MS4");
    const activeGroup = getActiveGroupData();
    sessionStorage.activeGroupId = activeGroup.groupId;
    Tree.set("activeGroup", {
      groupId: activeGroup.groupId,
      name: activeGroup.name,
      lat: activeGroup.latlng[0],
      lng: activeGroup.latlng[1],
    });
    Tree.set("mapTitle", activeGroup.name);
  };

  var reloadBmpFcsLayer = function (loadingScreen = true) {
    const activeTabIsData = Tree.get("activeTab") === "data";

    if (Tree.get("tool") === "trashram") {
      if (Tree.get(["layers", "fcs", "isEnabled"])) {
        FcsLayer.loadLayer("main", loadingScreen);
      } else {
        invalidateLayerByName("fcs");
      }
    }
    if (Tree.get(["layers", "bmps", "isEnabled"])) {
      BmpLayer.loadLayer("main", loadingScreen);
    }
    if (Tree.get(["layers", "muniCatchBasin", "isEnabled"])) {
      MuniCatchBasinGeoServerLayer.invalidateLayerData(loadingScreen);
    }
    if (Tree.get(["layers", "muniBmp", "isEnabled"]) && activeTabIsData) {
      MuniBmpGeoServerLayer.invalidateLayerData(loadingScreen);
    }
    if (Tree.get(["layers", "lidBmp", "isEnabled"]) && activeTabIsData) {
      LidBmpGeoServerLayer.invalidateLayerData(loadingScreen);
    }
    if (Tree.get(["layers", "structuralBmps", "isEnabled"]) && activeTabIsData) {
      StructuralBmpsGeoServerLayer.invalidateLayerData(loadingScreen);
    }

    if (Tree.get("factSheet", "open") && !FactSheetNew.getId()) {
      CatchBasinFactSheetController.initFactSheet(Tree.get(["factSheet", "id"]));
    }

    if (Tree.get(["layers", "fullDrainageFcs", "isEnabled"])) {
      Actions.loadFullDrainageFcs();
    } else if (Tree.get(["layers", "fullDrainageBmp", "isEnabled"])) {
      Actions.loadFullDrainageBmp();
    }

    if (!DataViewFunctions.getCurrentDataViewProperty("isGisDataView")) {
      Actions.loadTodos(Tree.get("dataView"), Tree.get("filters"), false, loadingScreen);
    }

    if (Tree.get("dataView") === "dry-weather") {
      DryWeatherFunctions.invalidateAllDryWeatherLayersData();
    }
  };

  var reloadBmpFcsLayerWithoutLoadingScreen = function () {
    reloadBmpFcsLayer(false);
  };

  var getActiveGroupData = function () {
    var groupId = sessionStorage.activeGroupId;
    var loginData;
    if (groupId != -1 && groupId in groupList) {
      return groupList[groupId];
    } else {
      loginData = Session.getUser();
      return {
        name: loginData.group,
        latlng: loginData.latlng,
      };
    }
  };

  var loadRegulatorLayers = function () {
    mapLayers.regionalLayer = L.geoJson(null, {
      style: MapStyles.styleMS4Boundary,
      onEachFeature: function (feature, layer) {
        layer.on({
          mouseover: highlightMS4Feature,
          mouseout: resetHighlightMS4,
          click: zoomToMS4,
        });
        layer.bindPopup(nunjucks.render("popups/ms4.njk", feature.properties));
      },
    });
  };

  var highlightMS4Feature = function (e) {
    ms4TableDoubleMouseover(e.target.feature.properties.group_id);
    $("#" + e.target.feature.properties.group_id + "_li").addClass("table-highlight");
  };

  var resetHighlightMS4 = function (e) {
    mapLayers.regionalLayer.resetStyle(e.target);
    $("#" + e.target.feature.properties.group_id + "_li").removeClass("table-highlight");
  };

  var ms4TableClick = function () {
    const groupId = $(this).data("groupid");
    var selectedLayer = null;
    sessionStorage.activeGroupId = groupId;
    Tree.set(["activeGroup", "groupId"], groupId);
    Tree.set("spatialScale", "MS4");
    user = getActiveGroupData();

    $(".bottomFloatingInputsTable").find(".active").removeClass("active");
    $("#" + groupId + "_li").addClass("active");
    // highlight regional layer, get selected layer
    mapLayers.regionalLayer.eachLayer(function (layer) {
      if (layer.feature.properties.group_id == groupId) {
        layer.setStyle(MapStyles.highlights());
        selectedLayer = layer;
        layer.openPopup();
      } else {
        mapLayers.regionalLayer.resetStyle(layer);
      }
    });
    setTimeout(() => {
      map.fitBounds(
        selectedLayer.getBounds(),
        {
          animate: true,
        },
        100,
      );
    });
  };

  var ms4TableDoubleClick = function () {
    const groupId = $(this).data("groupid");
    handleSpatialChange(groupId);
  };

  var popupGotoMS4Click = function () {
    const groupId = $(this).data("groupid");
    handleSpatialChange(groupId);
  };

  var ms4TableDoubleMouseover = function (arg) {
    const groupId = $(this).data("groupid") ? $(this).data("groupid") : parseInt(arg, 10);
    $("#" + groupId + "_li").addClass("table-highlight");
    if (mapLayers.regionalLayer) {
      mapLayers.regionalLayer.eachLayer(function (layer) {
        if (layer.feature.properties.group_id == groupId) {
          layer.setStyle(MapStyles.highlights());
        }
      });
    }
  };

  var ms4TableDoubleMouseout = function () {
    const groupId = $(this).data("groupid");
    if (mapLayers.regionalLayer) {
      mapLayers.regionalLayer.eachLayer(function (layer) {
        mapLayers.regionalLayer.resetStyle(layer);
      });
    }
    $("#" + groupId + "_li").removeClass("table-highlight");
  };

  var zoomToMS4 = function (e) {
    map.fitBounds(e.target.getBounds());
    $(".bottomFloatingInputsTable").find(".active").removeClass("active");
    $("#" + e.target.feature.properties.group_id + "_li").addClass("active");
  };

  var loadMS4Table = function () {
    const features = Tree.get("MS4BoundaryFeatures");
    const html = nunjucks.render("table/ms4Table.njk", {
      data: features,
    });
    $(".bottomFloatingInputsTable").html(html);
  };

  var handleSpatialChange = function (groupId) {
    sessionStorage.activeGroupId = groupId;
    Tree.set(["activeGroup", "groupId"], groupId);
    $("#main-legend input[value!='bmps'][value!= 'streams']:checked").prop("checked", false);
    Table.unloadListeners();
    if (groupId != -1) {
      //Normal MS4
      $(".export").show();
      Tree.set("spatialScale", "MS4");
      resetMapLayers();
      loadMs4Map();
      showMs4ForRegulator();
      MainMapController.initTool();
    } else {
      // Regulator
      Tree.set("spatialScale", "REGION");
      loadRegulatorMap();
      setPopupGoToMS4ClickListener();
    }
  };

  var showBlueDot = function () {
    const location = Tree.get("blueDotLatLng");
    if (location) {
      if (mapLayers.blueDotLayer !== null) {
        map.removeLayer(mapLayers.blueDotLayer);
      }
      mapLayers.blueDotLayer = L.featureGroup();
      blueDot = L.circleMarker(location, {
        pane: "blueDotPane",
      });
      blueDot.addTo(mapLayers.blueDotLayer);
      mapLayers.blueDotLayer.addTo(map);
    }
  };

  var handleTodoFullDrainages = function (fullDrainages) {
    const filteredFullDrainages = { data: {} };
    const todoSubject = Tree.get(["todos", Tree.get("dataView"), "selectedSubject"]);

    if (todoSubject) {
      filteredFullDrainages.data.features = fullDrainages.data.features.filter(function (feature) {
        return ToDoFunctions.getIsIdInTodos(feature.properties.idBmp, todoSubject);
      });
    } else {
      filteredFullDrainages.data.features = fullDrainages.data.features.filter(function (feature) {
        return (
          !feature.properties.planned && ToDoFunctions.getIsIdInTodos(feature.properties.idBmp)
        );
      });
    }

    return filteredFullDrainages;
  };

  var setFullDrainageLayerData = function (fullDrainages, layer) {
    const activeTab = Tree.get("activeTab");

    if (fullDrainages.data && fullDrainages.data.features && fullDrainages.data.features.length) {
      if (activeTab === "todo") {
        fullDrainages = handleTodoFullDrainages(fullDrainages);
      }
    }

    if ("features" in fullDrainages.data) {
      putCorrectDrainageLayerOnMap(fullDrainages, layer);
    }
  };

  var putCorrectDrainageLayerOnMap = function (fullDrainages, layer) {
    if (layer === "bmps") {
      mapLayers.fullDrainageBmpLayer = MapFunctions.addFullDrainageLayer(
        map,
        mapLayers.fullDrainageBmpLayer,
        fullDrainages.data,
        "bmp",
      );
    } else {
      mapLayers.fullDrainageFcsLayer = MapFunctions.addFullDrainageLayer(
        map,
        mapLayers.fullDrainageFcsLayer,
        fullDrainages.data,
        "fcs",
      );
    }
  };

  var loadLayerListeners = function () {
    const dataView = Tree.get("dataView");

    if (dataView === "properties") {
      return;
    }

    if (dataView === "asset-management") {
      AssetManagementSummaryTableController.init();
    }

    LayerFunctions.loadAllLayerMapListeners(map, mapLayers, "main");
    EsriDynamicMapLayerFunctions.addMapClickListener(map, mapLayers);
    GeoServerLayerFunctions.addMapClickListener(map);

    Tree.select("blueDotLatLng").on("update", function (e) {
      showBlueDot();
    });

    MapFunctions.whenLayerDataUpdated("runoffCondition", "main", function (data) {
      mapLayers.runoffConditionLayer = MapFunctions.addRunoffConditionLayer(
        map,
        mapLayers.runoffConditionLayer,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated("projects", "main", function (data) {
      mapLayers.projectLayer = MapFunctions.addProjectLayer(map, mapLayers.projectLayer, data);
    });

    MapFunctions.whenLayerDataUpdated("trashLines", "main", function (data) {
      // Actions.getRecordCount();
      mapLayers.trashLineLayer = MapFunctions.addTrashLinesLayer(
        map,
        mapLayers.trashLineLayer,
        data,
      );
      mapLayers.collectorLineOutlineLayer = MapFunctions.addCollectorLineOutlineLayer(
        map,
        mapLayers,
        data,
      );
    });

    MapFunctions.whenLayerDataUpdated("trashPoints", "main", function (data) {
      mapLayers.trashPointLayer = MapFunctions.addTrashPointsLayer(
        map,
        mapLayers.trashPointLayer,
        data,
      );

      startTrashPointZoomListener(map, mapLayers);
    });

    MapFunctions.whenLayerDataUpdated("fullDrainageFcs", "main", function (data) {
      setupAndFilterFullDrainageLayer("fcs");
    });

    MapFunctions.whenLayerDataUpdated("fullDrainageBmp", "main", function (data) {
      setupAndFilterFullDrainageLayer("bmps");
    });

    MapFunctions.whenLayerDataUpdated("lidProjectBmp", "main", function (data) {
      mapLayers.lidProjectBmpLayer = MapFunctions.addBmpLayer(
        map,
        mapLayers.lidProjectBmpLayer,
        data,
        "main",
      );
      Clustering.setState("lidProjectBmp");
    });

    MapFunctions.whenLayerDataUpdated("particulates", "main", function (data) {
      mapLayers.rankingLayer = MapFunctions.addRankingLayer(map, mapLayers.rankingLayer, data);
    });

    MapFunctions.whenLayerDataUpdated("runoff", "main", function (data) {
      mapLayers.rankingLayer = MapFunctions.addRankingLayer(map, mapLayers.rankingLayer, data);
    });

    MapFunctions.whenLayerDataUpdated("streams", "main", function (data) {
      mapLayers.rwLayer = MapFunctions.addStreamLayer(map, mapLayers, data, "main");
    });

    MapFunctions.whenLayerDataUpdated("bounds", "main", function (data) {
      mapLayers.boundsLayer = MapFunctions.addBoundsLayer(map, mapLayers.boundsLayer, data);
    });

    MapFunctions.whenLayerDataUpdated("stormdrains", "main", function (data) {
      StormdrainLayer.addAllStormdrainLayers(map, mapLayers, data, "main");
    });

    // ----------------------------------------------------------------------
    // List to change notifications when layer is turned off
    // ----------------------------------------------------------------------

    MapFunctions.whenLayerToggled(
      "stormdrains",
      "main",
      function (stormdrainsIsEnabled, sameSpatialFilter) {
        if (stormdrainsIsEnabled) {
          if (mapLayers.stormdrainLayer && sameSpatialFilter) {
            StormdrainLayer.handleStormdrainDisplay(map, mapLayers, "main");
          } else {
            StormdrainLayer.loadStormdrains();
          }
        } else {
          MapFunctions.hideStormdrains(map, mapLayers);
        }
      },
    );

    MapFunctions.whenLayerToggled("streams", "main", async function () {
      var streamsEnabled = Tree.get("layers", "streams", "isEnabled");
      if (streamsEnabled) {
        if (mapLayers.rwLayer) {
          map.addLayer(mapLayers.rwLayer);
        } else {
          Actions.loadWatersheds();
        }
      } else {
        if (mapLayers.rwLayer) map.removeLayer(mapLayers.rwLayer);
      }
    });

    MapFunctions.whenLayerToggled("bounds", "main", function (boundsIsEnabled) {
      if (boundsIsEnabled) {
        if (MapFunctions.mapHasLayer(map, mapLayers.boundsLayer)) {
          setTimeout(function () {
            mapLayers.boundsLayer.addTo(map);
          }, 50);
        } else {
          Actions.loadBoundary();
        }
      } else {
        if (MapFunctions.mapHasLayer(map, mapLayers.boundsLayer)) {
          map.removeLayer(mapLayers.boundsLayer);
        }
      }
    });

    MapFunctions.whenLayerToggled(
      "projects",
      "main",
      function (projectsIsEnabled, sameSpatialFilter) {
        if (projectsIsEnabled) {
          if (mapLayers.projectLayer && sameSpatialFilter) {
            map.addLayer(mapLayers.projectLayer);
          } else {
            Actions.loadAssociatedProjectArea();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.projectLayer)) {
            map.removeLayer(mapLayers.projectLayer);
          }
        }
      },
    );

    MapFunctions.whenLayerToggled(
      "trashLines",
      "main",
      function (trashLinesIsEnabled, sameSpatialFilter) {
        if (trashLinesIsEnabled) {
          const loadTrash = Tree.get("loadNewTrashLines");
          if (mapLayers.trashLineLayer && sameSpatialFilter && !loadTrash) {
            MapFunctions.displayTrashLines(map, mapLayers);
          } else {
            Actions.loadTrashLinesAndPoints();
            Tree.set("loadNewTrashLines", false);
          }
        } else {
          MapFunctions.removeTrashLines(map, mapLayers);
        }
      },
    );

    MapFunctions.whenLayerToggled(
      "fullDrainageBmp",
      "main",
      function (fullDrainageBmpIsEnabled, sameSpatialFilter) {
        if (fullDrainageBmpIsEnabled) {
          if (mapLayers.fullDrainageBmpLayer && sameSpatialFilter) {
            const todoSubject = Tree.get(["todos", "bmp", "selectedSubject"]);
            if (todoSubject) {
              setupAndFilterFullDrainageLayer("bmps");
            } else {
              MapFunctions.displayFullDrainageLayer(map, mapLayers, "bmp");
            }
          } else {
            Actions.loadFullDrainageBmp();
          }
        } else {
          MapFunctions.removeFullDrainageBmp(map, mapLayers);
        }
      },
    );

    MapFunctions.whenLayerToggled(
      "fullDrainageFcs",
      "main",
      function (fullDrainageFcsIsEnabled, sameSpatialFilter) {
        if (fullDrainageFcsIsEnabled) {
          if (mapLayers.fullDrainageFcsLayer && sameSpatialFilter) {
            const todoSubject = Tree.get(["todos", "fcs", "selectedSubject"]);
            if (todoSubject) {
              setupAndFilterFullDrainageLayer("fcs");
            } else {
              MapFunctions.displayFullDrainageLayer(map, mapLayers, "fcs");
            }
          } else {
            Actions.loadFullDrainageFcs();
          }
        } else {
          MapFunctions.removeFullDrainageFcs(map, mapLayers);
        }
      },
    );

    MapFunctions.whenLayerToggled(
      "runoffCondition",
      "main",
      function (runoffConditionEnabled, sameSpatialFilter) {
        if (runoffConditionEnabled) {
          if (mapLayers.runoffConditionLayer && sameSpatialFilter) {
            const data = Tree.get(["layers", "runoffCondition", "data"]);
            mapLayers.runoffConditionLayer = MapFunctions.addRunoffConditionLayer(
              map,
              mapLayers.runoffConditionLayer,
              data,
            );
          } else {
            Actions.loadRunoffCondition();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.runoffConditionLayer)) {
            map.removeLayer(mapLayers.runoffConditionLayer);
          }
        }
      },
    );

    MapFunctions.whenLayerToggled(
      "lidProjectBmp",
      "main",
      function (lidProjectBmpEnabled, sameSpatialFilter) {
        if (lidProjectBmpEnabled) {
          if (mapLayers.lidProjectBmpLayer && sameSpatialFilter) {
            map.addLayer(mapLayers.lidProjectBmpLayer);
            const data = Tree.get(["layers", "lidProjectBmp", "data"]);
            mapLayers.lidProjectBmpLayer = MapFunctions.addBmpLayer(
              map,
              mapLayers.lidProjectBmpLayer,
              data,
              "main",
            );
          } else {
            Actions.loadLidProjectBmps();
          }
        } else {
          if (MapFunctions.mapHasLayer(map, mapLayers.lidProjectBmpLayer)) {
            map.removeLayer(mapLayers.lidProjectBmpLayer);
          }
        }
      },
    );

    Tree.on("write", function (e) {
      var path = e.data.path;
      if (path[0] === "filters") {
        if (path.length === 1) {
          Tree.set("isDefaultFilter", true);
        } else if (path.length > 1) {
          Tree.set("isDefaultFilter", false);
        }
      }
    });

    // When filters are update, reload BMPs
    //@TODO get working with forceRefresh function from report
    Tree.select("filters").on("update", function (e) {
      if (Tree.get("layers", "fullDrainageFcs", "isEnabled")) {
        Actions.loadFullDrainageFcs();
      }

      if (Tree.get("layers", "fullDrainageBmp", "isEnabled")) {
        Actions.loadFullDrainageBmp();
      }

      if (
        (MapFunctions.mapHasLayer(map, mapLayers.surveyLayer) ||
          MapFunctions.mapHasLayer(map, mapLayers.trashPointLayer) ||
          MapFunctions.mapHasLayer(map, mapLayers.trashLineLayer)) &&
        Tree.get("reloadFilter")
      ) {
        Actions.loadTrashLinesAndPoints();
      }
      if (MapFunctions.mapHasLayer(map, mapLayers.lidProjectBmpLayer)) {
        Actions.loadLidProjectBmps();
      }
      if (MapFunctions.mapHasLayer(map, mapLayers.projectLayer)) {
        Actions.loadAssociatedProjectArea();
      }
      if (MapFunctions.mapHasLayer(map, mapLayers.runoffConditionLayer)) {
        Actions.loadRunoffCondition();
      }
      if (MapFunctions.mapHasLayer(map, mapLayers.baseStormdrainLayer)) {
        StormdrainLayer.loadStormdrains();
      }
    });

    Tree.select("selected").on("update", function (e) {
      Actions.selectCatchmentsByFilter(mapLayers);
    });

    BasemapFunctions.addBasemapChangeListener(function (newBasemap) {
      addBasemap(newBasemap);
    }, "main");
  };

  const addBasemap = function (basemap) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    currentBasemap = rasterBasemap
      ? MapFunctions.addBasemap(map, currentBasemap, basemap)
      : MapFunctions.addVectorBasemap(map, currentBasemap, basemap);
    return currentBasemap;
  };

  var startTrashPointZoomListener = function (map, mapLayers) {
    map.on("zoomend", function () {
      MapFunctions.resizeLayerMarkers(
        mapLayers?.trashPointLayer,
        TrashIconFunctions.createIcon,
        map.getZoom(),
      );
    });
  };

  var setMapDataWatchers = function (availableLayersArray = Tree.get("availableDataSortLayers")) {
    availableLayersArray.forEach(function (layer) {
      Tree.select(layer).release();
      Tree.select(layer).on("update", function (e) {
        if (!e.data.currentData.isFetching && !Session.isMobileParentView()) {
          mapLayers[`${layer}Layer`] = MapFunctions.addDataLayer(
            map,
            mapLayers,
            e.data.currentData.items,
            layer,
          );
        }
      });
    });
  };

  var addFullDrainageLayerToMap = function (layer) {
    const fullDrainageLayer = getFullDrainageBmpOrFcsLayer(layer);
    // let fullDrainagesFcs = Tree.get("layers", "fullDrainageFcs");

    if (
      fullDrainageLayer.isEnabled ||
      Tree.get("layers", "fcs", "selectedId") ||
      Tree.get("layers", "bmp", "selectedId")
    ) {
      if (
        fullDrainageLayer.data &&
        fullDrainageLayer.data.features &&
        fullDrainageLayer.data.features.length
      ) {
        if (layer === "bmps") {
          mapLayers.fullDrainageBmpLayer.addTo(map);
        } else {
          mapLayers.fullDrainageFcsLayer.addTo(map);
        }
      }
    }
  };

  var setupAndFilterFullDrainageLayer = function (layer) {
    const fullDrainagesLayer = getFullDrainageBmpOrFcsLayer(layer);
    if (fullDrainagesLayer.isEnabled) {
      setFullDrainageLayerData(fullDrainagesLayer, layer);
      addFullDrainageLayerToMap(layer);
    }
  };

  var getFullDrainageBmpOrFcsLayer = function (layer) {
    if (layer === "bmps") {
      return Tree.get("layers", "fullDrainageBmp");
    } else if (layer === "fcs") {
      return Tree.get("layers", "fullDrainageFcs");
    } else if (layer === "muniCatchBasin" || layer === "structuralBmps") {
      return Tree.get("layers", "muniCatchBasinDrainageArea");
    } else {
      throw new Error(`Unknown layer for finding drainage area: ${layer}.`);
    }
  };

  var getMap = function () {
    return map;
  };

  var getMapLayers = function () {
    return mapLayers;
  };

  var getMapLayerByLayerName = function (layerName) {
    var layerKey = LayerFunctions.getLayerKeyByLayerName(layerName);
    return mapLayers[layerKey];
  };

  var setMapLayerByLayerName = function (layerName, layer) {
    var layerKey = LayerFunctions.getLayerKeyByLayerName(layerName);
    mapLayers[layerKey] = layer;
  };

  var layerExists = function (layer) {
    for (const layerName in mapLayers) {
      if (mapLayers[layerName] === layer) {
        return true;
      }
    }

    return false;
  };

  var invalidateLayerByName = function (layerName) {
    const layerKey = LayerFunctions.getLayerKeyByLayerName(layerName);
    const layer = mapLayers[layerKey];

    if (layer) {
      map.removeLayer(layer);
      mapLayers[layerKey] = null;
      Tree.set(["layers", layerName, "isEnabled"], false);
      Tree.set(["layers", layerName, "data"], []);
    }
  };

  var closePopups = function () {
    if (map) {
      map.closePopup();
    }
  };

  var zoomToCatchmentView = function () {
    var selectedCatchment = Tree.get(["selected", "catchments"]);

    if (map && mapLayers && mapLayers.catchmentsLayer) {
      mapLayers.catchmentsLayer.eachLayer(function (layer) {
        if (layer.feature.properties.catchid === selectedCatchment) {
          map.fitBounds(layer.getBounds());
          return;
        }
      });
    }
  };

  var zoomToDrainageView = function () {
    var catchmentsInDrainage = [];
    var selectedDrainage = Tree.get(["selected", "urbanDrainage"]);

    if (map && mapLayers && mapLayers.catchmentsLayer) {
      mapLayers.catchmentsLayer.eachLayer(function (layer) {
        if (layer.feature.properties.drains_to_c === selectedDrainage) {
          catchmentsInDrainage.push(layer);
        }
      });
      var catchLayerGroup = L.featureGroup(catchmentsInDrainage);
      map.fitBounds(catchLayerGroup.getBounds());
    }
  };

  var zoomToMs4View = function () {
    if (map && mapLayers && mapLayers.catchmentsLayer) {
      map.fitBounds(mapLayers.catchmentsLayer.getBounds());
    }
  };

  var handlePolygonTableSelect = function (id) {
    mapLayers.runoffConditionLayer.eachLayer(function (layer) {
      if (layer.feature.properties.id === id) {
        setTimeout(function () {
          layer.openPopup();
        }, 0);
      }
    });
  };

  return {
    init,
    createMap,
    loadMap,
    ms4TableDoubleMouseover,
    ms4TableDoubleClick,
    ms4TableDoubleMouseout,
    ms4TableClick,
    handleSpatialChange,
    reloadBmpFcsLayer,
    invalidateMapSize,
    initFitBounds,
    getMap,
    getMapLayers,
    setupAndFilterFullDrainageLayer,
    closePopups,
    zoomToCatchmentView,
    zoomToDrainageView,
    zoomToMs4View,
    handlePolygonTableSelect,
    getMapLayerByLayerName,
    layerExists,
    setMapLayerByLayerName,
    disableUserMapLayers,
    setMapDataWatchers,
    invalidateLayerByName,
    reloadBmpFcsLayerWithoutLoadingScreen,
    loadMs4Map,
  };
};

module.exports = MainMap();

const ActionDropdown = require("../general/actionDropdown");
const Actions = require("../actions");
const ApiCalls = require("../apiCalls");
const BasemapFunctions = require("../mapFunctions/basemapFunctions");
const Benchmark = require("../modals/benchmark");
const BmpLayer = require("../bmpfcs/bmpLayer");
const CleanData = require("./cleanData");
const Clustering = require("./clustering");
const CustomLayer = require("./customLayer");
const DataViewFunctions = require("../dataViewFunctions");
const DisplayOptions = require("./displayOptions");
const EsriDynamicMapLayerFunctions = require("./esriDynamicMapLayerFunctions");
const FcsLayer = require("../bmpfcs/fcsLayer");
const Filters = require("./filters");
const GeoServerLayerFunctions = require("./geoServerLayerFunctions");
const LayerFunctions = require("../layerFunctions");
const ListPanel = require("../mapFunctions/listPanel");
const MainMapController = require("./mainMapController");
const MapFunctions = require("./mapFunctions");
const MapPaneFunctions = require("./mapPaneFunctions");
const MapPanel = require("../mapFunctions/mapPanel");
const MapStyles = require("./mapStyles");
const MapTitle = require("./mapTitle");
const Misc = require("../misc");
const Mobile = require("../mobile");
const ModalMap = require("./modalMap");
const MuniCatchBasinGeoServerLayer = require("../muni/muniCatchBasinGeoServerLayer");
const NavToggleController = require("./navToggleController");
const Panels = require("../panels");
const PopupContent = require("./popupContent");
const PopupPhotos = require("./popupPhotos");
const RegionDropdown = require("./regionDropdown");
const ReopenPopups = require("./reopenPopups");
const ReportingTimelineController = require("../reportingTimelineController");
const Session = require("../login/session");
const Settings = require("../settings/settings");
const StormdrainLayer = require("../general/stormdrainLayer");
const Table = require("./table");
const ToDoFunctions = require("./toDoFunctions");
const UserSettingsFunctions = require("../modals/userSettingsFunctions");
const AssetManagementSummaryTableController = require("../assetManagement/assetManagementSummaryTableController");
const AssetManagementWidgetToggle = require("../assetManagement/assetManagementWidgetToggle");
const FeatureFlag = require("../settings/featureFlag");
const CatchBasinFactSheetController = require("../muni/catchBasinFactSheetController");
const TrashIconFunctions = require("../trash/trashIconFunctions");
const Tree = require("../tree");
const FilterSummary = require("../filters/filterSummary");
const MuniBmpGeoServerLayer = require("../muni/bmp/muniBmpGeoServerLayer");
const StructuralBmpsGeoServerLayer = require("../bmpFcsG2/structuralBmpsGeoServerLayer");
const LidBmpGeoServerLayer = require("../lid/lidBmpGeoServerLayer");
const DryWeatherFunctions = require("../illicitDischarge/dryWeatherFunctions");
const FactSheetNew = require("../general/factSheetNew");

require("./geocodingControls");
