"use strict";

const GeoServerLayerFunctions = function () {
  const popupByLayer = {};
  var popupOpening = false;

  var getWmsLayer = function (layerName, onlineFiltering = true) {
    const rasterBasemap = FeatureFlag.enabled("raster-basemap");
    let wmsLayerString;
    const workspace = ToolSettings.getSetting("geoserver", "workspace");

    wmsLayerString = getWmsLayerString(layerName, workspace);
    const wmsStyle = getWmsStyle(layerName);
    const wmsUrl = getWmsLayerUrl();
    const maxZoom = rasterBasemap ? 19 : 21;

    if (window.twoNformReport) {
      const liveDataView = DataViewFunctions.getCurrentDataViewProperty("liveDataView");
      const reportData = getReportData();
      if (!reportData?.snapshotYear) {
        wmsLayerString = `${workspace}:${liveDataView}`;
      }
    }

    return CustomWmsLayer.betterWms(wmsUrl, {
      layers: wmsLayerString,
      tiled: true,
      srs: "EPSG:4326",
      maxZoom: maxZoom,
      transparent: true,
      format: "image/png",
      styles: wmsStyle,
      opacity: 1,
      pane: getPane(layerName),
      cql_filter: ramQueryOrAnnualReport(onlineFiltering, layerName),
      viewparams: GeoServerFilterFunctions.getFilterViewParams(layerName),
    });
  };

  var getPane = function (layerName) {
    if (Tree.get("dataView") === "properties") {
      return "tilePane";
    } else {
      const layerConfig = LayerFunctions.getLayerConfig(layerName);

      return window.twoNformReport
        ? "overCatchmentPane"
        : layerConfig?.pane
          ? layerConfig.pane
          : "overBoundaryPane";
    }
  };

  var getWmsLayerUrl = function () {
    const urlprefix = ToolSettings.getSetting("geoserver", "url");
    const workspace = ToolSettings.getSetting("geoserver", "workspace");

    return `${urlprefix}${workspace}/wms/`;
  };

  var getWmsLayerString = function (layerName, workspace) {
    const view = getGeoServerLayerProperty(layerName, "view");
    return `${workspace}:${view}`;
  };

  var getWmsStyle = function (layerName) {
    if (Tree.get("dataView") === "asset-management") {
      const view = getGeoServerLayerProperty(layerName, "view");
      const mode = Tree.get("assetManagementRadioMode");
      if (view === "assets_outfalls" && Session.isBatonRouge()) {
        return `${view}_${mode}_no_rotation`;
      } else {
        return `${view}_${mode}`;
      }
    } else {
      const conditionPhaseToggle = Tree.get(["filters", "conditionPhaseToggle"]);
      const style = getGeoServerLayerProperty(layerName, "style");
      const stylePhase = getGeoServerLayerProperty(layerName, "stylePhase");
      if (conditionPhaseToggle === "phase" && stylePhase) {
        return stylePhase;
      } else {
        return style;
      }
    }
  };

  var getFeaturesForLayerClick = async function (map, workspace, latlng) {
    const primaryLayerUrl = _getWfsUrl(map, workspace, latlng, true);
    const nonPrimaryLayerUrl = _getWfsUrl(map, workspace, latlng);

    const primaryLayerRequest = getFeaturesRequest(primaryLayerUrl, workspace, true);
    const otherLayerRequest =
      Tree.get("dataView") === "asset-management"
        ? undefined
        : getFeaturesRequest(nonPrimaryLayerUrl, workspace);
    const spatialLayerRequest = getFeaturesRequest(nonPrimaryLayerUrl, workspace, false, true);

    return await Promise.all([primaryLayerRequest, otherLayerRequest, spatialLayerRequest]).then(
      (values) => {
        const primaryLayerData = values[0];
        const otherLayerData = values[1];
        const spatialLayerData = values[2];

        if (primaryLayerData?.features.length) {
          return primaryLayerData.features;
        } else if (otherLayerData?.features.length) {
          return otherLayerData.features;
        } else if (spatialLayerData?.features.length) {
          return spatialLayerData.features;
        }

        return [];
      },
    );
  };

  var getFeaturesRequest = async function (
    url,
    workspace,
    isPrimaryLayer = false,
    isSpatialLayer = false,
  ) {
    const username = ToolSettings.getSetting("geoserver", "username");
    const password = ToolSettings.getSetting("geoserver", "password");

    const requestLayers = getWfsRequestLayers(isPrimaryLayer, isSpatialLayer) || [];
    const primaryRequestLayerName = requestLayers[0];
    const config = GeoServerConfig.get()?.[primaryRequestLayerName];
    if (!config) {
      return;
    }
    const typeName = getWfsLayerString(workspace, requestLayers);
    if (!typeName) {
      return;
    }

    let cqlFilter;
    if (isSpatialLayer) {
      cqlFilter = GeoServerFilterFunctions.getGroupIdQuery();
    } else if (isPrimaryLayer || config.filterModule) {
      cqlFilter = GeoServerFilterFunctions.getFilterQuery(primaryRequestLayerName);
    } else {
      cqlFilter = GeoServerFilterFunctions.getBaseSpatialFilterQuery();
    }

    const defaultParameters = {
      version: "1.1.0",
      service: "WFS",
      request: "GetFeature",
      typeName: typeName,
      outputFormat: "application/json",
      srsname: "EPSG:4326",
      CQL_FILTER: cqlFilter,
    };
    const parameters = L.Util.extend(defaultParameters);
    const loadingScreen = false;

    return await Network.asPromise(
      $.ajax({
        type: "post",
        url: url,
        data: parameters,
        dataType: "json",
        global: loadingScreen,
        headers: {
          Authorization: "Basic " + btoa(username + ":" + password),
        },
      }),
    );
  };

  var addMapClickListener = function (map) {
    map.on("click", async function (event) {
      if (Tree.get(["leafletRulerEnabled", "map"])) {
        return;
      }

      var latlng = event.latlng;
      latlng = convertClickPointForPopupsToOpenWithLatLngOffset(map, latlng);
      const isGisDataView = DataViewFunctions.getCurrentDataViewProperty("isGisDataView");
      const hasAssociatedG2Layer =
        DataViewFunctions.getCurrentDataViewProperty("hasAssociatedG2Layer");

      if (Tree.get("tool") !== "assets" && !isGisDataView && !hasAssociatedG2Layer) {
        return;
      }

      const workspace = ToolSettings.getSetting("geoserver", "workspace");

      loadPopupOnGeoServerFeaturesChange(latlng, map);
      const layerFeatures = await getFeaturesForLayerClick(map, workspace, latlng);

      layerFeatures.forEach(async function (feature, layer) {
        Tree.select(["geoServerFeatures"]).push(feature);
      });
    });
  };

  var _isSecondaryBmpLayer = function (currentDataView) {
    const enabledGeoServerLayerArray = Tree.get("enabledGeoServerLayers") ?? [];
    const secondaryBmpLayers = ["projectDeliveryBmps", "telrResultBmp"];
    const secondaryBmpDataViews = ["construction-project-delivery", "scenarios"];
    return (
      secondaryBmpDataViews.includes(currentDataView) &&
      secondaryBmpLayers.some((layerName) => enabledGeoServerLayerArray.includes(layerName))
    );
  };

  var removeMapClickListener = function (map) {
    map.off("click");
  };

  var convertClickPointForPopupsToOpenWithLatLngOffset = function (map, latlng) {
    const dataView = Tree.get("dataView");
    const bmpDataViews = ["bmp", "muni-bmp", "lid-bmp"];
    const zoomLevel = map.getZoom();
    const isLargeGroup = Session.isLargeGroup();
    const isSecondaryBmpLayer = _isSecondaryBmpLayer(dataView);
    let yOffset = 22;
    if (dataView === "bmp" || isSecondaryBmpLayer) {
      if (isLargeGroup && isSecondaryBmpLayer) {
        if (zoomLevel === 14) {
          yOffset = 22;
        } else if (zoomLevel === 12) {
          yOffset = 6;
        } else if (zoomLevel <= 11) {
          yOffset = 5;
        } else {
          yOffset = 22;
        }
      } else {
        yOffset = zoomLevel <= 14 ? 2 : 22;
      }
    } else if (dataView === "muni-bmp" || dataView === "lid-bmp") {
      if (zoomLevel <= 14) {
        yOffset = 10;
      } else if (zoomLevel <= 15) {
        yOffset = 20;
      } else if (zoomLevel <= 16) {
        yOffset = 25;
      } else if (zoomLevel <= 17) {
        yOffset = 20;
      } else if (zoomLevel <= 18) {
        yOffset = 25;
      } else if (zoomLevel <= 19) {
        yOffset = 25;
      }
    }
    if (bmpDataViews.includes(dataView) || isSecondaryBmpLayer) {
      var point = map.latLngToContainerPoint(latlng);
      var newPoint = L.point(point.x, point.y + yOffset);
      var newLatLng = map.containerPointToLatLng(newPoint);
      return newLatLng;
    } else {
      return latlng;
    }
  };

  var _getWfsUrl = function (map, workspace, latlng, isPrimaryLayer = false) {
    const urlprefix = ToolSettings.getSetting("geoserver", "url");

    const params = new URLSearchParams();

    var wfsViewparams = getBaseWfsViewparams(map, latlng);
    if (isPrimaryLayer) {
      const layerName = (DataViewFunctions.getCurrentDataViewProperty("defaultLayers") || [])[0];
      const layerViewparams = GeoServerFilterFunctions.getFilterViewParams(layerName);
      if (layerViewparams) {
        wfsViewparams += ";" + layerViewparams;
      }
    }
    params.append("viewparams", wfsViewparams);

    params.append("maxFeatures", 1);

    return `${urlprefix}${workspace}/wfs/?${params.toString()}`;
  };

  var getBaseWfsViewparams = function (map, latlng) {
    // Using "LIMIT ALL" instead of removing from the GeoServer so
    // layer previews still work.
    const params = [
      `lng:${latlng.lng}`,
      `lat:${latlng.lat}`,
      `b:${getTolerance(map)}`,
      `limit:ALL`,
    ];

    return params.join(";");
  };

  var loadPopupOnGeoServerFeaturesChange = function (latlng, map) {
    Tree.select("geoServerFeatures").release();
    Tree.set("geoServerFeatures", []);

    Tree.select("geoServerFeatures").on("update", function (e) {
      const features = e.data.currentData;
      if (features.length > 0) {
        const wfsFeatureId = features[0].id.split(".")?.[0];
        const layerName = getLayerNameByWfsFeatureId(wfsFeatureId);
        openGeoServerPopup(map, layerName, features[0]?.properties, latlng);
      }
    });
  };

  var openGeoServerPopup = async function (map, layerName, data, latlng, openedById = false) {
    const isCatchmentOrZone = layerName === "catchments" || layerName === "maintenanceZones";

    // @TODO:  Handle speical lid-runoff logic with config
    layerName =
      layerName !== "catchments" && Tree.get("dataView") === "lid-runoff"
        ? "postConstructionProjectG2"
        : layerName;
    if (
      layerName === "catchments" &&
      Tree.get("dataView") === "scenarios" &&
      Tree.get(["layers", "telrResult", "isEnabled"])
    ) {
      TelrResultLayerFunctions.openPopupByGid(data.gid);
      return;
    }

    if (
      isCatchmentOrZone &&
      Tree.get("dataView") !== "roads" &&
      (map.getZoom() > 15 || Device.isPrimaryInputTouch())
    ) {
      return;
    }

    const popupContent = _getPopupContent(data, layerName);
    if (popupContent) {
      const openPopupFunction = () =>
        openGeoServerPopupFromData(map, layerName, latlng, data, popupContent, openedById);
      popupByLayer[layerName] = {
        openPopupFunction,
      };
      await openPopupFunction();
    }
  };

  var _getPopupContent = function (data, layerName) {
    const popupHtmlFunction = getPopupHtmlFunction(layerName);
    return popupHtmlFunction(structuredClone(data));
  };

  var getPopupHtmlFunction = function (layerName) {
    const popupModule = getGeoServerLayerProperty(layerName, "popupModule");
    if (!popupModule) {
      throw new Error(`No popupModule defined in GeoServerConfig for layer ${layerName}`);
    }
    const popupHtmlFunction = popupModule?.getPopupHtml;
    if (!popupHtmlFunction) {
      throw new Error(`No function popupHtml found for layer ${layerName}`);
    }
    return popupHtmlFunction;
  };

  var getOnPopupOpenFunction = function (layerName) {
    const popupModule = getGeoServerLayerProperty(layerName, "popupModule");
    const onPopupOpenFunction = popupModule?.onPopupOpen;
    return onPopupOpenFunction;
  };

  var openGeoServerPopupByLayerName = async function (layerName) {
    const openPopupFunction = popupByLayer[layerName]?.openPopupFunction;

    if (openPopupFunction) {
      await openPopupFunction();
    }
  };

  var clearOpenPopupsByLayerName = async function (layerName) {
    if (!popupOpening) {
      popupByLayer[layerName] = {};
    }
  };

  var openGeoServerPopupFromData = function (
    map,
    layerName,
    latlng,
    data,
    popupContent,
    openedById,
  ) {
    const zoomLevel = map.getZoom();
    popupOpening = true;
    let popupOptions = MapFunctions.getPopupOptions();
    popupOptions = _offSetPopup(popupOptions, openedById, zoomLevel);
    L.popup(popupOptions).setLatLng(latlng).setContent(popupContent).openOn(map);

    const onPopupOpenFunction = getOnPopupOpenFunction(layerName);
    if (onPopupOpenFunction) {
      onPopupOpenFunction(data, layerName);
    }

    popupOpening = false;
  };

  var _offSetPopup = function (popupOptions, openedById = false, zoomLevel) {
    const dataView = Tree.get("dataView");
    let isClustered = false;
    const $checkBox = $(`input[name="super-cluster"]`)[0];
    const isSecondaryBmpLayer = _isSecondaryBmpLayer(dataView);
    if ($checkBox) {
      isClustered = $checkBox.checked;
    }
    const isLargeGroup = Session.isLargeGroup();
    if ((dataView === "bmp" || isSecondaryBmpLayer) && !isClustered && !openedById) {
      if (isLargeGroup && isSecondaryBmpLayer && (zoomLevel <= 14 || zoomLevel >= 12)) {
        popupOptions.offset = zoomLevel < 13 ? [-0.5, 0] : [-0.5, -12];
      } else {
        popupOptions.offset = zoomLevel <= 14 ? [0, 6] : [-0.5, -12];
      }
    } else if (dataView === "bmp" && !isClustered && openedById) {
      popupOptions.offset = zoomLevel <= 14 ? [0, 4] : [-0.5, -16];
    } else if (dataView === "bmp" && isClustered && openedById) {
      popupOptions.offset = zoomLevel <= 14 ? [0, -2] : [-0.5, -12];
    } else if ((dataView === "muni-bmp" || dataView === "lid-bmp") && openedById && !isClustered) {
      popupOptions.offset = zoomLevel <= 14 ? [0, -2] : [-2, -6];
    } else if ((dataView === "muni-bmp" || dataView === "lid-bmp") && !openedById && !isClustered) {
      popupOptions.offset = zoomLevel <= 14 ? [0, 0] : [-2, -10];
    } else if (dataView === "muni-manhole" && !openedById && !isClustered) {
      popupOptions.offset = [-1, 12];
    } else if (dataView === "muni-manhole" && openedById && !isClustered) {
      popupOptions.offset = [1, 10];
    } else if (dataView === "muni-catch-basin" && openedById && !isClustered) {
      popupOptions.offset = [-0.5, 10];
    } else if (dataView === "muni-cleanout" && openedById && !isClustered) {
      popupOptions.offset = [-0.5, 10];
    } else if (dataView === "muni-cleanout" && !openedById && !isClustered) {
      popupOptions.offset = [-0.5, 12];
    } else if (dataView === "muni-outfalls" && openedById && !isClustered) {
      popupOptions.offset = [-1, 7];
    } else if (dataView === "muni-outfalls" && !openedById && !isClustered) {
      popupOptions.offset = zoomLevel <= 14 ? [0, 15] : [1, 7];
    } else if (dataView === "muni-lift-stations" && openedById && !isClustered) {
      popupOptions.offset = [1, 10];
    } else if (dataView === "muni-lift-stations" && !openedById && !isClustered) {
      popupOptions.offset = [-1, 16];
    }
    return popupOptions;
  };

  var getTolerance = function (map) {
    const zoomLevel = map.getZoom();
    let tolerance;
    const dataView = Tree.get("dataView");
    const dataViews = ["muni-pipes", "muni-open-channel", "muni-culvert"];
    const isSecondaryBmpLayer = _isSecondaryBmpLayer(dataView);
    const isScenarioSecondaryBmpLayer = _isSecondaryBmpLayer(dataView) && dataView === "scenarios";
    const isLargeGroup = Session.isLargeGroup();
    if (
      (isSecondaryBmpLayer || dataView === "construction-project") &&
      isLargeGroup &&
      zoomLevel <= 13 &&
      zoomLevel >= 9
    ) {
      if (zoomLevel === 12 || zoomLevel === 13) {
        tolerance = dataView === "construction-project" ? "0.0025" : "0.0015";
      } else if (zoomLevel === 11) {
        tolerance = "0.003";
      } else if (zoomLevel < 11) {
        tolerance = "0.005";
      }
    } else if (isScenarioSecondaryBmpLayer && zoomLevel === 13) {
      tolerance = "0.001";
    } else if (zoomLevel <= 14) {
      tolerance = dataViews.includes(dataView) ? "0.0003" : "0.0005";
    } else if (zoomLevel === 15) {
      tolerance = dataViews.includes(dataView) ? "0.00015" : "0.0005";
    } else if (zoomLevel === 16) {
      tolerance = dataViews.includes(dataView) ? "0.0001" : "0.0002";
    } else if (zoomLevel === 17) {
      tolerance = dataViews.includes(dataView) ? "0.00005" : "0.0001";
    } else if (zoomLevel === 18) {
      tolerance = dataViews.includes(dataView) ? "0.00003" : "0.00005";
    } else if (zoomLevel === 19) {
      tolerance = "0.00002";
    } else if (zoomLevel > 19) {
      tolerance = "0.00001";
    }
    if (dataView === "properties" && zoomLevel <= 13) {
      tolerance = handlePropertyTolerance(zoomLevel);
    }

    return tolerance;
  };

  var handlePropertyTolerance = function (zoomLevel) {
    const toleranceLevels = {
      4: "0.2",
      5: "0.1",
      6: "0.03",
      7: "0.09",
      8: "0.01",
      9: "0.02",
      10: "0.01",
      11: "0.002",
      12: "0.001",
      13: "0.000001",
    };

    return toleranceLevels[zoomLevel] || "0";
  };

  var getWfsRequestLayers = function (isPrimaryLayer, isSpatialLayer) {
    let primaryLayer = (DataViewFunctions.getCurrentDataViewProperty("defaultLayers") || [])[0];
    if (!GeoServerConfig.get()[primaryLayer]) {
      primaryLayer = (Tree.get("enabledGeoServerLayers") || [])[0];
    }

    let enabledGeoServerLayers;
    if (window.twoNformReport) {
      enabledGeoServerLayers = ["muniCatchBasinReport"];
    } else if (isSpatialLayer) {
      enabledGeoServerLayers = getSpatialLayerNamesForPopup();
    } else if (Tree.get("dataView") === "asset-management") {
      enabledGeoServerLayers = getAssetLayerNamesForPopup();
    } else if (isPrimaryLayer && primaryLayer) {
      enabledGeoServerLayers = [primaryLayer];
    } else {
      enabledGeoServerLayers = getAssetLayerNamesForPopup()?.filter(
        (layerName) => layerName !== primaryLayer,
      );
    }

    return enabledGeoServerLayers;
  };

  var getWfsLayerString = function (workspace, requestLayers) {
    return requestLayers
      .slice()
      .reverse()
      .map((layerName) => {
        const view =
          getGeoServerLayerProperty(layerName, "popupView") ||
          getGeoServerLayerProperty(layerName, "view");
        if (view) {
          return `${workspace}:${view}`;
        }
      })
      .join(",");
  };

  var getAssetLayerNamesForPopup = function () {
    let enabledGeoServerLayers = Tree.get("enabledGeoServerLayers");

    if (Tree.get("dataView") !== "asset-management") {
      enabledGeoServerLayers = enabledGeoServerLayers.filter((layerName) => {
        const popupModule = getGeoServerLayerProperty(layerName, "popupModule");
        return !!popupModule;
      });
    }

    return enabledGeoServerLayers;
  };

  var getAdditionalInfo = function (parsedData, key) {
    if (!Array.isArray(parsedData)) {
      return null;
    }
    return parsedData.map((datum) => {
      return datum[key];
    });
  };

  var getSpatialLayerNamesForPopup = function () {
    const spatialGeoServerLayers = [];

    if (Tree.get("availableDataSortLayers").includes("maintenanceZones")) {
      spatialGeoServerLayers.unshift("maintenanceZones");
    } else {
      spatialGeoServerLayers.unshift("catchments");
    }

    return spatialGeoServerLayers;
  };

  var getGeoServerConfig = function (layerName) {
    const config = GeoServerConfig.get()[layerName];

    if (config === undefined) {
      throw Error(`layerName ${layerName} has no configuration.`);
    }

    return config;
  };

  var getGeoServerLayerProperty = function (layerName, property) {
    const config = getGeoServerConfig(layerName);

    return config[property];
  };

  var getLayerNameByWfsFeatureId = function (wfsFeatureId) {
    const allConfig = GeoServerConfig.get();

    for (const key in allConfig) {
      const config = allConfig[key];

      if (config.popupView) {
        if (config.popupView === wfsFeatureId) {
          return key;
        }
      } else {
        if (config.view === wfsFeatureId) {
          return key;
        }
      }
    }

    throw Error(`layerName not found for wfsFeatureId ${wfsFeatureId}`);
  };

  var getFeaturesForLayer = async function (
    layerName,
    onlineFiltering = false,
    loadingScreen = true,
    options = {},
  ) {
    let layerString;
    const urlprefix = ToolSettings.getSetting("geoserver", "url");
    const workspace = ToolSettings.getSetting("geoserver", "workspace");
    const username = ToolSettings.getSetting("geoserver", "username");
    const password = ToolSettings.getSetting("geoserver", "password");
    const wfsUrl = `${urlprefix}${workspace}/wfs`;
    const view = getGeoServerLayerProperty(layerName, "view");
    layerString = `${workspace}:${view}`;
    const reportData = getReportData();
    if (window.twoNformReport) {
      if (!reportData?.snapshotYear) {
        const liveDataView = DataViewFunctions.getCurrentDataViewProperty("liveDataView");
        layerString = `${workspace}:${liveDataView}`;
      }
    }

    const defaultParameters = {
      version: "1.1.0",
      service: "WFS",
      request: "GetFeature",
      typeName: layerString,
      outputFormat: "application/json",
      srsname: "EPSG:4326",
      CQL_FILTER: ramQueryOrAnnualReport(onlineFiltering, layerName),
      viewparams: onlineFiltering
        ? GeoServerFilterFunctions.getFilterViewParams(layerName, options)
        : "",
    };
    const parameters = L.Util.extend(defaultParameters);

    const data = await Network.asPromise(
      $.ajax({
        type: "post",
        url: wfsUrl,
        data: parameters,
        dataType: "json",
        global: loadingScreen,
        headers: {
          Authorization: "Basic " + btoa(username + ":" + password),
        },
      }),
    );
    if (data) {
      return data.features;
    }
  };

  var ramQueryOrAnnualReport = function (onlineFiltering, layerName) {
    if (!window.twoNformReport) {
      return onlineFiltering
        ? GeoServerFilterFunctions.getFilterQuery(layerName)
        : GeoServerFilterFunctions.getBaseQuery(layerName);
    } else {
      return GeoServerFilterFunctions.getAnnualReportCqlFilters();
    }
  };

  var getReportData = function () {
    if (window.twoNformReport) {
      const dataView = Tree.get("dataView");
      const reports = AnnualReports?.getYearReports();
      let filteredReports;
      if (dataView === "muniCatchBasinView") {
        filteredReports = reports.filter((report) => report.dataName === "muni catch basin");
      }
      const result = filteredReports ? filteredReports[0] : null;
      if (result) {
        const dataRangeLast = result.dataRangeLast;
        const groupId = result.groupId;
        const reportingYear = result.reportingYear;
        const snapshotYear = result.snapshotYear;

        return { dataRangeLast, groupId, reportingYear, snapshotYear };
      } else {
        return null;
      }
    }
  };

  var prepareToDoData = function (data, subjectsMap, { dueWhenDate = false } = {}) {
    const result = { toDoSubjects: [] };

    for (const key in subjectsMap) {
      const subject = subjectsMap[key];

      result[subject] = getToDoObject(data, key, dueWhenDate);

      if (result[subject].isDue) {
        result.toDoSubjects.push(subject);
      }
    }

    return result;
  };

  var getToDoObject = function (data, prefix, dueWhenDate) {
    const dueDate = data[`todo_due_date_${prefix}`];
    const isDue = dueWhenDate ? dueDate !== null : data[`todo_is_${prefix}`];
    const additionalInfo = parseJsonIfDefined(data[`todo_additional_info_${prefix}`]);

    return { isDue, additionalInfo, dueDate };
  };

  var parseJsonIfDefined = function (toParse) {
    return toParse === undefined ? undefined : JSON.parse(toParse);
  };

  var setLayerInstanceByMapId = function (layerName, mapId, layerInstance) {
    Tree.set([layerName, mapId], layerInstance);
  };

  var loadLayerByMapId = function (layerName, mapId = "main") {
    const layerInstance = Tree.get([layerName, mapId]);
    if (layerInstance) {
      let map, mapLayers;
      if (mapId === "modal") {
        map = ModalMap.getMap();
        mapLayers = ModalMap.getMapLayers();
      } else {
        map = MainMap.getMap();
        mapLayers = MainMap.getMapLayers();
      }

      layerInstance.loadGeoServerLayer(map, mapLayers, mapId, {
        refreshData: false,
      });
    }
  };

  var invalidateLayerDataForMainMap = function (layerName, loadingScreen = true) {
    const mapId = "main";
    const layerInstance = Tree.get([layerName, mapId]);
    if (layerInstance) {
      layerInstance.invalidateLayerData(MainMap.getMap(), MainMap.getMapLayers(), mapId, {
        loadingScreen,
      });
    }
  };

  return {
    addMapClickListener,
    getWmsLayer,
    getWmsLayerUrl,
    getWfsLayerString,
    getGeoServerLayerProperty,
    getFeaturesForLayer,
    openGeoServerPopupByLayerName,
    clearOpenPopupsByLayerName,
    openGeoServerPopup,
    prepareToDoData,
    getPopupHtmlFunction,
    getOnPopupOpenFunction,
    _getWfsUrl,
    _offSetPopup,
    removeMapClickListener,
    getReportData,
    _isSecondaryBmpLayer,
    getPane,
    getAdditionalInfo,
    setLayerInstanceByMapId,
    loadLayerByMapId,
    invalidateLayerDataForMainMap,
  };
};

module.exports = GeoServerLayerFunctions();

const Network = require("../network");
const CustomWmsLayer = require("./customWmsLayer");
const DataViewFunctions = require("../dataViewFunctions");
const Device = require("../device");
const FeatureFlag = require("../settings/featureFlag");
const GeoServerConfig = require("../config/geoServerConfig");
const GeoServerFilterFunctions = require("./geoServerFilterFunctions");
const MainMap = require("./mainMap");
const ModalMap = require("./modalMap");
const MapFunctions = require("./mapFunctions");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const TelrResultLayerFunctions = require("../scenario/telrResultLayerFunctions");
const AnnualReports = require("../report/annualReports");
const Session = require("../login/session");
const LayerFunctions = require("../layerFunctions");
