"use strict";

const MuniOutfallsGeoServerLayer = function () {
  const layerName = "muniOutfalls";
  const layerKey = "muniOutfallsLayer";

  var createMarkerFunction = function (feature, latlng) {
    return OutfallsIcon.getOutfallsMarker(
      latlng,
      feature.properties.rotationAzimuth,
      feature.properties.isHighPriority,
      feature.properties.condition,
      feature.properties.outfallInspectionIsDue,
    );
  };

  var createIconFunction = function (feature) {
    const isHighPriority = ["high", "very-high"].includes(feature.properties.cof);
    return OutfallsIcon.getOutfallsMarkerIcon(
      isHighPriority,
      feature.properties.condition,
      feature.properties.outfallInspectionIsDue,
    );
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const muniOutfallsGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
      createIconFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, muniOutfallsGeoServerLayer);

    muniOutfallsGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniOutfallsGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var organizeLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    const toDoData = GeoServerLayerFunctions.prepareToDoData(data, {
      outfall_inspection: "muniOutfallRoutineInspection",
      outfall_repair: "muniOutfallRoutineMaintenance",
    });
    RoutineMaintenanceFormTable.processRepairAdditionalInfo(
      toDoData.muniOutfallRoutineMaintenance.additionalInfo,
    );

    const boolOrNullPriority = getBoolOrNullPriority(data.cof_final_text);
    return {
      id: data.id,
      groupId: data.group_id,
      geom: geometry,
      geometry: geometry,
      name: data.outfall_name,
      outfallName: data.outfall_name,
      rotationAzimuth: data.rotation_azimuth,
      priority: boolOrNullPriority,
      isHighPriority: boolOrNullPriority === true,
      highwayAssignment: data.highway_assignment,
      address: data.address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      mileMarker: data.mile_marker,
      maintenanceSection: data.maintenance_section,
      patrol: data.patrol,
      phase: data.phase,
      region: data.region,
      drainageAreaAcres: data.drainage_area_acres,
      outletLength: data.outlet_length,
      outletLengthUnits: data.outlet_length_units,
      outletDiameter: data.outlet_diameter,
      outletDiameterUnits: data.outlet_diameter_units,
      outletWidth: data.outlet_width,
      outletWidthUnits: data.outlet_width_units,
      outletInvertElevation: data.outlet_invert_elevation,
      outletInvertElevationUnits: data.outlet_invert_elevation_units,
      outletBaseElevation: data.outlet_base_elevation,
      outletBaseElevationUnits: data.outlet_base_elevation_units,
      outletRimElevation: data.outlet_rim_elevation,
      outletRimElevationUnits: data.outlet_rim_elevation_units,
      outletType: data.outlet_type,
      outfallType: data.outfall_type,
      displayOutletType: _getDisplayOutletType(data.outlet_type),
      displayOutfallType: _getDisplayOutfallType(data.outfall_type),
      outletShape: data.outlet_shape,
      material: data.outlet_material,
      outletMaterial: data.outlet_material,
      outletOtherShape: data.outlet_other_shape,
      outletOtherMaterial: data.outlet_other_material,
      installDate: data.install_date,
      landOwnership: data.property,
      implementerType: data.implementer_type,
      maintainerType: data.maintainer_type,
      structureId: data.structure_id,
      routineSiteVisitFrequencyMonths: data.routine_site_visit_frequency_months,
      notes: data.notes,
      comments: data.comments,
      condition: RoutineMaintenanceIcon.getLayerCondition(
        data.last_maintenance_inspection_id,
        data.todo_is_outfall_repair,
      ),
      asset: {
        id: data.id,
        name: data.outfall_name,
        assetType: "outfall",
        assetDisplayType: ToolSettings.getSetting("constants", "assetDisplayNames", "outfall"),
        isMonitoringLocation: data.monitoring_location_id !== null,
      },
      pof: data.pof_text,
      cof: data.cof_final_text,
      usefulLife: data.useful_life,
      attributed: data.attributed,
      permissions: {
        canCreateOutfallInspection: ToolSettings.getSetting(
          "permissions",
          "can_create_outfall_inspection",
        ),
        canDeleteOutfallInspection: ToolSettings.getSetting(
          "permissions",
          "can_delete_outfall_inspection",
        ),
        canBeDeleted: ToolSettings.getSetting("permissions", "can_delete_outfall"),
        canBeEdited: ToolSettings.getSetting("permissions", "can_edit_outfall"),
      },
      routineRepairDue: data.todo_is_outfall_repair,
      outfallInspectionIsDue: data.todo_is_outfall_inspection,
      activeMaintenanceIssues: toDoData.muniOutfallRoutineMaintenance?.additionalInfo ?? [],
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      zone_names: data.zone_names,
      routine: data.routine_inspection_dates ? JSON.parse(data.routine_inspection_dates) : null,
      repair: data.repair_dates ? JSON.parse(data.repair_dates) : null,
      ...toDoData,
      enforcementLevel: data.additional_info
        ? GeoServerLayerFunctions.getAdditionalInfo(JSON.parse(data.additional_info), "level")
        : null,
      correctiveAction: data.additional_info
        ? GeoServerLayerFunctions.getAdditionalInfo(JSON.parse(data.additional_info), "key")
        : null,
    };
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
    );
    return organizeLayerData(data);
  };

  var getOfflineData = async function (idbmp) {
    return await ResourceController.get("muniOutfalls").getById(idbmp);
  };

  var getBoolOrNullPriority = function (priority) {
    if (["high", "very-high"].includes(priority)) {
      return true;
    } else if (["low", "moderate"].includes(priority)) {
      return false;
    }

    return null;
  };

  var _getDisplayOutletType = function (outletType) {
    return FormSettings.getDropdownName("outlet-type", outletType) ?? "";
  };

  var _getDisplayOutfallType = function (outfallType) {
    return FormSettings.getDropdownName("outfall-type", outfallType) ?? "";
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeLayerData,
    getLayerData,
    getOfflineData,
    _getDisplayOutletType,
  };
};

module.exports = MuniOutfallsGeoServerLayer();

const FormSettings = require("../../settings/formSettings");
const GeoServerLayerFunctions = require("../../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../../mapFunctions/geoServerMapLayer");
const OutfallsIcon = require("../../outfalls/outfallsIcon");
const ResourceController = require("../../offline/resourceController");
const ToolSettings = require("../../settings/toolSettings");
const RoutineMaintenanceFormTable = require("../../general/routineMaintenanceFormTable");
const RoutineMaintenanceIcon = require("../../general/routineMaintenanceIcon");
