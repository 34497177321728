"use strict";

const assetManagementController = function () {
  var renderWidgetScreen = function () {
    const html = nunjucks.render("modals/assetManagement/assetManagementWidget.njk", {
      assets: AssetManagementWidgetDataCache.getWidgetDataArray(),
    });
    $("#widget-page-container").html(html);
    handleAssetBarHoverDisplay();
  };

  var handleAssetBarHoverDisplay = function () {
    $(".asset-bar").off("mouseenter");
    $(".asset-bar").on("mouseenter", function () {
      const percentage = $(this).data("percentage");
      $(this)
        .parents(".asset-management-chart-content")
        .find(".asset-bar-tool-tip")
        .css("visibility", "visible")
        .find("span")
        .text(percentage);
    });

    $(".asset-bar").off("mouseleave");
    $(".asset-bar").on("mouseleave", function () {
      $(this)
        .parents(".asset-management-chart-content")
        .find(".asset-bar-tool-tip")
        .css("visibility", "hidden")
        .find("span")
        .text("");
    });
  };

  return {
    renderWidgetScreen,
  };
};

module.exports = assetManagementController();

const AssetManagementWidgetDataCache = require("./assetManagementWidgetDataCache");
