"use strict";

const Session = function () {
  var initSession = function (data) {
    saveContext(data.context);
    TokenManager.saveToken(data.token);
    setSentryUserContext(data.context);
    Analytics.init(data.context);

    $(".username").text(data.context.username);
  };

  var saveContext = function (context) {
    Object.keys(context).forEach(function (key, index) {
      const groupArray = [
        "groupId",
        "latlng",
        "lat",
        "lng",
        "group_role",
        "group",
        "isRegulator",
        "is_parent",
      ];
      const userArray = [
        "username",
        "full_name",
        "admin_type",
        "email",
        "group",
        "group_role",
        "paying",
        "trueGroupId",
        "export",
        "is_2n_admin",
        "is_limited_access",
        "userId",
        "can_manage_users",
        "isSsoUser",
        "enabledFlags",
      ];

      if (userArray.indexOf(key) != -1) {
        Tree.set(["user", key], context[key]);
      }

      if (groupArray.indexOf(key) != -1) {
        Tree.set(["rootGroup", key], context[key]);
      }

      if (key == "latlng") {
        Tree.set(["rootGroup", "latlng"], context[key]);
        Tree.set(["rootGroup", "lat"], context[key][0]);
        Tree.set(["rootGroup", "lng"], context[key][1]);
      }

      if (key === "group") {
        Tree.set(["rootGroup", "name"], context[key]);
        Tree.set(["user", "name"], context[key]);
      }

      if (key === "groupId") {
        Tree.set(["rootGroup", "groupId"], context[key]);
        Tree.set(["user", "trueGroupId"], context[key]);
      }

      if (groupArray.indexOf(key) == -1) {
        Tree.set(key, context[key]);
      }
    });
    Tree.set("activeGroup", { ...Tree.get("rootGroup") });
  };

  var saveUserRole = function (permissionsArray) {
    Tree.set(["user", "user_role"], permissionsArray["user_role"]);
  };

  // misleading name, use getUser instead
  var loadContext = function () {
    const user = Tree.get("user");
    if (!user) {
      throw new Error("cannot load context before calling Session.initSession");
    }
    return user;
  };

  var getUser = function () {
    return Tree.get("user");
  };

  var clear = function () {
    sessionStorage.clear();
  };

  var getGroupIdToPass = function () {
    var activeGroupId = Tree.get("activeGroup", "groupId");
    return activeGroupId;
  };

  var isRegulator = function (shouldThrow = true) {
    return Progeny.isRegulator(shouldThrow);
  };

  var isFieldPersonnel = function () {
    return Tree.get("user", "user_role") === "field-personnel";
  };

  var isAdmin = function () {
    return Tree.get("user", "user_role") === "admin";
  };

  var loadCurrentBmp = function () {
    return JSON.parse(sessionStorage.getItem("BMP"));
  };

  var replace = function (item, value) {
    sessionStorage.removeItem(item);
    sessionStorage.setItem(item, JSON.stringify(value));
  };

  var isDemoAccount = function () {
    return getUser().userId === 56;
  };

  var isDemoGroup = function () {
    const groupId = Tree.get("activeGroup", "groupId");
    return groupId === 8;
  };

  var setSentryUserContext = function (context) {
    Sentry.getCurrentScope().setUser({
      id: context.userId,
      username: context.username,
      adminType: context.admin_type,
      groupName: context.group,
      email: context.email,
      name: context.full_name,
      groupId: context.groupId,
      role: context.user_role,
    });
  };

  var is2nAdmin = function () {
    return Tree.get("user", "is_2n_admin");
  };

  var isParent = function () {
    return Tree.get("rootGroup", "is_parent");
  };

  var isCwp = function () {
    const cwpGroupId = 168;
    return cwpGroupId == Tree.get("rootGroup", "groupId");
  };

  var isMmsd = function () {
    const mmsdGroupId = 170;
    return mmsdGroupId == Tree.get("rootGroup", "groupId");
  };

  var isCdot = function () {
    // Including findings groups
    const cdotGroupIds = [1776, 1775, 1774, 1773, 1772, 1771, 1966, 1967, 2065, 2066, 2067, 2068];
    return cdotGroupIds.includes(Tree.get("rootGroup", "groupId"));
  };

  var isSalinas = function () {
    const salinasGroupId = 15;
    return salinasGroupId === Tree.get("activeGroup", "groupId");
  };

  var isUlar = function () {
    const ularGroupId = 11261;
    return ularGroupId === Tree.get("rootGroup", "groupId");
  };

  var isVentura = function () {
    const venturaGroupId = 74424;
    return venturaGroupId === Tree.get("rootGroup", "groupId");
  };

  var isBatonRouge = function () {
    const batonRougeGroupId = 32874;
    return batonRougeGroupId === Tree.get("activeGroup", "groupId");
  };

  var isLargeGroup = function () {
    const venturaGroupId = 74424;
    const ularGroupId = 11261;
    const berkeleyGroupId = 1807;
    const cdotAndChildGroupIds = [
      1776, 1775, 1774, 1773, 1772, 1771, 1966, 1967, 2065, 2066, 2067, 2068,
    ];
    const brightstormLA = 114385;
    const largeGroups = [
      venturaGroupId,
      ularGroupId,
      berkeleyGroupId,
      brightstormLA,
      ...cdotAndChildGroupIds,
    ];

    return largeGroups.includes(Tree.get("rootGroup", "groupId"));
  };

  var isNewDataDrivenRoadConditionGroup = function () {
    const newDataDrivenRoadConditionGroupIds = [15, 8, 32874, 114121, 114223];
    const groupId = Tree.get("activeGroup", "groupId");

    return newDataDrivenRoadConditionGroupIds.includes(groupId);
  };

  var isDisabledRouteOmGroup = function () {
    const blockedGroups = []; // Cotati, Sebastopol, Sonoma State, Ukiah, Martinsburg, Elsmere
    const earlyAdopterGroups = [];
    const groupId = Tree.get("activeGroup", "groupId");

    return blockedGroups.includes(groupId) || earlyAdopterGroups.includes(groupId);
  };

  var isBlankSlateRoadConditionGroup = function () {
    return !isNewDataDrivenRoadConditionGroup() && !isDisabledRouteOmGroup();
  };

  var isMobileParentView = function () {
    return Device.isPrimaryInputTouch();
  };

  var isLoggedIn = function () {
    return Tree.get("user", "username") !== "";
  };

  var groupNameContains = function (string) {
    return Tree.get(["rootGroup", "name"]).toLowerCase().includes(string.toLowerCase());
  };

  var isTestingOrDemoAdminAccount = function () {
    return Tree.get("user", "is_2n_admin") && [8, 10].includes(Tree.get("user", "trueGroupId"));
  };

  return {
    clear,
    getGroupIdToPass,
    getUser,
    initSession,
    groupNameContains,
    isTestingOrDemoAdminAccount,
    is2nAdmin,
    isCdot,
    isCwp,
    isDemoGroup,
    isDemoAccount,
    isLargeGroup,
    isMmsd,
    isMobileParentView,
    isParent,
    isFieldPersonnel,
    isAdmin,
    isRegulator,
    isSalinas,
    isUlar,
    isVentura,
    isBatonRouge,
    isNewDataDrivenRoadConditionGroup,
    isDisabledRouteOmGroup,
    isBlankSlateRoadConditionGroup,
    loadContext,
    loadCurrentBmp,
    replace,
    saveContext,
    saveUserRole,
    isLoggedIn,
  };
};

module.exports = Session();

const Device = require("../device");
const Tree = require("../tree");
const Sentry = require("@sentry/browser");
const Progeny = require("../login/progeny");
const Analytics = require("../general/analytics");
const TokenManager = require("./tokenManager");
