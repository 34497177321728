"use strict";

function FacilitySelfInspectionController() {
  const htmlPath = "modals/facilitySelfInspection";
  const formKey = "facility-self-inspection";

  let $modal;
  let initialData;
  let initialFormData = {};

  async function loadNew(facilityId, readOnly = false) {
    initialData = await ApiCalls.getNewFacilitySelfInspection(facilityId);

    _showModal(readOnly);
  }

  function _showModal(readOnly) {
    initialFormData = {};
    _showTabbedModal(readOnly);
    loadListeners();
    setAdditionalModalHandlers();
    _updateSaveButton();
  }

  function _showTabbedModal(readOnly) {
    const tabs = [FacilitySelfInspectionPage];
    const renderData = _mapTabProps(tabs);
    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, {
      readOnly,
      extraButtons: [
        { text: "Preview", type: "primary", callback: downloadPreviewPdf, class: "preview-button" },
      ],
    });
    TabbedModal.setHeaderText(
      nunjucks.render(`${htmlPath}/facilitySelfInspectionHeader.njk`, renderData),
    );
  }

  function _mapTabProps(tabs) {
    const data = getAllData();

    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...initialFormData,
      ...tabProps,
      base: Config.get().apiUrl,
    };
  }

  function loadListeners() {
    Form.initializeAndLoadListeners($modal, formKey);
    FormSettings.customizeForm($modal, formKey);
    Form.manuallySetFormDataField(formKey, [], initialFormData);
    $modal.on("2N:FormInput", _updateSaveButton);
  }

  function unloadListeners() {
    $modal.off("2N:FormInput", _updateSaveButton);
    Form.clearForm(formKey);
  }

  function setAdditionalModalHandlers() {
    TabbedModal.setCancelHandler(unloadListeners);
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
  }

  async function saveButtonClick() {
    if (await confirmSave()) {
      return await saveAndCloseModal();
    }

    return false;
  }

  async function confirmSave() {
    const actionState = getCurrentActionState();
    try {
      await MessageModal.showConfirmWarningModalAsPromise(actionState.saveWarning, {
        okMessage: actionState.saveText,
        returnMessage: "Go Back",
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async function saveAndCloseModal() {
    try {
      await Form.getReadyToSavePromise(formKey);
      const uploadData = getUploadData();
      await ApiCalls.createFacilitySelfInspection(uploadData);
      TabbedModal.hide();
      unloadListeners();
      IndcomFacilityGeoServerLayer.invalidateLayerData();
    } catch (e) {
      return UploadValidator.reportError(e);
    }

    return true;
  }

  function getUploadData() {
    const updates = Form.getDataFromForm(formKey, false);
    const actionState = getCurrentActionState();
    updates.action = actionState.action;

    return {
      updates: updates,
      inspectionId: initialData.inspection.id,
      selfInspectionId: initialData.selfInspection?.id,
    };
  }

  function formHasUpdates() {
    return !Form.isFormEmpty(formKey);
  }

  function getAllData() {
    return $.extend(true, {}, initialData, Form.getDataFromForm(formKey, false));
  }

  function setFormData(path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  }

  function getInitialFormDataObject() {
    return initialFormData;
  }

  function _getRemainingRequiredFields(requiredFields) {
    const data = getAllData();
    const locationObservationResponses = (data.locationObservationResponses ?? []).filter(
      (finding) => finding.compliant,
    );

    return requiredFields.filter(function (field) {
      if (field === "locationObservationResponses") {
        return locationObservationResponses.length !== initialData.locationObservations.length;
      }

      return !Form.getDataAtPath(data, Form.getPathPartsFromName(field));
    });
  }

  function _updateSaveButton() {
    const actionState = getCurrentActionState();
    const isDisabled = _getRemainingRequiredFields(actionState.requiredFields).length > 0;
    TabbedModal.disableSaveButton(isDisabled);
    TabbedModal.setSaveButtonText(actionState.saveText);

    const $previewButton = $(".preview-button");
    $previewButton.toggle(actionState.showPreview);
    $previewButton.prop("disabled", isDisabled);
  }

  function getCurrentActionState() {
    const data = getAllData();
    const locationObservationResponses = (data.locationObservationResponses ?? []).filter(
      (finding) => finding.compliant,
    );
    const allCorrected =
      initialData.locationObservations.length ===
      locationObservationResponses.filter((finding) => finding.compliant === "yes").length;

    if (data.action === "respond") {
      if (allCorrected) {
        if (data.selfInspection) {
          return getActionState("respond-approve");
        } else {
          return getActionState("receive-approve");
        }
      } else {
        if (data.selfInspection) {
          return getActionState("respond-reject");
        } else {
          return getActionState("receive-reject");
        }
      }
    } else {
      return getActionState(data.action);
    }
  }

  function getActionState(action) {
    if (!action) {
      return {
        requiredFields: ["action"],
        showPreview: false,
      };
    }

    const status = {
      "respond-approve": {
        requiredFields: ["action", "locationObservationResponses"],
        saveText: "Save & Approve",
        saveWarning: `This will close all open findings associated with this facility. `,
      },
      "respond-reject": {
        requiredFields: ["action", "locationObservationResponses", "letter[dueDate]"],
        saveText: "Reject & Generate",
        saveWarning: `Clicking "Reject & Generate" will save the information entered and generate a letter PDF with a new Collaboration Portal response link for this facility.`,
        showPreview: true,
      },
      "receive-approve": {
        requiredFields: ["action", "locationObservationResponses", "response[receivedDate]"],
        saveText: "Save & Approve",
        saveWarning: `This will close all open findings associated with this facility. `,
      },
      "receive-reject": {
        requiredFields: [
          "action",
          "locationObservationResponses",
          "letter[dueDate]",
          "response[receivedDate]",
        ],
        saveText: "Reject & Generate",
        saveWarning: `Clicking "Reject & Generate" will save the information entered and generate a letter PDF with a new Collaboration Portal response link for this facility.`,
        showPreview: true,
      },
      unresponsive: {
        requiredFields: ["action", "letter[followUpDate]"],
        saveText: "Save & Generate",
        saveWarning: `Clicking "Save & Generate" will save the information entered and generate a letter PDF with a new Collaboration Portal response link for this facility. It will also schedule an onsite followup for the date specified.`,
        showPreview: true,
      },
      remind: {
        requiredFields: ["action", "letter[dueDate]"],
        saveText: "Save & Generate",
        saveWarning: `Clicking "Save & Generate" will save the information entered and generate a letter PDF with a new Collaboration Portal response link for this facility.`,
        showPreview: true,
      },
    }[action];

    if (!status) {
      throw new Error(`Invalid action state ${action}`);
    }

    status.action = action;

    return status;
  }

  function downloadPreviewPdf() {
    InspectionPreview.preview(async function () {
      await Form.getReadyToSavePromise(formKey);
      return getUploadData();
    }, ApiCalls.previewFacilitySelfInspectionPdf);
  }

  return {
    loadNew,
    getAllData,
    saveAndCloseModal,
    setFormData,
    getInitialFormDataObject,
    getCurrentActionState,
  };
}

module.exports = FacilitySelfInspectionController();

const Config = require("../../config");
const Form = require("../../general/form");
const FormSettings = require("../../settings/formSettings");
const TabbedModal = require("../../general/tabbedModal");
const UploadValidator = require("../../general/uploadValidator");
const ApiCalls = require("../../apiCalls");
const FacilitySelfInspectionPage = require("./facilitySelfInspectionPage");
const MessageModal = require("../../modals/messageModal");
const IndcomFacilityGeoServerLayer = require("../indcomFacilityGeoServerLayer");
const InspectionPreview = require("../../general/inspectionPreview");
