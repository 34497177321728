"use strict";

const NunjucksFunctions = function () {
  var setupFunctions = function (env) {
    const functionList = {
      getConstructionActionDropdownOptions,
      getLidActionDropdownOptions,
      getConstructionProjectDeliveryActionDropdownOptions,
      _getConstructionComplianceActionDropdownOptions,
      getFacilityActionDropdownOptions,
      getCulvertDropdownOptions,
      getActivityActionDropdownOptions,
      getCatchBasinDropdownOptions,
      getStructuralBmpActionDropdownOptions,
      getManHoleDropdownOptions,
      getCleanoutDropdownOptions,
      getOutfallDropdownOptions,
      getOpenChannelActionDropdownOptions,
      getScenarioDropdownOptions,
      getTrashFcsDropdownOptions,
      getPropertyDropdownOptions,
      getPlanDropdownOptions,
      getPlanPropertyDropdownOptions,
      getLidFactSheetDropdownOptions,
      getFactSheetDropdownOptions,
      getBmpDropdownOptions,
      getContactDropdownOptions,
      getProjectUserDropdownOptions,
      getPermitAndPlanDropdownOptions,
      getProjectHistoryDropdownOptions,
      getUserDropdownOptions,
      getDisplayFieldSetting,
      isPhaseOrLater,
      getDeviceSize,
      featureFlagEnabled,
      getDisplayFallback,
      getAssetDisplayName,
    };

    // Can't use an array because function names get minimized on production
    for (const fnName in functionList) {
      env.addGlobal(fnName, functionList[fnName]);
    }
  };

  const getConstructionActionDropdownOptions = function (
    dropdownOptions,
    canEditProject,
    canIssueEnforcement,
  ) {
    const currentDataView = Tree.get("dataView");
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: canEditProject ? false : true,
      },
    ];

    if (FeatureFlag.enabled("download-project-photos")) {
      defaultOptions.push({
        name: "Download Project Photos",
        analyticsAction: "download_photos",
        iconClass: "glyphicon-download-alt",
        linkClass: "download-button online-only",
      });
    }

    if (canEditProject) {
      defaultOptions.push({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
        primary: dropdownOptions?.find((option) => option.name === "Site Visit") ? false : true,
      });
    }

    if (currentDataView === "lid-project") {
      if (canIssueEnforcement) {
        defaultOptions.push({
          name: "Issue Enforcement",
          analyticsAction: "issue_enforcement",
          iconClass: "glyphicon-issue-enforcement",
          linkClass: "issue-lid-enforcement online-only",
        });
      }

      return defaultOptions.concat(dropdownOptions)?.reverse();
    }
    if (currentDataView === "construction-project") {
      const options = defaultOptions.concat(dropdownOptions);
      const sortOrders = [
        "Edit Inventory",
        "Site Visit",
        "Restart Inspections",
        "Pause Inspections",
        "Complete Project",
        "Go to Map",
        "Download Project Photos",
      ];
      sortActionsByName(options, sortOrders);
      return options;
    } else {
      return defaultOptions.concat(dropdownOptions);
    }
  };

  const getLidActionDropdownOptions = function (project) {
    const actionableOptions = [];

    _addLidInspectionLetter(project.showGenerateSummaryButton, actionableOptions);

    return actionableOptions;
  };

  const _addLidInspectionLetter = function (showGenerateSummaryButton, actionableOptions) {
    if (FeatureFlag.enabled("pcr-letter") && showGenerateSummaryButton) {
      actionableOptions.push({
        name: "Generate Inspection Summary Letter",
        iconClass: "mail",
        linkClass: "js-inspection-letter online-only",
      });
    }
  };

  const getConstructionProjectDeliveryActionDropdownOptions = function (
    canAdvanceProjectPhase,
    canCertifyProject,
    projectPhase,
  ) {
    // Can't include at the bottom because it interferes with testing mocks
    const ProjectStageData = require("../construction/projectStageData");
    const enablePostActivePhase = ToolSettings.getSetting(
      "construction",
      "enable-post-active-phase",
    );
    const dropdownOptions = [];
    const isCertifyStage = ProjectStageData.getStageByPhase(projectPhase) === "certified";

    if (!isCertifyStage && canAdvanceProjectPhase) {
      dropdownOptions.unshift({
        name: "Change Phase",
        iconClass: "glyphicon-arrow-right",
        linkClass: "phase-button online-only",
      });
    }

    if (projectPhase === "completed" && canCertifyProject) {
      dropdownOptions.unshift({
        name: "Certify Project",
        iconClass: "glyphicon-ok",
        linkClass: "certify-button online-only",
      });
    }

    if (canCertifyProject) {
      if (enablePostActivePhase && projectPhase === "construction") {
        dropdownOptions.unshift({
          name: "Set as Post Active",
          iconClass: "glyphicon-arrow-right",
          linkClass: "post-active-button online-only",
        });
      } else if (
        (!enablePostActivePhase && projectPhase === "construction") ||
        (enablePostActivePhase && projectPhase === "post-active")
      ) {
        dropdownOptions.unshift({
          name: "Complete Project",
          analyticsAction: "complete_proj",
          iconClass: "glyphicon-ok",
          linkClass: "completed-button online-only",
        });
      }
    }

    return dropdownOptions;
  };

  const _getConstructionComplianceActionDropdownOptions = function (
    projectId,
    phase,
    pauseStatus,
    canInspectProject,
    canCertifyProject,
    canAdvanceProjectPhase,
  ) {
    const dropdownOptions = [];

    if (canInspectProject && projectId) {
      dropdownOptions.unshift({
        primary: true,
        name: "Site Visit",
        analyticsAction: "add_site_visit",
        iconClass: "glyphicon-plus-sign",
        linkClass: "js-add-construction-project-inspection",
        linkAttribute: `data-project-id='${projectId}'`,
      });
    }

    if (canAdvanceProjectPhase && phase === "construction") {
      dropdownOptions.unshift({
        name: pauseStatus === "paused" ? "Restart Inspections" : "Pause Inspections",
        analyticsAction: "pause_project",
        iconClass:
          pauseStatus === "paused" ? "glyphicon glyphicon-play" : "glyphicon glyphicon-pause",
        linkClass: "pause-button online-only",
      });
    }

    const enablePostActivePhase = ToolSettings.getSetting(
      "construction",
      "enable-post-active-phase",
    );
    if (canCertifyProject) {
      if (enablePostActivePhase && phase === "construction") {
        dropdownOptions.unshift({
          name: "Set as Post Active",
          iconClass: "glyphicon-arrow-right",
          linkClass: "post-active-button online-only",
        });
      } else if (
        (!enablePostActivePhase && phase === "construction") ||
        (enablePostActivePhase && phase === "post-active")
      ) {
        dropdownOptions.unshift({
          name: "Complete Project",
          analyticsAction: "complete_proj",
          iconClass: "glyphicon-ok",
          linkClass: "completed-button online-only",
        });
      }
    }

    return dropdownOptions;
  };

  const getOpenChannelActionDropdownOptions = function () {
    return [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: true,
      },
    ];
  };

  const getActivityActionDropdownOptions = function (canEditActivity, canDeleteActivity) {
    const dropdownOptions = [];

    if (canEditActivity) {
      dropdownOptions.push({
        name: "Edit Activity",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button",
        primary: true,
      });

      dropdownOptions.push({
        name: "Copy",
        iconClass: "glyphicon-duplicate",
        linkClass: "copy-button",
      });
    }

    if (canDeleteActivity && !Session.isFieldPersonnel()) {
      dropdownOptions.push({
        name: "Delete Activity",
        iconClass: "glyphicon-trash",
        linkClass: "delete-button",
      });
    }

    return dropdownOptions;
  };

  var getCulvertDropdownOptions = function () {
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: true,
      },
    ];
    return defaultOptions;
  };

  const getFacilityActionDropdownOptions = function (
    canInspectFacility,
    canIssueEnforcement,
    canCreateFacility,
  ) {
    const optionDropdowns = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: canInspectFacility ? false : true,
      },
    ];

    if (canCreateFacility) {
      optionDropdowns.unshift({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
        primary: true,
      });
    }

    if (canInspectFacility) {
      optionDropdowns.unshift({
        name: "Site Visit",
        analyticsAction: "add_site_visit",
        iconClass: "glyphicon-plus-sign",
        linkClass: "add-facility-inspection",
        primary: true,
      });
    }

    if (canIssueEnforcement) {
      optionDropdowns.unshift({
        name: "Issue Enforcement",
        analyticsAction: "issue_enforcement",
        iconClass: "glyphicon-issue-enforcement",
        linkClass: "issue-facility-enforcement online-only",
      });
    }

    const temp = optionDropdowns[0];
    optionDropdowns[0] = optionDropdowns[1];
    optionDropdowns[1] = temp;
    return optionDropdowns;
  };

  const getCatchBasinDropdownOptions = function (id, phase, permissions) {
    const props = {};
    const MuniCatchBasinPopup = require("../muni/muniCatchBasinPopup");
    MuniCatchBasinPopup.addInspectionPermissions({ phase, permissions }, props);

    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: permissions.canBeEdited ? false : true,
      },
    ];

    if (permissions.canBeEdited) {
      defaultOptions.unshift({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
        primary:
          props.displayAddInspectionButton || props.displayDocumentRepairsButton ? false : true,
      });
    }
    if (props.displayAddInspectionButton) {
      defaultOptions.unshift({
        name: "Inspect",
        analyticsAction: "add_site_visit",
        primary: true,
        iconClass: "glyphicon-plus-sign",
        href: `/ram/asset/catchBasin/${id}/routineInspection`,
      });
    }

    return defaultOptions;
  };

  var getManHoleDropdownOptions = function () {
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: true,
      },
    ];
    return defaultOptions;
  };

  var getCleanoutDropdownOptions = function () {
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: true,
      },
    ];
    return defaultOptions;
  };

  const getStructuralBmpActionDropdownOptions = function (
    permissions,
    idBmp,
    showRoutineMaintenanceButton,
    showAddNewBenchmarkButton,
    showEditBenchmarkButton,
    showAddSbmpAssessmentButton,
  ) {
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: permissions.canBeEdited ? false : true,
      },
    ];

    if (permissions.canBeEdited || permissions.can_edit_bmps) {
      defaultOptions.unshift({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
        primary: showAddSbmpAssessmentButton ? false : true,
      });
    }

    if (showAddSbmpAssessmentButton) {
      defaultOptions.unshift({
        name: "Assess",
        analyticsAction: "add_bmp_assessment",
        iconClass: "glyphicon-check",
        linkClass: "add-assessment-button",
        primary: true,
      });
    }

    if (showEditBenchmarkButton) {
      defaultOptions.unshift({
        name: "Benchmark",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-benchmark-button",
      });
    }

    if (showAddNewBenchmarkButton) {
      defaultOptions.unshift({
        name: "Benchmark",
        analyticsAction: "add_new_benchmark",
        iconClass: "glyphicon-check",
        linkClass: "add-benchmark-button",
      });
    }
    if (showRoutineMaintenanceButton) {
      defaultOptions.unshift({
        name: "Inspect",
        analyticsAction: "add_site_visit",
        iconClass: "glyphicon-plus-sign",
        href: `/ram/asset/bmpFcs/${idBmp}/routineInspection`,
      });
    }

    return defaultOptions;
  };

  const getScenarioDropdownOptions = function (
    id,
    phase,
    canAdvanceProjectPhase,
    canCertifyProject,
    projectPhase,
  ) {
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only disabled hide",
      },
    ];
    const props = {};
    let options = defaultOptions;

    addScenarioPlanningPermissions(phase, props);
    if (props.canEdit) {
      options.unshift({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
      });
    }

    if (projectPhase) {
      const projectDeliveryActionDropdownOptions =
        getConstructionProjectDeliveryActionDropdownOptions(
          canAdvanceProjectPhase,
          canCertifyProject,
          projectPhase,
        );
      options = options.concat(projectDeliveryActionDropdownOptions);
    }

    return options;
  };

  var addScenarioPlanningPermissions = function (phase, props) {
    const role = ToolSettings.getSetting("permissions", "user_role");
    props.canEdit =
      ["admin", "data-manager"].includes(role) ||
      (role === "planner" && SharedDataFunctions.isBeforePhase(phase, "post-active"));
  };

  const getTrashFcsDropdownOptions = function (permissions, planned, fcsStat) {
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: permissions.canBeEdited ? false : true,
      },
    ];

    if (permissions.canBeEdited) {
      defaultOptions.unshift({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
        primary: fcsStat && !planned && permissions.canCreateObservation ? false : true,
      });
    }
    if (fcsStat && !planned && permissions.canCreateObservation) {
      defaultOptions.unshift({
        name: "Assess",
        analyticsAction: "add_site_visit",
        iconClass: "glyphicon-check",
        linkClass: "show-fcs-assessment",
        primary: true,
      });
    }

    return defaultOptions;
  };

  const getOutfallDropdownOptions = function (id, permissions, routineRepairDue) {
    const defaultOptions = [
      {
        name: "Go to Map",
        analyticsAction: "go_to_map",
        iconClass: "glyphicon-map-marker",
        linkClass: "map-button online-only",
        primary: permissions.canBeEdited ? false : true,
      },
    ];

    if (permissions.canBeEdited) {
      defaultOptions.push({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
        primary: permissions.canCreateOutfallInspection ? false : true,
      });
    }

    if (permissions?.canCreateOutfallInspection) {
      defaultOptions.push({
        name: "Inspect",
        analyticsAction: "add_site_visit",
        primary: true,
        iconClass: "glyphicon-plus-sign",
        href: `/ram/asset/outfall/${id}/routineInspection`,
      });
    }

    return defaultOptions?.reverse();
  };

  const getPropertyDropdownOptions = function (id, canCreateBmps, hasBoundary) {
    return [
      {
        name: "Add sBMP",
        primary: true,
        iconClass: "glyphicon-plus",
        linkClass: canCreateBmps && hasBoundary ? "add-sbmp-button" : "disabled",
        href: `#`,
      },
      {
        name: "Edit Property",
        primary: false,
        iconClass: "glyphicon-pencil",
        linkClass: canCreateBmps ? "edit-property-button" : "edit-property-button disabled",
        href: `#`,
      },
      {
        name: "Delete Property",
        primary: false,
        iconClass: "glyphicon-trash",
        linkClass: canCreateBmps ? "delete-property-button" : "disabled",
        href: `#`,
      },
    ];
  };

  const getLidFactSheetDropdownOptions = function (
    canEditProject,
    canDeleteProject,
    canIssueEnforcement,
    showGenerateSummaryButton,
  ) {
    const items = [];

    _addLidInspectionLetter(showGenerateSummaryButton, items);

    if (canEditProject) {
      items.push({
        name: "Edit Inventory",
        analyticsAction: "edit_inventory",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-button online-only",
        primary: true,
      });
    }
    if (canIssueEnforcement) {
      items.push({
        name: "Issue Enforcement",
        analyticsAction: "issue_enforcement",
        iconClass: "glyphicon-issue-enforcement",
        linkClass: "issue-lid-enforcement online-only",
      });
    }
    items.push({
      name: "Go to Map",
      analyticsAction: "go_to_map",
      iconClass: "glyphicon-map-marker",
      linkClass: "map-button online-only",
      primary: canEditProject ? false : true,
    });
    if (FeatureFlag.enabled("download-project-photos")) {
      items.push({
        name: "Download Project Photos",
        analyticsAction: "download_photos",
        iconClass: "glyphicon-download-alt",
        linkClass: "download-button online-only",
      });
    }
    if (canDeleteProject) {
      items.push({
        name: "Delete Project",
        analyticsAction: "delete_project",
        iconClass: "glyphicon-trash",
        linkClass: "delete-button online-only",
      });
    }

    return items;
  };

  const getFactSheetDropdownOptions = function (
    id,
    planId,
    planStatus,
    canCreateBmps,
    hasBoundary = true,
  ) {
    let items = [
      {
        name: "Add sBMP",
        primary: true,
        iconClass: "glyphicon-plus",
        linkClass: "add-sbmp-button",
        href: "#",
      },
      {
        name: "Desktop Analysis",
        primary: true,
        iconClass: "esg-analysis",
        linkClass: hasBoundary ? "action-desktop-analysis" : "disabled",
        href: `#`,
      },
      {
        name: "Edit Property",
        primary: false,
        iconClass: "glyphicon-pencil",
        linkClass: "edit-property-button" + (canCreateBmps ? "" : " disabled"),
        href: `#`,
      },
    ];
    if (planId) {
      items = items.concat(getPlanPropertyDropdownOptions(planId, planStatus, canCreateBmps));
    } else {
      items = items.concat({
        name: "Delete Property",
        primary: false,
        iconClass: "glyphicon-trash",
        linkClass: canCreateBmps ? "delete-property-button" : "disabled",
        href: `#`,
      });
    }
    return items;
  };

  const getPlanDropdownOptions = function (id, phase, wizardCompleted) {
    const availableOptions = [];

    if (phase.toLowerCase() !== "incomplete") {
      availableOptions.push({
        name: "Edit Plan Info",
        primary: true,
        iconClass: "glyphicon-pencil",
        linkClass: "edit-plan-info",
        href: `/impact-accounting/stormwater-plans/edit-info/${id}`,
      });
    }

    if (phase.toLowerCase() === "draft") {
      availableOptions.push({
        name: "Recreate Plan",
        primary: false,
        iconClass: "glyphicon-repeat",
        linkClass: "recreate-plan-button",
        href: `#`,
      });
    }

    if (phase.toLowerCase() === "incomplete") {
      availableOptions.push({
        name: "Finish Creating Plan",
        primary: false,
        iconClass: "glyphicon-pencil",
        linkClass: "finish-creating-plan-button",
        href: `/impact-accounting/stormwater-plans/${id}`,
      });
    }

    if (wizardCompleted) {
      availableOptions.push({
        name: "Change Phase",
        primary: false,
        iconClass: "glyphicon-arrow-right",
        linkClass: "change-phase-button",
        href: "#",
      });
    }

    availableOptions.push({
      name: "Delete Plan",
      primary: false,
      iconClass: "glyphicon-trash",
      linkClass: "delete-plan-button",
      href: `#`,
    });

    return availableOptions;
  };

  const getPlanPropertyDropdownOptions = function (id, currentStatus = "suitable", canCreateBmps) {
    switch (currentStatus) {
      case "selected":
        return [
          {
            name: "Shelve",
            iconClass: "glyphicon-thumbs-down",
            linkClass: canCreateBmps ? "action-shelve-property" : "disabled",
            href: "#",
          },
        ];
      case "shelved":
        return [
          {
            name: "Select",
            primary: true,
            iconClass: "glyphicon-check",
            linkClass: canCreateBmps ? "select-property" : "disabled",
            href: "#",
          },
          {
            name: "Unshelve",
            iconClass: "glyphicon-thumbs-up",
            linkClass: canCreateBmps ? "unshelve-property" : "disabled",
            href: "#",
          },
        ];
      case "suitable":
      default:
        return [
          {
            name: "Select",
            primary: true,
            iconClass: "glyphicon-check",
            linkClass: canCreateBmps ? "select-property" : "disabled",
            href: "#",
          },
          {
            name: "Shelve",
            iconClass: "glyphicon-thumbs-down",
            linkClass: canCreateBmps ? "action-shelve-property" : "disabled",
            href: "#",
          },
        ];
    }
  };

  const getBmpDropdownOptions = function (canEdit) {
    const items = [];
    if (canEdit) {
      items.push({
        name: "Edit",
        primary: true,
        iconClass: "glyphicon-pencil",
        linkClass: "edit-sbmp-button",
        href: "#",
      });
    }
    return items;
  };

  const getContactDropdownOptions = function () {
    return [
      {
        name: "Edit Contact",
        primary: true,
        iconClass: "glyphicon-pencil",
        linkClass: "edit-contact",
        href: "#",
      },
    ];
  };

  const getProjectUserDropdownOptions = function () {
    return [
      {
        name: "Edit User",
        primary: true,
        iconClass: "glyphicon-pencil",
        linkClass: "edit-user",
        href: "#",
      },
    ];
  };

  const getPermitAndPlanDropdownOptions = function (availableForDownload, canEditProject) {
    const options = [];
    if (availableForDownload) {
      options.push({
        name: "Download",
        iconClass: "glyphicon-download-alt",
        linkClass: "download-file",
        href: "#",
      });
    }
    if (canEditProject) {
      options.push({
        name: "Edit",
        iconClass: "glyphicon-pencil",
        linkClass: "edit-file",
        href: "#",
      });
    }
    return options;
  };

  const getProjectHistoryDropdownOptions = function (row, readOnlyTopLevel) {
    const options = [];
    if (row.type === "enforcement") {
      if (row.result === "Open" && row.editable) {
        options.push({
          name: "Review",
          iconClass: "glyphicon-issue-enforcement",
          linkClass: "js-edit-lid-enforcement",
        });
      } else {
        options.push({
          name: "View Enforcement",
          iconClass: "glyphicon-eye-open",
          linkClass: "js-view-lid-enforcement",
        });
      }
    } else if (row.type === "enforcement-action") {
      if (row.viewable) {
        options.push({
          name: "View Enforcement Action",
          iconClass: "glyphicon-eye-open",
          linkClass: "show-enforcement-action",
        });
      }
      if (row.editable) {
        options.push({
          name: "Edit Enforcement Action",
          iconClass: "glyphicon-pencil",
          linkClass: "show-enforcement-action",
        });
      }
    } else if (row.type === "lid-send-notice" || row.type === "lid-receive-notice") {
      if (row.noActions || row.method === "Field Assessment") {
        return options;
      } else if (
        readOnlyTopLevel ||
        row.action === "send" ||
        row.action === "reminder" ||
        row.method === "Portal"
      ) {
        options.push({
          name: "View",
          iconClass: "glyphicon-eye-open",
          linkClass: "js-view-assessment",
        });
        if (row.letterFilename) {
          options.push({
            name: "Download letter",
            iconClass: "glyphicon-download-alt",
            linkClass: "download-inspection-report",
          });
        } else if (row.responseFilename) {
          options.push({
            name: "Download response",
            iconClass: "glyphicon-download-alt",
            linkClass: "download-response-report",
          });
        }
        if (row.canDeleteInspection && featureFlagEnabled("delete-lid-letter")) {
          options.push({
            name: "Delete",
            iconClass: "glyphicon-trash",
            linkClass: "js-delete-assessment",
          });
        }
      } else {
        if (row.canEditInspections) {
          options.push({
            name: "Edit",
            iconClass: "glyphicon-pencil",
            linkClass: "js-edit-assessment",
          });
        }
        if (row.canDeleteInspection) {
          options.push({
            name: "Delete",
            iconClass: "glyphicon-trash",
            linkClass: "js-delete-assessment",
          });
        }
      }
    } else if (row.type === "lid-send-summary") {
      options.push({
        name: "View",
        iconClass: "glyphicon-eye-open",
        linkClass: "js-view-summary",
      });

      if (row.letterFilename) {
        options.push({
          name: "Download letter",
          iconClass: "glyphicon-download-alt",
          linkClass: "download-summary-report",
        });
      }

      if (row.canDeleteInspection && featureFlagEnabled("delete-lid-letter")) {
        options.push({
          name: "Delete",
          iconClass: "glyphicon-trash",
          linkClass: "js-delete-summary",
        });
      }
    } else if (row.type === "inspection" || row.type === "installation-inspection") {
      if (row.editable && row.type === "inspection") {
        options.push({
          name: "Edit Inspection",
          iconClass: "glyphicon-pencil",
          linkClass: "js-edit-construction-project-inspection",
        });
      }
      if (row.deletable && row.type === "inspection") {
        options.push({
          name: "Delete Inspection",
          iconClass: "glyphicon-trash",
          linkClass: "js-delete-construction-project-inspection",
        });
      }
      if (row.viewable) {
        options.push({
          name: "Download Inspection Report",
          iconClass: "glyphicon-download-alt",
          linkClass: "download-inspection",
        });
      }
    } else if (row.idBmp) {
      if (row.noActions) return options;
      if (row.readOnly || readOnlyTopLevel) {
        if (row.assessmentType !== "historical") {
          if (row.hasReport && row.assetType === "bmp") {
            options.push({
              name: "Download Assessment Report",
              iconClass: "glyphicon-download-alt",
              linkClass: "download-bmp-report",
            });
          } else if (
            row.hasReport &&
            (row.assetType === "routineMaintenance" || row.assetType === "routineRepair")
          ) {
            options.push({
              name: "Download Inspection Report",
              iconClass: "glyphicon-download-alt",
              linkClass: "download-report",
            });
          } else {
            // @TODO: add this back after fixing https://2ndnature.atlassian.net/browse/SLG-2064
            // options.push({
            //   name: "View",
            //   iconClass: "glyphicon-eye-open",
            //   linkClass: "js-view-bmp-assessment",
            // });
          }
        }
      } else {
        if (row.assessmentType !== "historical") {
          // @TODO: add this back after fixing https://2ndnature.atlassian.net/browse/SLG-2064
          // options.push({
          //   name: "Edit",
          //   iconClass: "glyphicon-pencil",
          //   linkClass: "js-edit-bmp-assessment",
          // });
        }
        if (row.hasReport && row.assetType === "bmp") {
          options.push({
            name: "Download Assessment Report",
            iconClass: "glyphicon-download-alt",
            linkClass: "download-bmp-report",
          });
        } else if (
          row.hasReport &&
          (row.assetType === "routineMaintenance" || row.assetType === "routineRepair")
        ) {
          options.push({
            name: "Download Inspection Report",
            iconClass: "glyphicon-download-alt",
            linkClass: "download-report",
          });
        }
        // @TODO: add this back after fixing https://2ndnature.atlassian.net/browse/SLG-2064
        // options.push({
        //   name: "Delete",
        //   iconClass: "glyphicon-trash",
        //   linkClass: "js-delete-bmp-assessment",
        // });
      }
    }

    return options;
  };

  var getUserDropdownOptions = function (id, resend) {
    let items = [
      {
        name: "Edit Permissions",
        primary: true,
        iconClass: "glyphicon-pencil",
        linkClass: "edit-permissions",
        href: "#",
      },
      {
        name: "Remove",
        iconClass: "glyphicon-trash",
        linkClass: "remove-user",
        href: "#",
      },
    ];

    if (resend) {
      items = items.concat([
        {
          name: "Resend Invite",
          iconClass: "mail-send",
          linkClass: "resend-invite",
          href: "#",
        },
      ]);
    }
    return items;
  };

  const getDisplayFieldSetting = function (objectType) {
    return ToolSettings.getSetting("group")["settings"]["displaySettings"][objectType];
  };

  const isPhaseOrLater = function (currentPhase, targetPhase) {
    return SharedDataFunctions.isPhaseOrLater(currentPhase, targetPhase);
  };

  const getDeviceSize = function () {
    const minWidthDesktop = 992;
    const minWidthTablet = 720;
    const screenWidth = window.screen.availWidth;
    if (screenWidth > minWidthDesktop) {
      return "desktop";
    } else if (screenWidth > minWidthTablet) {
      return "tablet";
    } else {
      return "mobile";
    }
  };

  const featureFlagEnabled = function (key) {
    return FeatureFlag.enabled(key);
  };

  var sortActionsByName = function (dataArray, orderArray) {
    dataArray.sort((a, b) => {
      return orderArray.indexOf(a.name) - orderArray.indexOf(b.name);
    });
  };

  var getDisplayFallback = function () {
    return NunjucksFilters.displayFallback;
  };

  var getAssetDisplayName = function (assetName) {
    const displayName = ToolSettings.getSetting("constants", "assetDisplayNames", assetName);

    if (!displayName) {
      throw new Error(`No asset display not found for ${assetName}`);
    }

    return displayName;
  };

  return {
    setupFunctions,
    getCatchBasinDropdownOptions,
    getManHoleDropdownOptions,
    getCleanoutDropdownOptions,
    getOutfallDropdownOptions,
    getScenarioDropdownOptions,
    getTrashFcsDropdownOptions,
    _getConstructionComplianceActionDropdownOptions,
    getStructuralBmpActionDropdownOptions,
    getConstructionProjectDeliveryActionDropdownOptions,
    getActivityActionDropdownOptions,
    getCulvertDropdownOptions,
    getOpenChannelActionDropdownOptions,
    getFacilityActionDropdownOptions,
    getDisplayFieldSetting,
    getDeviceSize,
    featureFlagEnabled,
  };
};

module.exports = NunjucksFunctions();

const FeatureFlag = require("../settings/featureFlag");
const SharedDataFunctions = require("./sharedDataFunctions");
const ToolSettings = require("../settings/toolSettings");
const Tree = require("../tree");
const Session = require("../login/session");
const NunjucksFilters = require("./nunjucksFilters");
