"use strict";

function DataImportConfig() {
  const _templateUrlByLayerName = {
    outfalls:
      "https://docs.google.com/spreadsheets/d/1Qwm0pEBDvbl5ECGpeifghP8MF4mC_-Ag02B7Rk7q2Xo/edit?usp=sharing",
    sbmps:
      "https://docs.google.com/spreadsheets/d/1rsWgWkzLfifH9jyw7tPW_NQwNE7TaYiEBgXw-rr27SU/edit#gid=2009301310",
    "catch-basins":
      "https://docs.google.com/spreadsheets/d/1Q62FcePGppH3S-KvnJTZUOnehY7IuqRDwNmhSeWVCTc/edit?usp=sharing",
    "bmp-observations":
      "https://docs.google.com/spreadsheets/d/1IC2bYTextLmVTpLdBSBPsLPgUZqvSPFGjTwkivDxzMI/edit?usp=sharing",
    projects:
      "https://docs.google.com/spreadsheets/d/1RdeBr16JRwzORVnKkprPh2WD5Y4EGw82XDoYSXPmraM/edit?usp=sharing",
    "project-contacts":
      "https://docs.google.com/spreadsheets/d/1tmsKZfp1U_hnDyyGv6GFn8VV0JsDU_ZlK6QqGwnKtRo/edit?usp=sharing",
    fcss: "https://docs.google.com/spreadsheets/d/1mwfIFYJ4BGsdzpBFKyhj2ZyEvH-w12CsdgvUPSlcZzQ/edit?usp=sharing",
    facilities:
      "https://docs.google.com/spreadsheets/d/1G2RXmneB_a4N1TicEyA1Hxk0V3CvTIXq1pJDG_edTyo/edit?usp=sharing",
    catchBasins:
      "https://docs.google.com/spreadsheets/d/1RrbQ-FyXX6C0VA9CCZ5yTvkp_6Z2pvCVj2lIu3aEf3Y/edit#gid=989174310",
    manholes:
      "https://docs.google.com/spreadsheets/d/1nWOEqpJbypoJ7XLL9Igqlw5HC63NRA27u4OKRpKIgVk/edit?usp=sharing",
  };

  function getTemplateUrl(layerName) {
    return _templateUrlByLayerName[layerName];
  }

  const importRowLimit = 501;

  const validationRegex = {
    drainage: /.*(.+?\/)(rest\/services\/)(.+?\/)(FeatureServer\/)(\d+).*/gim,
  };

  const _agolErrors = {
    wrongUrl: {
      title: "Error",
      message: "Please provide a valid ArcGis Online Service URL for the layer you wish to upload.",
    },
    missingToken: {
      title: "Permissions",
      message:
        "We are unable to query AGOL servers for data. Please provide a valid access token or allow access to the map services.",
    },
    badUrl: {
      title: "Error",
      message: "The URL you have provided does not link to a valid service URL.",
    },
    notImplemented: {
      title: "Warning",
      message: "This feature has not yet been implemented (The URL is correct though)",
    },
  };

  const modalBody = {
    drainage: "Please provide the service URL to the drainage layer in ArcGIS Online.",
    zones: "Please provide the service URL to the maintenance zones layer in ArcGIS Online.",
    "project-area": "Please provide the service URL to the project area layer in ArcGIS Online.",
    "open-channels": "Please provide the service URL to the lift station layer in ArcGIS Online.",
    culverts: "Please provide the service URL to the lift station layer in ArcGIS Online.",
    cleanouts: "Please provide the service URL to the lift station layer in ArcGIS Online.",
    pipes: "Please provide the service URL to the lift station layer in ArcGIS Online.",
    "lift-stations": "Please provide the service URL to the lift station layer in ArcGIS Online.",
    manholes: "Please provide the service URL to the lift station layer in ArcGIS Online.",
  };

  const errorMessages = {
    drainage: _agolErrors,
    zones: _agolErrors,
    "project-area": _agolErrors,
    "open-channels": _agolErrors,
    culverts: _agolErrors,
    cleanouts: _agolErrors,
    pipes: _agolErrors,
    "lift-stations": _agolErrors,
    manholes: _agolErrors,
  };

  const layerOptions = [
    {
      value: "outfalls",
      name: "Outfalls",
      enabled: true,
      mapLayers: ["outfalls", "outfallsSimple", "muniOutfalls"],
      type: "csv",
      exportEnabled: true,
    },
    {
      value: "sbmps",
      name: "Structural BMPs",
      enabled: true,
      mapLayers: ["bmps"], //"fullDrainageBmp"
      type: "csv",
      exportEnabled: true,
    },
    {
      value: "catchBasins",
      name: "Catch Basins",
      enabled: true,
      mapLayers: ["muniCatchBasin"],
      type: "csv",
      exportEnabled: true,
    },
    {
      value: "projects",
      name: "Construction and PCR Projects",
      enabled: true,
      mapLayers: [
        "constructionProjectG2",
        "constructionProjectArea",
        "lidProject",
        "lidProjectArea",
      ],
      type: "csv",
      exportEnabled: true,
    },
    {
      value: "facilities",
      name: "Industrial and Commercial Facilities",
      enabled: true,
      mapLayers: ["indcomFacilities"],
      type: "csv",
      exportEnabled: true,
    },
  ];

  var getLayerOptions = function () {
    const optionsCopy = [...layerOptions];
    optionsCopy.push(
      {
        value: "bmp-observations",
        name: "Historical BMP Assessments",
        enabled: true,
        mapLayers: ["bmps"],
        type: "csv",
        exportEnabled: false,
      },
      {
        value: "project-contacts",
        name: "Project Contacts",
        enabled: true,
        mapLayers: [],
        type: "csv",
        exportEnabled: false,
      },
      {
        value: "drainage",
        name: "Structural BMP Drainage Areas",
        enabled: true,
        mapLayers: [], //"bmps", "fullDrainageBmp"
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
      {
        value: "project-area",
        name: "Project Areas",
        enabled: true,
        mapLayers: ["lidProjectArea", "constructionProjectArea"],
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
      {
        value: "zones",
        name: "Maintenance Zones",
        enabled: true,
        mapLayers: [], // TODO Maintenance zones,
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
      {
        value: "manholes",
        name: "Manholes",
        enabled: true,
        mapLayers: ["muniManhole"],
        type: FeatureFlag.enabled("bulk-import-manhole") ? "csv" : "url",
        handler: UrlImport.queryArcGisUrl,
        exportEnabled: FeatureFlag.enabled("bulk-import-manhole"),
      },
      {
        value: "lift-stations",
        name: "Lift Stations",
        enabled: true,
        mapLayers: ["muniLiftStations"],
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
      {
        value: "pipes",
        name: "Pipes",
        enabled: true,
        mapLayers: ["muniPipes"],
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
      {
        value: "cleanouts",
        name: "Cleanouts",
        enabled: true,
        mapLayers: ["muniCleanout"],
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
      {
        value: "culverts",
        name: "Culverts",
        enabled: true,
        mapLayers: ["muniCulvert"],
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
      {
        value: "open-channels",
        name: "Open Channels",
        enabled: true,
        mapLayers: ["muniOpenChannel"],
        type: "url",
        handler: UrlImport.queryArcGisUrl,
      },
    );

    return optionsCopy;
  };

  return {
    importRowLimit,
    getTemplateUrl,
    validationRegex,
    modalBody,
    errorMessages,
    getLayerOptions,
  };
}

module.exports = DataImportConfig();

const UrlImport = require("../dataImport/urlImport");
const FeatureFlag = require("../settings/featureFlag");
