"use strict";

const AssetManagementSummaryTableController = function () {
  var inited = false;

  var init = function () {
    if (inited) {
      return;
    }

    Tree.select("assetManagementRadioMode").on("update", function (e) {
      renderSummaryTable();
    });
    rerenderTableOnSpatialFilter();

    renderSummaryTable();

    inited = true;
  };

  var rerenderTableOnSpatialFilter = function () {
    Tree.select("filters").on("update", async function (e) {
      await loadingScreen.waitForResult(
        ".summary-table-container > div",
        getAndSetAssetManagementAttributionPercentage({ loadingScreen: false }),
      );
      renderSummaryTable();
    });
  };

  var renderSummaryTable = function () {
    const mode = Tree.get("assetManagementRadioMode");
    const layers = Tree.get("layers");
    const apiArray = Tree.get("assetManagementApiDataSummaryTable");
    const assetsArray = AssetManagementSummaryTableDataCache.getLayersArray(layers);
    const unenabledAssetCount =
      AssetManagementSummaryTableDataCache.checkForNoLayerEnabled(assetsArray);
    const props = AssetManagementSummaryTableDataCache.getTableSummaryDataArray(
      assetsArray,
      apiArray,
    );

    let template;

    if (mode === "pof") {
      template = "modals/assetManagement/assetManagementSummaryTablePof.njk";
    } else if (mode === "cof") {
      template = "modals/assetManagement/assetManagementSummaryTableCof.njk";
    } else if (mode === "criticality") {
      template = "modals/assetManagement/assetManagementSummaryTableCriticality.njk";
    } else {
      template = "modals/assetManagement/assetManagementSummaryTableAttribution.njk";
    }

    MapSummaryTable.renderSummaryTableContent(template, {
      props,
      noLayerOn: unenabledAssetCount > 8,
      title: getHeaderTitle(mode),
    });
  };

  var getAndSetAssetManagementAttributionPercentage = async function ({
    loadingScreen = true,
  } = {}) {
    const filters = Actions.getFiltersByMapId("main");

    const data = await ApiCalls.getAttributionPercentage(filters, { loadingScreen });
    if (!Tree.get("assetManagementApiData")) {
      Tree.set(["assetManagementApiData"], data);
    }

    Tree.set(["assetManagementApiDataSummaryTable"], data);
  };

  var getHeaderTitle = function (mode) {
    if (mode === "pof") {
      return "Probability of Failure";
    } else if (mode === "cof") {
      return "Consequence of Failure";
    } else if (mode === "criticality") {
      return "Criticality";
    } else {
      return "Attribution Status";
    }
  };

  return {
    init,
    getAndSetAssetManagementAttributionPercentage,
    renderSummaryTable,
  };
};

module.exports = AssetManagementSummaryTableController();
const Tree = require("../tree");
const AssetManagementSummaryTableDataCache = require("../assetManagement/assetManagementSummaryTableDataCache");
const Actions = require("../actions");
const ApiCalls = require("../apiCalls");
const MapSummaryTable = require("../general/mapSummaryTable");
const loadingScreen = require("../general/loadingScreen");
