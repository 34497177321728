"use strict";

function SelectPage() {
  let $container;
  let selectedTable;
  let selectLazyList;
  let sortedSelectDataArray;
  let selectTableSort;
  let smartFilters;

  async function render($newContainer) {
    const inited = !!$container;
    $container = $newContainer;

    if (inited) {
      _deselectAll();
    } else {
      sortedSelectDataArray = [...ToDoPrioritizeController.getDataById().values()];
      await _loadSmartFilters();
      _loadSelectTable();
      _loadListeners();
    }

    ToDoPrioritizeController.recalculateStyles();
  }

  function _loadSelectTable() {
    const $scrollContainer = $container.find(".list-container");

    selectTableSort = new TableSort($scrollContainer, {
      dueDate: DateTime.dbDateStringComparator,
      bmpName: SortUtilities.alphaSorter,
    });
    selectTableSort.setSorting("bmpName");
    selectTableSort.sort(sortedSelectDataArray);

    selectLazyList = new LazyList({
      rowContainer: $scrollContainer.find("tbody")[0],
      scrollContainer: $scrollContainer[0],
      getRowCallback: _getSelectRowHtmlByIndex,
      totalRows: sortedSelectDataArray.length,
    });
  }

  async function _loadSmartFilters() {
    const filterModule = DataViewFunctions.getCurrentDataViewProperty("filterModule");
    smartFilters = new SmartFilters(filterModule.getFilterConfigs(), {
      defaultFilters: Filters.getDefaultFilters(),
      onSetDefault: _resetFilters,
      treePath: ToDoPrioritizeController.getFormKey(),
      afterFilter: _afterFilter,
    });

    await _renderFilters();
    smartFilters.init($container.find(".display-filters"));
  }

  function _loadListeners() {
    selectedTable = new LazySelectTable(
      ToDoPrioritizeController.getDataById(),
      $container.find(".list-container"),
      {
        onSelect: _renderSelectCount,
      },
    );

    $container.on("click", ".js-add", _onAddSelection);
    $container.on("click", ".js-remove", _onRemoveSelection);
    $container.on("click", ".sortable", _onSelectTrSort);
    $container.on("2N:FormInput", `[name="searchString"]`, Misc.debounceDelay(_onSearch, 500));
  }

  function _getSelectRowHtmlByIndex(rowIndex) {
    return nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/selectionTableRow.njk", {
      datum: sortedSelectDataArray[rowIndex],
    });
  }

  function getStep() {
    return {
      title: "Selection",
      descriptionHtml:
        "<p>To create <strong>Routine Inspection Tasks</strong>, filter assets using Selection Criteria, select assets in the Asset List, Set Due Date for associated tasks, then click Add Tasks.</p>",
      contentHtml: nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/selection.njk", {
        selectedCount: 0,
        selectedToDoCount: 0,
      }),
      render,
    };
  }

  function destroy() {
    $container = null;
    sortedSelectDataArray = null;
    selectTableSort = null;
    selectedTable = null;
    selectLazyList?.destroy();
    selectLazyList = null;
    smartFilters?.destroy();
    smartFilters = null;
  }

  function _deselectAll() {
    selectedTable.toggleAllRows(false);
    _renderSelectCount();
    _rerenderSelect();
  }

  function _rerenderSelect() {
    selectLazyList.updateData(_getSelectRowHtmlByIndex, sortedSelectDataArray.length);
  }

  function _renderSelectCount() {
    const selectedIds = _getSelectedIds();
    const selectedToDoIds = _getSelectedToDoIds(selectedIds);
    const $footer = $container.find(".footer");

    const html = nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/selectionFooter.njk", {
      selectedCount: selectedIds.length,
      selectedToDoCount: selectedToDoIds.length,
      dateIso: _getIsoDueDate(),
    });
    $footer.html(html);

    Form.initializeDatePickers($footer, ToDoPrioritizeController.getFormKey());
  }

  function _renderTableCount(tableCount) {
    const html = nunjucks.render(
      "multiStepForm/muniCatchBasinToDoPrioritize/selectionAssetListHeader.njk",
      {
        tableCount,
      },
    );
    $container.find(".js-asset-header").html(html);
  }

  function _onAddSelection() {
    const selectedIds = _getSelectedIds();
    const newToDoIds = selectedIds.filter(
      (id) => !ToDoPrioritizeController.getToDoDataById().has(id),
    );
    const existingCount = selectedIds.length - newToDoIds.length;

    if (existingCount > 0) {
      const html = nunjucks.render("multiStepForm/muniCatchBasinToDoPrioritize/addWarning.njk", {
        existingCount: existingCount,
      });
      MessageModal.showThreeButtonConfirmWarningModal(
        null,
        () => _addSelection(selectedIds),
        () => _addSelection(newToDoIds),
        "Cancel",
        "Overwrite",
        "Don’t Overwrite",
        html,
      );
    } else {
      _addSelection(selectedIds);
    }
  }

  function _addSelection(selectedIds) {
    const dueDate = _getIsoDueDate();
    ToDoPrioritizeController.setDue(selectedIds, true, dueDate);
    _deselectAll();
  }
  function _getIsoDueDate() {
    let date = Form.getFormDataAtPath(ToDoPrioritizeController.getFormKey(), ["dueDate"]) ?? null;

    if (!date) {
      return null;
    }

    date = DateTime.getLocalAtGroupTimeZone(date);
    return DateTime.getIsoString(date);
  }

  async function _onRemoveSelection() {
    const selectedToDoIds = _getSelectedToDoIds(_getSelectedIds());

    const removed = await ToDoPrioritizeController.promptRemoveSelection(selectedToDoIds);

    if (removed) {
      _deselectAll();
    }
  }

  function _getSelectedIds() {
    return sortedSelectDataArray.filter((datum) => datum.selected).map((datum) => datum.id);
  }

  function _getSelectedToDoIds(selectedIdArray) {
    const toDoDataById = ToDoPrioritizeController.getToDoDataById();
    return selectedIdArray.filter((id) => toDoDataById.has(id));
  }

  function _onSelectTrSort(e) {
    const $tr = $(e.currentTarget);
    const sortKey = $tr.data("sortKey");

    selectTableSort.setSorting(sortKey);
    selectTableSort.updateHeaders();
    selectTableSort.sort(sortedSelectDataArray);
    _rerenderSelect();
  }

  async function _afterFilter(filters) {
    const filterModule = DataViewFunctions.getCurrentDataViewProperty("filterModule");
    sortedSelectDataArray = await filterModule.filterResourceDataHandler(
      ToDoPrioritizeController.getDataById().values().toArray(),
      filters,
      true,
      smartFilters,
    );

    selectTableSort.sort(sortedSelectDataArray);
    selectedTable.setData(sortedSelectDataArray);
    _renderTableCount(sortedSelectDataArray.length);
    _deselectAll();
  }

  async function _renderFilters() {
    const html = await Filters.getFilterHtml(
      Tree.get("dataView"),
      ToDoPrioritizeController.getFormKey(),
      {
        searchPlaceholder: DataViewFunctions.getCurrentDataViewProperty("searchPlaceholder"),
      },
    );
    $container.find(".js-filters").html(html);
  }

  async function _resetFilters() {
    await _renderFilters();
    smartFilters.init($container.find(".display-filters"));
  }

  function _onSearch(e, value) {
    Tree.set([ToDoPrioritizeController.getFormKey(), "searchString"], value);
  }

  return {
    getStep,
    render,
    destroy,
  };
}

module.exports = SelectPage();

const LazySelectTable = require("../../general/lazySelectTable");
const Form = require("../../general/form");
const MessageModal = require("../../modals/messageModal");
const LazyList = require("../../general/lazyList");
const TableSort = require("../../general/tableSort");
const SortUtilities = require("../../general/sortUtilities");
const DateTime = require("../../dateTime");
const SmartFilters = require("../../mapFunctions/smartFilters");
const Filters = require("../../mapFunctions/filters");
const DataViewFunctions = require("../../dataViewFunctions");
const Tree = require("../../tree");
const Misc = require("../../misc");
const ToDoPrioritizeController = require("./toDoPrioritizeController");
