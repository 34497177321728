"use strict";

const PropertiesController = function () {
  const render = async function (view = "list") {
    const options = {
      dataView: "properties",
      layer: {
        g2: true,
        name: "allProperties",
        list: {
          defaultSortKey: "rank",
          preparedDataFunction: _getPreparedData,
          template: "esg/properties/propertiesList.njk",
          afterRenderFunction: _afterListRenderFunction,
          listeners: [
            {
              target: ".add-sbmp-button",
              callback: function (event) {
                const propId = $(event.target)
                  .parents(".action-dropdown")
                  .find(".add-sbmp-button")
                  .attr("data-id");
                invokeCostsModal(propId);
              },
            },
            {
              target: ".edit-property-ranking",
              callback: showPropertyRankingInfographicModal,
            },
            {
              target: ".create-property",
              callback: function () {
                PropertyInventoryModalController.showPropertyInventoryModal({});
              },
            },
            {
              target: ".edit-property-button",
              callback: async function (e) {
                const propId = $(e.currentTarget)
                  .parents(".action-dropdown")
                  .find(".edit-property-button")
                  .attr("data-id");
                const data = await EsgApiCalls.getProperty(propId, null, true);
                PropertyInventoryModalController.showPropertyInventoryModal(data);
              },
            },
            {
              target: ".delete-property-button",
              callback: async function (e) {
                const propId = $(e.currentTarget)
                  .parents(".action-dropdown")
                  .find(".delete-property-button")
                  .attr("data-id");
                const layerData =
                  PageController.getLayerData()?.find((property) => property.id === propId) ?? null;
                const data = await EsgApiCalls.getProperty(layerData.id, null, true);
                PropertyFactSheetController.showDeletePropertyModal(data);
              },
            },
          ],
        },
        map: { baseMap: "Gray" },
      },
      navToggle: {
        view: view.length === 0 ? "list" : view,
      },
      breadcrumbs: {
        previousPage: {
          page: "Portfolio",
          route: "/portfolio",
        },
        currentPage: "Properties",
      },
      constants: [
        "regions",
        "states",
        "huc6",
        "huc8",
        "tenants",
        "acquisitionPortfolios",
        "funds",
        "propertyTypes",
        "ejCommunity",
        "retrofitOpportunities",
        "siteComplexity",
        "bmpRelationship",
        "compensationStatus",
        "allStates",
        "ownershipStatus",
        "districts",
        "propertyTypeUnitConsumptiveUse",
        "rankCategories",
      ],
      sideBar: {
        itemCount: true,
        isFlatList: false,
        dataFunction: () => {},
        onRowClick: () => {},
        filtersTemplate: "esg/properties/propertyFilters.njk",
      },
      afterRenderFunction: afterRenderFunction,
    };
    await PageController.render(
      "all-properties",
      "esg/properties/properties.njk",
      () => {
        return {
          canCreatePlan: UserPermissions.getPermission("can_create_plan"),
        };
      },
      options,
    );
  };

  const afterRenderFunction = function () {
    FilterSummary.enable({ esg: true });
    _handleMessages();
  };

  const _handleMessages = function () {
    const groupId = Tree.get("activeGroup", "groupId");
    const channel = `properties.${groupId}`;

    Messages.subscribe({
      channel,
      event: "delete",
      callback: async (data) => {
        await _rerankExistingData(data.rank, null, data.id);
        await AllPropertiesGeoServerLayer.deleteRow(data.id);

        LoadingScreen.hide();
      },
    });
    Messages.subscribe({
      channel,
      event: "update",
      callback: async (data) => {
        await _rerankExistingData(data.existingRank, data.property.rank, data.property.id);
        await AllPropertiesGeoServerLayer.updateRow(data.property, data.existingSlug);
      },
    });
    Messages.subscribe({
      channel,
      event: "new",
      callback: async (data) => {
        await _rerankExistingData(null, data.property.rank, null);
        await AllPropertiesGeoServerLayer.addRow(data.property);
      },
    });

    PageController.beforeNextRender(() => Messages.unsubscribe(channel));
  };

  const _rerankExistingData = async function (oldRank, newRank, id) {
    await AllPropertiesGeoServerLayer.replaceAllStoredData(
      _updateRanks(await AllPropertiesGeoServerLayer.getAll(), oldRank, newRank, id),
    );
  };

  const _updateRanks = function (data, oldRank, newRank, id) {
    if (!oldRank && !newRank) return data;
    return data.map((existingProperty) => {
      const property = { ...existingProperty };

      if (property.id === id) return property;

      if (oldRank && !newRank) {
        if (property.rank >= oldRank) {
          property.rank--;
        }
      } else if (newRank && !oldRank) {
        if (property.rank >= newRank) {
          property.rank++;
        }
      } else if (newRank > oldRank) {
        if (property.rank > oldRank && property.rank <= newRank) {
          property.rank--;
        }
      } else if (newRank < oldRank) {
        if (property.rank < oldRank && property.rank >= newRank) {
          property.rank++;
        }
      }

      return property;
    });
  };

  const _afterListRenderFunction = function (rowsAndOptions) {
    _renderSummaryBar(rowsAndOptions);
    Tooltip.setTooltip({
      selector: ".benefits-badge.mini.failed",
      text: "Property analytics could not be completed. The Rainsteward team is investigating the issue.",
      width: 320,
    });
  };

  const _renderSummaryBar = function (rowsAndOptions) {
    const $summaryBar = $(".summary-bar");
    const html = nunjucks.render("esg/properties/propertiesListSummaryBar.njk", rowsAndOptions);
    $summaryBar.html(html);
  };

  const _getPreparedData = function (properties) {
    _sortBy(properties);
    const canCreateBmp = UserPermissions.getPermission("can_create_bmps");

    for (const property of properties) {
      property.canCreateBmps = canCreateBmp;
      property.hasBoundary = !!property.geomMultiPoly;
      property.iconClass = "list-badge";
      property.dropClasses = DataRichness.getDropClasses(property.dataRichness);
      property.title = EsgSettings.propertyTitleDisplay(property);
    }

    return {
      rows: properties,
      options: {
        propertiesTotal: properties?.length,
        dropClassesAvg: DataRichness.getDropClasses(properties.averageValues("dataRichness")),
        totalCostTotal: properties.sumValues("costTotal"),
        costPerGallonAvg: properties?.sumValues("costTotal") / properties.sumValues("swMngd"),
        swMngdTotal: properties.sumValues("swMngd"),
        pollMngdTotal: properties.sumValues("pollMngd"),
        imperviousAcresTotal: properties.sumValues("imperviousAcres"),
        navTabs: { activeTab: NavTabs.getActiveTab() },
        canCreatePlan: UserPermissions.getPermission("can_create_plan"),
        title: EsgSettings.propertyTitleDisplay(),
      },
    };
  };

  const _sortBy = function (properties) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    var sortersDict = {
      propertyIdentifier: alphaSorter,
      propertyName: alphaSorter,
      rank: numberSorter,
      displayAddress: alphaSorter,
      dataRichness: numberSorter,
      costTotal: numberSorter,
      costPerGallon: numberSorter,
      swMngd: numberSorter,
      pollMngd: numberSorter,
      imperviousAcres: numberSorter,
    };
    DataList.sortWithDict(properties, sortersDict);
  };

  const showEditPropertyRankingModal = function (e) {
    PropertyRankingModal.render();
  };
  const showPropertyRankingInfographicModal = function (e) {
    const $modal = $("#property-ranking-infographic-modal");
    $modal.html(nunjucks.render("esg/modals/propertyRankingInfographicModal.njk", false));
    $modal.modal("show");
    $modal.off();
    $modal.find("[value='cancel']").on("click", () => $modal.modal("hide"));
    $modal.find("[value='ok']").on("click", () => {
      $modal.modal("hide");
      showEditPropertyRankingModal();
    });
  };
  const invokeCostsModal = async function (propertyIdentifier) {
    const propertyData = await EsgApiCalls.getProperty(propertyIdentifier, null, true);
    PropertiesCostModalController.showPropertyCostsModal(
      propertyData,
      "property-costs-add-bmps",
      null,
    );
  };

  return {
    render,
    _sortBy,
    _updateRanks,
  };
};

module.exports = PropertiesController();

const PageController = require("../pageController");
const DataList = require("../../mapFunctions/dataList");
const SortUtilities = require("../../general/sortUtilities");
const DataRichness = require("../dataRichness");
const NavTabs = require("../navTabs");
const PropertyRankingModal = require("../modals/propertyRankingModal");
const FilterSummary = require("../../filters/filterSummary");
const PropertiesCostModalController = require("../modals/propertyCostsModalController");
const EsgApiCalls = require("../esgApiCalls");
const UserPermissions = require("../../login/userPermissions");
const PropertyInventoryModalController = require("../modals/propertyInventoryModalController");
const Messages = require("../../messages");
const Tree = require("../../tree");
const AllPropertiesGeoServerLayer = require("../geoServer/allPropertiesGeoServerLayer");
const PropertyFactSheetController = require("../propertyFactSheetController");
const EsgSettings = require("../esgSettings");
const LoadingScreen = require("../../general/loadingScreen");
const Tooltip = require("../../tooltip");
