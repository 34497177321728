"use strict";

const PropertyFactSheetController = function () {
  let planId, planStatus;
  const render = async function (propertySlug, planSlug = null, planType = null) {
    planStatus = "suitable";
    planId = planSlug;
    const options = {
      dataView: "properties",
      layer: {
        name: "allProperties",
      },
      sideBar: {
        isFlatList: true,
        dataFunction: () => EsgApiCalls.getProperties(planSlug, planType),
        onRowClick: function (id) {
          if (planSlug) {
            UrlRoutes.navigate(`/impact-accounting/stormwater-plans/${planSlug}/${planType}/${id}`);
          } else {
            UrlRoutes.navigate(`/portfolio/properties/${id}`);
          }
        },
      },
      breadcrumbs: {
        previousPage: {
          page: planSlug ? "Plan" : "Properties",
          route: planSlug
            ? `/impact-accounting/stormwater-plans/${planSlug}/${planType}`
            : "/portfolio/properties",
        },
        currentPage: "Property Factsheet",
      },
      constants: [
        "retrofitOpportunities",
        "bmpRelationship",
        "siteComplexity",
        "compensationStatus",
        "allStates",
        "ownershipStatus",
        "propertyTypes",
        "propertyTypeUnitConsumptiveUse",
      ],
      preparedDataFunction: _getPreparedData,
      afterRenderFunction: _afterRenderFunction,
    };
    await PageController.render(
      "property",
      "esg/factSheet/propertyFactSheet.njk",
      () => EsgApiCalls.getProperty(propertySlug, planSlug),
      options,
    );
  };

  const _afterRenderFunction = function () {
    _createMap();
    _loadListeners();
    ToolTips.init("factSheet", PageController.getData());
    _handleMessages();
  };

  const _handleMessages = function () {
    const channel = `property.${PageController.getData("dbId")}`;

    Messages.subscribe({
      channel,
      event: "update",
      callback: (data) => {
        PageController.refresh({
          ...PageController.getData(),
          ...data.property,
        });
        history.replaceState({}, null, `/portfolio/properties/${data.property.id}`);
      },
    });

    Messages.subscribe({
      channel,
      event: "delete",
      callback: (data) => UrlRoutes.navigate("/portfolio/properties/"),
    });

    PageController.beforeNextRender(() => Messages.unsubscribe(channel));
  };

  const _getPreparedData = function (data) {
    planStatus = data.shelved ? "shelved" : data.selected ? "selected" : "suitable";

    return {
      ...data,
      ownershipStatus: PageController.getConstants("ownershipStatus")[data.ownershipStatus],
      propertyType: PageController.getConstants("propertyTypes")[data.propertyType],
      aspectDisplay: Misc.getAspectDisplay(data.aspect),
      acquiredYear: _getYearFromDate(data.acquiredDate),
      qbGallons: UnitConversion.acreFeetToGallons(data.qb),
      dropClasses: DataRichness.getDropClasses(data.dataRichness),
      focusTenant: _pickFocusTenant(data.tenants),
      planId: planId,
      planStatus: planStatus,
      hiddenFields: EsgSettings.hiddenFields(),
      title: EsgSettings.propertyTitleDisplay(data),
      hasBoundary: !!data.geom,
      compValueTotal: _getCompValueTotal(data),
    };
  };

  const _getCompValueTotal = function (data) {
    let total = 0;
    if (data.incentivePrograms) {
      data.incentivePrograms.forEach((program) => {
        if (["not-applicable", "identified"].includes(program.compStatus)) return;
        total += program.useManualCompensationValue
          ? Number(program.compValueManual)
          : Number(program.compValue);
      });
    }
    return total;
  };

  const _pickFocusTenant = function (tenants) {
    if (tenants && tenants.length) {
      let hasAmazon = false;
      tenants.forEach((obj) => {
        if (obj.tenant.indexOf("Amazon") > -1) {
          hasAmazon = true;
        }
      });
      if (hasAmazon) {
        return "Amazon";
      }
      return tenants[0].tenant;
    }
    return null;
  };

  const _getYearFromDate = function (dateString) {
    if (!dateString) return null;
    const dateObj = new Date(dateString);
    const theYear = dateObj.getFullYear();
    if (dateObj && !isNaN(theYear)) {
      return Number(theYear);
    }
  };

  const _getDataId = function (e) {
    return $(e.target).attr("data-id")
      ? $(e.target).attr("data-id")
      : $(e.target).parents("a").attr("data-id");
  };

  const _loadListeners = function () {
    $("#fact-sheet-next").off().on("click", SideBar.goToNext);
    $("#fact-sheet-previous").off().on("click", SideBar.goToPrevious);
    $("a.select-property")
      .off()
      .on("click", function (e) {
        _selectProperty(_getDataId(e), planId, planStatus);
      });
    $("a.action-shelve-property")
      .off()
      .on("click", function (e) {
        _shelveProperty(_getDataId(e), planId, planStatus);
      });
    $("a.unshelve-property")
      .off()
      .on("click", function (e) {
        _unshelveProperty(_getDataId(e), planId, planStatus);
      });
    $("a.edit-property-button")
      .off()
      .on("click", (e) => invokeInventoryModal());
    $("a.delete-property-button")
      .off()
      .on("click", (e) => showDeletePropertyModal(PageController.getData()));
    $("#edit-cost-estimates")
      .off()
      .on("click", () => invokeCostsModal("capital-cost"));
    $("#action-dropdown .action-desktop-analysis")
      .off()
      .on("click", () => invokeCostsModal("capital-cost"));
    $("#add-sbmp,.add-sbmp-button,.edit-sbmp-button")
      .off()
      .on("click", () => invokeCostsModal("property-costs-add-bmps"));
    $("#edit-cost-recovery")
      .off()
      .on("click", () => invokeCostsModal("cost-recovery"));
    $("#edit-units")
      .off()
      .on("click", () => invokeInventoryModal("units"));
    $("#edit-property-description")
      .off()
      .on("click", () => invokeInventoryModal("information"));
  };

  const showDeletePropertyModal = function (data) {
    const id = data.id;
    const propertyName = data.propertyName;
    const planCount = Object.keys(data?.plans)?.length ?? 0;
    MessageModal.showConfirmWarningModal(
      null,
      function () {
        LoadingScreen.show();
        _deleteProperty(id);
      },
      "Cancel",
      "Delete",
      nunjucks.render("esg/modals/deletePropertyModal.njk", {
        id,
        propertyName,
        planCount,
      }),
      null,
      "Warning",
      "esg",
    );
  };

  const _deleteProperty = async function (propertyIdentifier) {
    await EsgApiCalls.deleteProperty(propertyIdentifier);
  };

  const _selectProperty = async function (propertyIdentifier, planId, type) {
    const args = {
      planId: planId,
      propertyIds: [propertyIdentifier],
      type: type,
    };
    EsgApiCalls.selectProperties(args)
      .then(async function (response) {
        await PageController.refresh();
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  const _shelveProperty = async function (propertyIdentifier, planId, type) {
    const args = {
      id: planId,
      propertyIds: [propertyIdentifier],
      type: type,
    };
    EsgApiCalls.shelveProperties(args)
      .then(async function () {
        await PageController.refresh();
        return 0;
      })
      .catch((error) => {
        return error;
      });
  };
  const _unshelveProperty = async function (propertyIdentifier, planId, type) {
    const args = {
      id: planId,
      propertyIds: [propertyIdentifier],
      type: type,
    };
    EsgApiCalls.unshelveProperties(args)
      .then(async function () {
        await PageController.refresh();
        return 0;
      })
      .catch((error) => {
        return error;
      });
  };

  const invokeInventoryModal = async function (startingPage) {
    const data = PageController.getData();
    PropertyInventoryModalController.showPropertyInventoryModal(data, startingPage);
  };

  const invokeCostsModal = async function (startingPage) {
    const data = PageController.getData();
    if (planId) {
      data.planStatus = planStatus[0].toUpperCase() + planStatus.slice(1);
    }
    PropertyCostsModalController.showPropertyCostsModal(data, startingPage);
  };

  const _createMap = function () {
    const geom = PageController.getData("geom");
    MapFunctions.createStaticMapWithPolygon("factsheet-map", { geom });
  };

  return {
    render,
    invokeCostsModal,
    showDeletePropertyModal,
    _getDataId,
    _pickFocusTenant,
    _createMap,
    _shelveProperty,
    _unshelveProperty,
    _selectProperty,
    _getCompValueTotal,
  };
};

module.exports = PropertyFactSheetController();

const EsgApiCalls = require("../esg/esgApiCalls");
const PageController = require("./pageController");
const UrlRoutes = require("../routes/urlRoutes");
const SideBar = require("../esg/sideBar");
const MapFunctions = require("../mapFunctions/mapFunctions");
const UnitConversion = require("../unitConversion");
const DataRichness = require("../esg/dataRichness");
const ToolTips = require("../esg/toolTips");
const PropertyCostsModalController = require("./modals/propertyCostsModalController");
const PropertyInventoryModalController = require("./modals/propertyInventoryModalController");
const Messages = require("../messages");
const MessageModal = require("../modals/messageModal");
const EsgSettings = require("./esgSettings");
const LoadingScreen = require("../general/loadingScreen");
const Misc = require("../misc");
