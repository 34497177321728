"use strict";

const IncidentInventorySetLocation = function () {
  const stringKey = "incident-set-location";
  const headerInformation = "Location";
  let $page;

  var loadSelectLocationListeners = function () {
    $page.on("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.on("input", ":input.form-control", changeSetLocationInput);
    $page.on("change", `[name="use-manual-apn"]`, onUseManualApnChange);
    Form.initializeAndLoadListeners($page, "incident-inventory", { isMultiPart: true });
  };

  var removeListeners = function () {
    $page.off("change", "[name=latitude], [name=longitude]", updateLatLngFromInput);
    $page.off("input", ":input.form-control", changeSetLocationInput);
    $page.off("change", `[name="use-manual-apn"]`, onUseManualApnChange);
  };

  var changeSetLocationInput = function () {
    const changedField = $(this).attr("name");
    if (changedField === "incident-name") {
      setIncidentId($(this).val());
    }
  };

  var setIncidentId = function (newName) {
    if (newName === IncidentInventoryModalController.getExistingData("incidentName")) {
      IncidentInventoryModalController.unsetFormDataField("incidentName");
      restoreButtonsIfHasAllRequiredInputs();
    } else if (newName === "") {
      InventoryModal.allowedSaveState(false);
      IncidentInventoryModalController.unsetFormDataField("incidentName");
      InventoryModal.disableSaveButton();
    } else {
      IncidentInventoryModalController.setFormDataField("incidentName", newName);
      restoreButtonsIfHasAllRequiredInputs();
    }
  };

  var onUseManualApnChange = function () {
    const showManualApnInput =
      IncidentInventoryModalController.getLatestIncidentInventoryData("useManualApn") === true;
    $page.find(`input[name="apn"]`).toggleClass("hidden", showManualApnInput);
    $page.find(`input[name="manual-apn"]`).toggleClass("hidden", !showManualApnInput);
  };

  var getStringKey = function () {
    return stringKey;
  };

  var getHeaderInformation = function () {
    return headerInformation;
  };

  var render = async function (options) {
    await renderPageHtml();
    ModalMap.removeMarkerLayer();
    showLocationSelectionMap(options);
    loadSelectLocationListeners();
    disableUnavailableElements();
  };
  var disableUnavailableElements = function () {
    const hasMarkerLocation =
      IncidentInventoryModalController.getLatestIncidentInventoryData("latitude");

    if (!Session.isSalinas() && !Session.isCdot()) {
      Misc.toggleDisabled($("#inventory-modal input[name='incident-name']"), !hasMarkerLocation);
    }
  };

  var checkCreateNewIncidentMarker = function () {
    const id = IncidentInventoryModalController.getLatestIncidentInventoryData("id");

    return !id;
  };

  var showLocationSelectionMap = function (options) {
    const configuration = {
      checkNewMarkerHandler: checkCreateNewIncidentMarker,
      newExistingMarkerHandler: IncidentInventoryModalController.loadExistingIncidentMarker,
      locationDataUpdateHandler: updateSetLocationFooter,
      badLocationUpdateHandler: resetLatLngToLastValidValue,
      createMarkerHandler: createIncidentMarker,
    };

    LocationSelectionMap.configureLocationModalMapHandlers(configuration);
    LocationSelectionMap.showLocationSelectionMap(options?.existingLocation);

    setLocationMapText();
  };

  var createIncidentMarker = function (latlng) {
    return ModalMap.getMarker(latlng);
  };

  var updateSetLocationFooter = async function (locationInfo, latlng) {
    updateLatLngFields(latlng);
    const promise = updateLocationInfo(locationInfo);

    const $incidentName = $page.find("input[name='incident-name']");
    if (IncidentInventoryModalController.getExistingData("incidentName")) {
      restoreButtonsIfHasAllRequiredInputs();
    } else {
      Misc.toggleDisabled($incidentName, false);
      $incidentName.focus();
    }

    return promise;
  };

  var getRegionName = function (RegionNum) {
    return RegionNum ? `Region ${RegionNum}` : "—";
  };

  var updateLocationInfo = function (locationInfo) {
    locationInfo = parseLocationInfo(locationInfo);

    IncidentInventoryModalController.setFormDataField("locationInfo", locationInfo);
    setInputField("maintenance-zone", locationInfo.maintenanceZone, true);
    setInputField("maintenanceSection", locationInfo.maintenanceSection, true);
    setInputField("apn", locationInfo.parcelApn, true);
    setInputField("catchment-id", locationInfo.catchmentName, true);
    setInputField("address", locationInfo.address, true);
    setInputField("city", locationInfo.groupCityName, true);
    setInputField("zip", locationInfo.zip, true);
    setInputField("state", locationInfo.state, true);
    setInputField("highwayAssignment", locationInfo.highway, true);
    setInputField("region", locationInfo.regionName, true);
    setInputField("patrol", locationInfo.patrol, true);

    return;
  };

  var parseLocationInfo = function (locationInfo) {
    const region = locationInfo?.region;

    return {
      regionName: getRegionName(region),
      highway: locationInfo.highway_name,
      catchmentGid: locationInfo.gid,
      catchmentName: locationInfo.catchid,
      receivingWaterName: locationInfo.drains_to_rw,
      landuseType: locationInfo.combo,
      groupName: locationInfo.gname,
      groupId: locationInfo.group_id,
      groupCityName: locationInfo.city,
      address: locationInfo.address,
      zip: locationInfo.zip,
      state: locationInfo.state,
      parcelApn: locationInfo.parcelid,
      maintenanceZone: locationInfo.maintenanceZone,
      maintenanceSection: locationInfo.maintenance_section,
      patrol: locationInfo.patrol,
    };
  };

  var updateLatLngFields = function (latlng) {
    const latInput = $page.find(`input[name="latitude"]`)[0];
    const longInput = $page.find(`input[name="longitude"]`)[0];

    if (latInput && longInput) {
      NumberInput.manuallySetInput(latInput, latlng.lat);
      NumberInput.manuallySetInput(longInput, latlng.lng);
    }

    IncidentInventoryModalController.setFormDataField("latitude", latlng.lat);
    IncidentInventoryModalController.setFormDataField("longitude", latlng.lng);
    restoreButtonsIfHasAllRequiredInputs();
  };

  var setInputField = function (field, value, updateForm) {
    $page.find(`input[name=${field}]`).val(value);
    if (updateForm && value !== undefined) {
      IncidentInventoryModalController.setFormDataField(field, value);
    }
  };

  var setLocationMapText = function () {
    if (ModalMap.modalMapHasLayer("singleMarkerLayer")) {
      ModalMap.showLocationMessage("Click map or drag marker to change location");
    } else {
      ModalMap.showLocationMessage("Click map to set location");
    }
  };

  var renderPageHtml = async function () {
    const isCdot = Session.isCdot();
    const isSalinas = Session.isSalinas();
    const renderData = InventoryModal.getAllData("incident-inventory");
    const html = nunjucks.render("modals/incidentInventory/incidentInventorySetLocation.njk", {
      ...renderData,
      locationInfo: parseLocationInfo(renderData.locationInfo ?? {}),
      isCdot,
      isSalinas,
    });

    IncidentInventoryModalController.renderPageContent(html);
    $page = $(".modal-dynamic-content");
  };

  var updateLatLngFromInput = function () {
    if ($page.find(`input[name="latitude"]`).val() && $page.find(`input[name="longitude"]`).val()) {
      const e = {
        latlng: {
          lat: $page.find(`input[name="latitude"]`).val(),
          lng: $page.find(`input[name="longitude"]`).val(),
        },
      };
      LocationSelectionMap.locationSelectionMapClick(e);

      restoreButtonsIfHasAllRequiredInputs();
    } else {
      InventoryModal.allowedSaveState(false);
      InventoryModal.disableNavigationButtons();
    }
  };

  var hasAllRequiredInputs = function () {
    const latitude = $page.find(`input[name="latitude"]`).val();
    const longitude = $page.find(`input[name="longitude"]`).val();
    const incidentName = $page.find(`input[name="incident-name"]`).val();

    return latitude && longitude && incidentName;
  };

  var restoreButtonsIfHasAllRequiredInputs = function () {
    if (hasAllRequiredInputs()) {
      InventoryModal.allowedSaveState(true);
      InventoryModal.restoreNavigationButtons();
    }
  };

  var resetLatLngToLastValidValue = function (latlng) {
    NumberInput.manuallySetInput($page.find(`input[name="latitude"]`)[0], latlng.lat);
    NumberInput.manuallySetInput($page.find(`input[name="longitude"]`)[0], latlng.lng);
  };

  var cleanUp = function () {
    removeListeners();
    LocationSelectionMap.hideLocationSelectionMap();
    $page.empty();
    return true;
  };

  var validateIncidentId = async function () {
    const alreadyUsedId = IncidentInventoryModalController.getExistingData("incidentName");
    const currentIdState =
      IncidentInventoryModalController.getLatestIncidentInventoryData("incidentName");
    if (alreadyUsedId === currentIdState) {
      return true;
    } else {
      const groupId = Tree.get("activeGroup", "groupId");
      const isUnique = await ApiCalls.checkUniqueIncidentName(groupId, currentIdState);
      if (!isUnique[0].unique) {
        MessageModal.showSimpleWarningModal(
          "The ID you entered already exists in our records! Please choose a different name or contact <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for further assistance.",
          true,
        );
      }
      return isUnique[0].unique;
    }
  };

  var validate = async function () {
    return validateIncidentId().catch((err) => {
      throw err;
    });
  };

  return {
    getStringKey,
    getHeaderInformation,
    setIncidentId,
    render,
    renderPageHtml,
    setInputField,
    cleanUp,
    validate,
    updateSetLocationFooter,
    loadSelectLocationListeners,
    resetLatLngToLastValidValue,
  };
};

module.exports = IncidentInventorySetLocation();

const Form = require("../../general/form");
const InventoryModal = require("../../general/inventoryModal");
const LocationSelectionMap = require("../../mapFunctions/locationSelectionMap");
const Misc = require("../../misc");
const ModalMap = require("../../mapFunctions/modalMap");
const NumberInput = require("../../general/numberInput");
const Tree = require("../../tree");
const IncidentInventoryModalController = require("./incidentInventoryModalController");
const ApiCalls = require("../../apiCalls");
const MessageModal = require("../../modals/messageModal");
const Session = require("../../login/session");
