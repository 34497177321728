"use strict";

const DataViewConfig = function () {
  const get = function () {
    // Tests fail if these are imported at the bottom
    const BmpFcsToDoController = require("../bmpfcs/bmpFcsToDoController");
    const IncidentLayer = require("../illicitDischarge/incidents/incidentLayer");
    const ResourceController = require("../offline/resourceController");
    const ConstructionFilters = require("../construction/constructionFilters");
    const IndcomFacilityFilters = require("../indcom/indcomFacilityFilters");
    const ScenarioFilter = require("../filters/scenarioFilter");
    const PageController = require("../esg/pageController");
    const LidToDoList = require("../lid/lidToDoList");
    const ConstructionProjectG2Filters = require("../constructionG2/constructionProjectG2Filters");
    const StreetSweepingLayer = require("../muni/streetSweepingLayer");
    const BmpFilter = require("../filters/bmpFilter");
    const VaFilter = require("../filters/vaFilter");
    const MuniCatchBasinFilters = require("../muni/muniCatchBasinFilters");
    const PostConstructionProjectG2Filters = require("../constructionG2/postConstructionProjectG2Filters");
    const MuniOutfallsFilters = require("../muni/outfalls/muniOutfallsFilters");
    const MuniBmpFilters = require("../muni/bmp/muniBmpFilters");
    const LidBmpFilters = require("../lid/lidBmpFilters");
    const StructuralBmpFilters = require("../bmpFcsG2/structuralBmpFilters");
    const MuniCleanoutFilters = require("../muni/muniCleanoutFilters");
    const MuniManholeFilters = require("../muni/muniManholeFilters");
    const MuniLiftStationsFilters = require("../muni/muniLiftStationsFilters");
    const MuniOpenChannelFilters = require("../muni/openChannel/muniOpenChannelFilters");
    const MuniPipesFilters = require("../muni/muniPipesFilters");
    const MuniCulvertFilters = require("../muni/culvert/muniCulvertFilters");
    const IncidentsFilters = require("../illicitDischarge/incidents/incidentsFilters");
    const OtherPropertiesLayer = require("../esg/properties/otherPropertiesLayer");
    const ActivityFilters = require("../peo/activityFilters");
    const AssetManagementFilters = require("../assetManagement/filters/assetManagementFilters");
    const Actions = require("../actions");

    const defaultStormDrainLayer = ToolSettings.getSetting("simplifiedStormdrainLayer")
      ? "stormdrainGeoServer"
      : "stormdrains";

    return {
      "muni-lift-stations": {
        isGisDataView: true,
        name: "Lift Stations",
        dataSortByTab: { data: ["muniLiftStationsId", "address"] },
        defaultLayers: ["muniLiftStations"],
        flatDataSort: "muniLiftStationsId",
        actionButton: {
          defaultHidden: true,
        },
        filterTemplate: "filters/muniLiftStationsFilters.njk",
        filterModule: MuniLiftStationsFilters,
        listActionButtonHide: true,
        hideMapListToggle: { data: false },
        searchPlaceholder: "Search by ID or Street Address",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "maintenanceZones"],
        defaultTab: "data",
        mapEnabledTabs: ["data"],
        listEnabledTabs: ["data"],
        defaultImportLayer: "lift-stations",
        setToMapView: true,
      },
      bmp: {
        isGisDataView: Tree.get("activeTab") === "data" ? true : false,
        name: "Structural BMPs",
        defaultLayers: Tree.get("activeTab") === "data" ? ["structuralBmps"] : ["bmps"],
        searchByPropertyEnabled: true,
        listEnabledTabs: ["todo", "data"],
        mapEnabledTabs: ["data", "todo"],
        topLevelDataSort: ["rw", "ud", "uc"],
        flatDataSort: "bmpName",
        dataSortByTab: { data: ["bmpName", "bmpTotal", "bmpImp", "bmpType", "bmpStatus"] },
        toDoSubjects: ["assessmentDue", "maintenanceNeeded", "inventoryIncomplete"],
        actionButton: {
          offline: false,
          buttonText: "Add New sBMP",
          dataAction: "add-new",
          permissionKey: "can_create_bmps",
        },
        searchPlaceholder: "Search ID, address, or facility name",
        hideMapListToggle: { data: false },
        defaultTab: "data",
        defaultImportLayer: "sbmps",
        reportingDataGroup: "stormwater group",
        exportsEnabled: ["excel", "shapefile"],
        defaultExportType: "excel",
        activeTabChangeCallback: BmpFcsToDoController.handleConditionPhaseToggleDisplay,
        filterTemplate: "filters/bmpFilters.njk",
        filterModule: StructuralBmpFilters,
      },
      va: {
        name: "Trash Assessment",
        defaultLayers: ["trashLines"],
        defaultDisabledLayers: [
          "landuse",
          "fullDrainageFcs",
          "fullDrainageBmp",
          "projects",
          "bmpFcs",
          "bmps",
          "fcs",
        ],
        setToMapView: true,
        flatDataSort: "uc",
        actionButton: {
          offline: false,
          buttonText: "Add New FCS",
          dataAction: "add-new",
          permissionKey: "can_create_bmps",
        },
        topLevelDataSort: ["rw", "ud", "uc"],
        searchPlaceholder: "Search catchments",
        filterBarHiddenButtons: ["item-count"],
        defaultTab: "data",
        listEnabledTabs: [],
        mapEnabledTabs: ["data"],
        reportingDataGroup: "trash group",
        hideMapListToggle: { data: true },
        filterTemplate: "filters/vaFilters.njk",
        filterModule: VaFilter,
        hasAssociatedG2Layer: true,
        useSimpleOutfallG2Layer: true,
      },
      fcs: {
        name: "Full Capture System",
        defaultLayers: ["fcs"],
        defaultDisabledLayers: [
          "bmps",
          "trashLines",
          "priority",
          "landuse",
          "fullDrainageFcs",
          "fullDrainageBmp",
          "projects",
        ],
        setToMapView: true,
        flatDataSort: "fcsId",
        searchByPropertyEnabled: true,
        listEnabledTabs: ["todo", "data"],
        mapEnabledTabs: ["data", "todo"],
        topLevelDataSort: ["rw", "uc", "ud"],
        dataSortByTab: { data: ["fcsId", "fcsTotal", "fcsPlu", "fcsType", "fcsStatus"] },
        toDoSubjects: ["assessmentDue", "maintenanceNeeded", "inventoryIncomplete"],
        actionButton: {
          offline: false,
          buttonText: "Add New FCS",
          dataAction: "add-new",
          permissionKey: "can_create_bmps",
        },
        searchPlaceholder: "Search ID, address, or facility name",
        defaultTab: "data",
        reportingDataGroup: "trash group",
        exportsEnabled: ["excel", "shapefile"],
        defaultExportType: "excel",
        hideMapListToggle: { data: false },
        filterTemplate: "filters/fcsFilters.njk",
        filterModule: BmpFilter,
        hasAssociatedG2Layer: true,
        useSimpleOutfallG2Layer: true,
      },
      "construction-project": {
        isGisDataView: Tree.get("activeTab") === "data",
        hasAssociatedG2Layer: true,
        name: "Compliance",
        enableFilterSummaryOnMapOrListToggle: true,
        offline: ResourceController.get("constructionProjectG2").getOfflineCount() > 0,
        defaultLayers: ["constructionProjectG2"],
        flatDataSort: "projectIdentifier",
        searchByPropertyEnabled: true,
        listEnabledTabs: ["data", "todo"],
        mapEnabledTabs: ["data"],
        topLevelDataSort: ["rw", "ud", "uc", "highways"],
        dataSortByTab: {
          data: ["projectIdentifier", "projectName", "projectAddressG2", "priority"],
        },
        hideMapListToggle: { todo: true },
        toDoSubjects: [
          "constructionInspectionDue",
          "constructionEnforcementUnresolved",
          "constructionFormalCommunication",
          "constructionIndividualEnforcementUnresolved",
          "constructionInstallationInspection",
          "constructionCompleteProject",
        ],
        actionButton: {
          offline: false,
          buttonText: "Add New Project",
          dataAction: "add-new",
          permissionKey: "can_create_projects",
        },
        searchPlaceholder: "Search project ID, name, or address",
        defaultTab: "data",
        defaultImportLayer: "projects",
        reportingDataGroup: "construction",
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
        filterModule: ConstructionProjectG2Filters,
        filterTemplate: "filters/constructionProjectFilters.njk",
      },
      "construction-project-delivery": {
        name: "Project Delivery",
        defaultLayers: ["constructionProject"],
        flatDataSort: "projectIdentifier",
        searchByPropertyEnabled: true,
        listEnabledTabs: ["data"],
        mapEnabledTabs: ["data"],
        hasAssociatedG2Layer: true,
        topLevelDataSort: ["rw", "ud", "uc"],
        enableFilterSummaryOnMapOrListToggle: true,
        dataSortByTab: {
          data: ["projectIdentifier", "projectName", "projectAddress", "status"],
        },
        actionButton: {
          offline: false,
          buttonText: "Add New Project",
          dataAction: "add-new",
          permissionKey: "can_create_projects",
        },
        searchPlaceholder: "Search project ID, name, or address",
        defaultTab: "data",
        defaultImportLayer: "projects",
        reportingDataGroup: "construction",
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
        filterTemplate: "filters/constructionProjectDeliveryFilters.njk",
        filterModule: ConstructionFilters,
        mapSummaryTableEnabledTabs: ["data"],
      },
      "lid-project": {
        name: "PCR Project",
        isGisDataView: Tree.get("activeTab") === "data",
        hasAssociatedG2Layer: true,
        defaultLayers:
          Tree.get("activeTab") === "data" ? ["postConstructionProjectG2"] : ["lidProject"],
        defaultDisabledLayers: ["lidProjectBmp", "runoffCondition"],
        flatDataSort: "lidProjectId",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "ud", "uc"],
        dataSortByTab: { data: ["lidProjectId", "lidProjectStatus", "lidProjectPerformanceG2"] },
        actionButton: {
          offline: false,
          buttonText: "Add New Project",
          dataAction: "add-new",
          permissionKey: "can_create_projects",
          defaultHidden: false,
        },
        showActionButtonsTabs: ["data", "todo"],
        setToMapView: true,
        searchPlaceholder: "Search project ID, name, or address",
        defaultTab: "data",
        listEnabledTabs: ["data", "todo", "insight"],
        mapEnabledTabs: ["data", "todo"],
        defaultImportLayer: "projects",
        reportingDataGroup: "lid",
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
        filterTemplate: "filters/lidProjectFilters.njk",
        filterModule: PostConstructionProjectG2Filters,
        toDoSubjects: [
          "lidFormalCommunications",
          "lidResponseNeeded",
          "lidProjectEnforcement",
          "lidMissingInformation",
        ],
        activeTabChangeCallback: LidToDoList.handleConditionPhaseToggleDisplay,
        limitedAccessDisabledDataViews: ["lid-runoff"],
      },
      "lid-bmp": {
        isGisDataView: true,
        name: "Structural BMPs",
        defaultLayers: ["lidBmp"],
        actionButton: {
          offline: false,
          buttonText: "Add New sBMP",
          dataAction: "add-new",
          defaultHidden: UserPermissions.getPermission("can_create_bmps") ? false : true,
          permissionKey: "can_create_bmps",
        },
        searchPlaceholder: "Search by ID or Street Address",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "maintenanceZones"],
        flatDataSort: "muniBmpId",
        dataSortByTab: { data: ["muniBmpId", "address"] },
        defaultTab: "data",
        mapEnabledTabs: ["todo", "data"],
        listEnabledTabs: ["todo", "data"],
        toDoSubjects: ["routineSiteVisitBmp", "routineRepairBmp"],
        defaultImportLayer: "sbmps",
        setToMapView: true,
        hideMapListToggle: { data: false },
        filterTemplate: "filters/lidBmpFilters.njk",
        filterModule: LidBmpFilters,
        offlineTodos: true, // @TODO: remove when all offline data views use G2
      },
      "lid-runoff": {
        name: "Runoff Condition",
        isGisDataView: true,
        defaultLayers: ["runoffCondition"],
        defaultDisabledLayers: ["lidProjectArea", "lidProject", "lidProjectBmp"],
        catchmentView: true,
        catchmentViewDefaultEnabledLayers: ["runoffCondition"],
        catchmentViewDefaultDataSort: "apn",
        topLevelDataSort: ["rw", "ud", "uc"],
        dataSortByTab: { data: ["apn"] },
        flatDataSort: "apn",
        searchByPropertyEnabled: true,
        actionButton: {
          offline: false,
          buttonText: "Add New Project",
          dataAction: "add-new",
          defaultHidden: true,
        },
        hideMapListToggle: { data: true },
        searchPlaceholder: "Search address or APN",
        filterBarDisabledButtons: [],
        filterBarHiddenButtons: ["search", "filter"],
        defaultTab: "data",
        listEnabledTabs: [],
        mapEnabledTabs: ["data"],
        defaultImportLayer: "projects",
        reportingDataGroup: "stormwater group",
      },
      "indcom-facilities": {
        isGisDataView: true,
        name: "Industrial/Commercial",
        defaultLayers: ["indcomFacilities"],
        searchByPropertyEnabled: true,
        enableFilterSummaryOnMapOrListToggle: true,
        actionButton: {
          offline: false,
          buttonText: "Add New Facility",
          dataAction: "add-new",
          permissionKey: "can_create_facility",
        },
        showActionButtonsTabs: ["data", "todo"],
        filterBarDisabledButtons: [],
        topLevelDataSort: ["rw", "ud", "uc"],
        flatDataSort: "indcomFacilitiesName",
        searchPlaceholder: "Search facility ID, name, or address",
        dataSortByTab: { data: ["indcomFacilitiesName", "facilityIdentifier"] },
        defaultTab: "data",
        listEnabledTabs: ["data", "todo", "insight"],
        mapEnabledTabs: ["data", "todo"],
        toDoSubjects: [
          "indcomFacilityInspection",
          "indcomFacilityFinding",
          "indcomFacilityEnforcement",
          "indcomFacilityInventoryIncomplete",
        ],
        defaultImportLayer: "facilities",
        reportingDataGroup: "indcom",
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
        hideMapListToggle: { insight: true },
        filterTemplate: "filters/facilityFilters.njk",
        filterModule: IndcomFacilityFilters,
        offline: ResourceController.get("indcomFacilities").getOfflineCount() > 0,
        offlineTodos: true, // @TODO: remove when all offline data views use G2
      },
      "dry-weather": {
        name: "Dry Weather",
        defaultDisabled: Session.isCdot(),
        defaultLayers: ["monitoringLocation"],
        actionButton: {
          offline: false,
          defaultHidden: true,
        },
        setToMapView: true,
        hideMapListToggle: { data: true },
        filterBarDisabledButtons: ["filter"],
        filterBarHiddenButtons: ["filter"],
        topLevelDataSort: ["rw", "ud", "uc"],
        dataSortByTab: { data: ["monitoringLocationId"] },
        flatDataSort: "monitoringLocationId",
        searchPlaceholder: "Search monitoring location name",
        searchByPropertyEnabled: true,
        defaultTab: "data",
        listEnabledTabs: [],
        hasAssociatedG2Layer: true,
        associatedG2Layer: "iddeManhole",
        mapEnabledTabs: ["data"],
        defaultImportLayer: "outfalls",
        reportingDataGroup: null,
      },
      outfalls: {
        name: "Outfalls",
        defaultLayers: ["outfalls"],
        defaultModalLayers: [defaultStormDrainLayer],
        actionButton: {
          offline: false,
          disabled: false,
          buttonText: "Add New Outfall",
          dataAction: "add-new",
          permissionKey: "can_create_outfall",
        },
        hideMapListToggle: { data: true },
        filterBarDisabledButtons: ["filter"],
        filterBarHiddenButtons: ["filter"],
        topLevelDataSort: ["rw", "ud", "uc", "maintenanceZones"],
        dataSortByTab: { data: ["outfallsId"] },
        flatDataSort: "outfallsId",
        searchPlaceholder: "Search outfall name",
        searchByPropertyEnabled: true,
        defaultTab: "data",
        mapEnabledTabs: ["data"],
        listEnabledTabs: [],
        defaultImportLayer: "outfalls",
        reportingDataGroup: "muni outfall",
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
        esriLayer: "catch-basin", // @TODO: Update for outfall esri layer
        hasAssociatedG2Layer: true,
      },
      "asset-management": {
        name: "asset Management",
        defaultLayers: Actions.useCachedLayers(["assetsPipeEsriDynamicMap"]),
        actionButton: {
          offline: false,
          defaultHidden: true,
        },
        flatDataSort: "assetManagementId",
        dataSortByTab: {
          data: FeatureFlag.enabled("alo-filters") ? ["assetManagementId"] : [],
        },
        filterBarHiddenButtons: FeatureFlag.enabled("alo-filters")
          ? ["item-count", "search"]
          : ["item-count", "filter", "search"],
        filterBarDisabledButtons: FeatureFlag.enabled("alo-filters")
          ? ["search"]
          : ["filter", "search"],
        topLevelDataSort: ["rw", "ud", "uc", "highways"],
        searchPlaceholder: "Search outfall name",
        searchByPropertyEnabled: true,
        defaultTab: "data",
        mapEnabledTabs: ["data"],
        listEnabledTabs: [],
        mapSummaryTableEnabledTabs: ["data"],
        widgetScreenButton: true,
        reportingDataGroup: "muni outfall",
        defaultExportType: "excel",
        esriKey: "assets",
        esriLayer: "pipe", // @TODO: Update for outfall esri layer
        filterTemplate: "filters/assetManagementFilters.njk",
        filterModule: AssetManagementFilters,
      },
      "muni-outfalls": {
        name: "Outfalls",
        defaultLayers: ["muniOutfalls"],
        defaultModalLayers: [defaultStormDrainLayer],
        setToMapView: true,
        actionButton: {
          offline: false,
          disabled: false,
          buttonText: "Add New Outfall",
          dataAction: "add-new",
          permissionKey: "can_create_outfall",
        },
        topLevelDataSort: ["rw", "ud", "uc", "maintenanceZones", "highways"],
        dataSortByTab: { data: ["outfallsId", "address"] },
        flatDataSort: "outfallsId",
        searchPlaceholder: "Search by ID or Street Address",
        searchByPropertyEnabled: true,
        defaultTab: "data",
        mapEnabledTabs: ["data", "todo"],
        toDoSubjects: ["muniOutfallRoutineInspection", "muniOutfallRoutineMaintenance"],
        listEnabledTabs: ["data", "todo", "insight"],
        showActionButtonsTabs: ["data", "todo"],
        defaultImportLayer: "outfalls",
        reportingDataGroup: "muni outfall",
        exportsEnabled: ["excel", "shapefile"],
        defaultExportType: "excel",
        isGisDataView: true,
        offline: ResourceController.get("muniOutfalls").getOfflineCount() > 0,
        offlineTodos: true, // @TODO: remove when all offline data views use G2
        filterTemplate: "filters/muniOutfallsFilters.njk",
        filterModule: MuniOutfallsFilters,
        resetTodoFiltersModule: MuniOutfallsFilters,
      },
      scenarios: {
        name: "Scenario Planning",
        defaultTab: "insight",
        mapEnabledTabs: ["result"],
        topLevelDataSort: ["rw", "ud", "uc"],
        hideMapListToggle: { data: true, insight: true, result: true },
        listEnabledTabs: ["data", "insight"],
        defaultLayers: ["scenarios", "telrResult"],
        associatedG2Layer: "telrResultBmp",
        hasAssociatedG2Layer: true,
        searchByPropertyEnabled: true,
        searchPlaceholder: "Search asset ID",
        dataSortByTab: {
          data: [
            "scenarioAssetId",
            "drainageArea",
            "imperviousAreaTreated",
            "typeAbbreviation",
            "bmpPhase",
          ],
        },
        flatDataSort: "scenarioAssetId",
        actionButton: {
          offline: false,
          buttonText: "Run TELR",
          dataAction: "run-telr-scenario",
          disabled: false,
          defaultHidden: UserPermissions.getPermission("can_run_telr") ? false : true,
          permissionKey: "can_run_telr",
        },
        showActionButtonsTabs: ["insight"],
        filterTemplate: "filters/scenarioFilters.njk",
        filterModule: ScenarioFilter,
        scenarioDropdown: [
          { value: "baseline-runoff", name: "Runoff - Baseline" },
          { value: "current-runoff", name: "Runoff - Current" },
          { value: "potential-runoff", name: "Runoff - Potential" },
          { value: "divider", name: "" },
          { value: "baseline-particulate", name: "Particulates - Baseline" },
          { value: "current-particulate", name: "Particulates - Current" },
          { value: "potential-particulate", name: "Particulates - Potential" },
        ],
        mapSummaryTableEnabledTabs: ["result"],
      },
      incidents: {
        name: "Incidents",
        actionButton: {
          offline: false,
          disabled: false,
          buttonText: "Add New Incident",
          dataAction: "add-new",
          permissionKey: "can_create_incident",
        },
        showActionButtonsTabs: ["data", "todo"],
        setToMapView: true,
        hideMapListToggle: { data: true, todo: false, insight: true, result: true },
        filterBarDisabledButtons: [],
        searchPlaceholder: "Search by Incident ID",
        flatDataSort: "incidentName",
        searchByPropertyEnabled: true,
        dataSortByTab: { data: ["incidentName"] },
        defaultLayers: ["incidents"],
        defaultTab: "data",
        topLevelDataSort: ["rw", "ud", "uc", "highways"],
        mapEnabledTabs: ["data", "todo"],
        toDoSubjects: ["incidentNew", "incidentOpen"],
        listEnabledTabs: ["todo", "insight"],
        reportingDataGroup: null,
        exportsEnabled: ["csv", "shapefile-geoserver", "kml", "geojson"],
        defaultExportType: "csv",
        activeTabChangeCallback: IncidentLayer.updateLegendByActiveTab,
        filterTemplate: "filters/incidentFilters.njk",
        filterModule: IncidentsFilters,
        hasAssociatedG2Layer: true,
      },
      muniCatchBasinView: {
        isGisDataView: true,
        liveDataView: "muni_catch_basins",
      },
      "muni-catch-basin": {
        isGisDataView: true,
        offline: ResourceController.get("muniCatchBasin").getOfflineCount() > 0,
        name: "Catch Basins",
        defaultLayers: ["muniCatchBasin"],
        defaultModalLayers: [defaultStormDrainLayer],
        setToMapView: true,
        flatDataSort: "muniCatchBasinId",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "ud", "uc", "maintenanceZones"],
        toDoSubjects: ["routineSiteVisit", "routineRepair", "catchBasinInventoryIncomplete"],
        dataSortByTab: { data: ["muniCatchBasinId", "address"] },

        dualActionButtons: [
          {
            buttonText: "Add New",
            dataAction: "add-new",
            permissionKey: "can_create_bmps",
            offline: false,
          },
          {
            buttonText: "Bulk Clean",
            dataAction: "bulk-clean",
            permissionKey: "can_inspect_bmps",
            offline: false,
          },
        ],
        searchPlaceholder: "Search by ID or Street Address",
        showActionButtonsTabs: ["data", "todo"],
        defaultTab: "data",
        mapEnabledTabs: ["data", "todo"],
        listEnabledTabs: ["data", "todo", "insight"],
        defaultImportLayer: "catchBasins",
        reportingDataGroup: "muni catch basin",
        esriLayer: "catch-basin",
        offlineTodos: true, // @TODO: remove when all offline data views use G2
        filterTemplate: "filters/muniCatchBasinFilters.njk",
        filterModule: MuniCatchBasinFilters,
        resetTodoFiltersModule: MuniCatchBasinFilters,
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
      },
      "muni-cleanout": {
        isGisDataView: true,
        name: "Cleanouts",
        defaultLayers: ["muniCleanout"],
        actionButton: {
          offline: false,
          buttonText: "Add New Cleanout",
          dataAction: "add-new",
          disabled: true,
          defaultHidden: true,
        },
        filterTemplate: "filters/muniCleanoutFilters.njk",
        filterModule: MuniCleanoutFilters,
        searchPlaceholder: "Search by ID or Street Address",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "maintenanceZones"],
        flatDataSort: "muniCleanoutId",
        dataSortByTab: { data: ["muniCleanoutId", "address"] },
        defaultTab: "data",
        hideMapListToggle: { data: false },
        mapEnabledTabs: ["data"],
        listEnabledTabs: ["data"],
        defaultImportLayer: "cleanouts",
        setToMapView: true,
        listActionButtonHide: true,
      },
      roads: {
        name: "Street Sweeping",
        hasAssociatedG2Layer: true,
        defaultLayers: Session.isNewDataDrivenRoadConditionGroup()
          ? ["streetSweepingRoute"]
          : ["roads"],
        dualActionButtons: [
          {
            offline: false,
            buttonText: "Add Sweeps",
            dataAction: "bulk-record-sweep",
            permissionKey: "can_create_record_sweeps",
          },
          {
            offline: false,
            buttonText: "Route O&M",
            dataAction: "road-om",
            permissionKey: "can_edit_roads",
            defaultDisabled: Session.isDisabledRouteOmGroup(),
          },
        ],
        catchWithRoadCondition: true,
        filterBarDisabledButtons: [],
        topLevelDataSort: Session.isNewDataDrivenRoadConditionGroup()
          ? ["sweepingNetworks"]
          : ["rw", "ud", "uc", "maintenanceZones"],
        getTopLevelDataSortCallback: Session.isNewDataDrivenRoadConditionGroup()
          ? StreetSweepingLayer.getTopLevelDataSort
          : null,
        flatDataSort: "uc",
        searchPlaceholder: "Search by ID or Street Address",
        defaultTab: "data",
        filterBarHiddenButtons: ["search", "filter", "item-count"],
        hideMapListToggle: { data: true },
        mapEnabledTabs: ["data"],
        listEnabledTabs: ["insight"],
        showActionButtonsTabs: ["data"],
        reportingDataGroup: "stormwater group",
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
        esriLayer: Session.isNewDataDrivenRoadConditionGroup() ? "streetSweepingRoute" : "roads",
        esriKey: Session.isNewDataDrivenRoadConditionGroup() ? "streetSweepingRoute" : "roadsOm",
        setToMapView: true,
        useSimpleOutfallG2Layer: true,
      },
      "muni-manhole": {
        isGisDataView: true,
        name: "Manholes",
        defaultLayers: ["muniManhole"],
        actionButton: {
          offline: false,
          buttonText: "Add New Manhole",
          dataAction: "add-new",
          disabled: true,
          defaultHidden: true,
        },
        listActionButtonHide: true,
        searchPlaceholder: "Search by ID or Street Address",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "ud", "uc", "maintenanceZones"],
        flatDataSort: "muniManholeId",
        dataSortByTab: { data: ["muniManholeId", "address"] },
        defaultTab: "data",
        hideMapListToggle: { data: false },
        mapEnabledTabs: ["data"],
        listEnabledTabs: ["data"],
        defaultImportLayer: "manholes",
        setToMapView: true,
        filterTemplate: "filters/muniManholesFilters.njk",
        filterModule: MuniManholeFilters,
      },
      "muni-bmp": {
        isGisDataView: true,
        name: "Structural BMPs",
        defaultLayers: ["muniBmp"],
        actionButton: {
          offline: false,
          buttonText: "Add New sBMP",
          dataAction: "add-new",
          defaultHidden: UserPermissions.getPermission("can_create_bmps") ? false : true,
          permissionKey: "can_create_bmps",
        },
        searchPlaceholder: "Search by ID or Street Address",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "maintenanceZones"],
        flatDataSort: "muniBmpId",
        dataSortByTab: { data: ["muniBmpId", "address"] },
        defaultTab: "data",
        mapEnabledTabs: ["todo", "data"],
        listEnabledTabs: ["todo", "data"],
        toDoSubjects: ["routineSiteVisitBmp", "routineRepairBmp"],
        defaultImportLayer: "sbmps",
        setToMapView: true,
        hideMapListToggle: { data: false },
        filterTemplate: "filters/muniBmpFilters.njk",
        filterModule: MuniBmpFilters,
        offlineTodos: true, // @TODO: remove when all offline data views use G2
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
      },
      "muni-culvert": {
        isGisDataView: true,
        name: "Culverts",
        defaultLayers: ["muniCulvert"],
        actionButton: {
          defaultHidden: true,
        },
        listActionButtonHide: true,
        searchPlaceholder: "Search by ID",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "maintenanceZones"],
        flatDataSort: "muniCulvertId",
        dataSortByTab: { data: ["muniCulvertId"] },
        defaultTab: "data",
        hideMapListToggle: { data: false },
        mapEnabledTabs: ["data"],
        listEnabledTabs: ["data"],
        defaultImportLayer: "culverts",
        setToMapView: true,
        filterTemplate: "filters/muniCulvertFilters.njk",
        filterModule: MuniCulvertFilters,
      },
      "muni-open-channel": {
        isGisDataView: true,
        name: "Open Channels",
        defaultLayers: ["muniOpenChannel"],
        actionButton: {
          defaultHidden: true,
        },
        listActionButtonHide: true,
        searchPlaceholder: "Search by ID",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "maintenanceZones"],
        flatDataSort: "muniOpenChannelId",
        dataSortByTab: { data: ["muniOpenChannelId"] },
        defaultTab: "data",
        hideMapListToggle: { data: false },
        mapEnabledTabs: ["data"],
        listEnabledTabs: ["data"],
        defaultImportLayer: "open-channels",
        setToMapView: true,
        filterTemplate: "filters/muniOpenChannelFilters.njk",
        filterModule: MuniOpenChannelFilters,
      },
      "muni-pipes": {
        isGisDataView: true,
        name: "Pipes",
        defaultLayers: ["muniPipes"],
        actionButton: {
          defaultHidden: true,
        },
        listActionButtonHide: true,
        filterTemplate: "filters/muniPipesFilters.njk",
        filterModule: MuniPipesFilters,
        searchPlaceholder: "Search by ID",
        searchByPropertyEnabled: true,
        topLevelDataSort: ["rw", "maintenanceZones"],
        flatDataSort: "muniPipesId",
        dataSortByTab: { data: ["muniPipesId"] },
        defaultTab: "data",
        hideMapListToggle: { data: false },
        mapEnabledTabs: ["data"],
        listEnabledTabs: ["data"],
        defaultImportLayer: "pipes",
        setToMapView: true,
      },
      activities: {
        name: "Activities",
        defaultLayers: ["activities"],
        isGisDataView: true,
        searchByPropertyEnabled: true,
        searchPlaceholder: "Search activity name or address",
        actionButton: {
          offline: false,
          buttonText: "Add New Activity",
          dataAction: "add-new",
          permissionKey: "can_create_activity",
        },
        filterBarDisabledButtons: [],
        topLevelDataSort: ["rw", "ud", "uc", "highways"],
        flatDataSort: "activities",
        dataSortByTab: { data: ["activities"] },
        filterTemplate: "filters/activityFilters.njk",
        filterModule: ActivityFilters,
        defaultTab: "data",
        listEnabledTabs: ["data"],
        mapEnabledTabs: [],
        reportingDataGroup: "peo",
        defaultDataSort: "activities",
        hideMapListToggle: { data: true },
        exportsEnabled: ["excel"],
        defaultExportType: "excel",
      },

      // ESG
      properties: {
        name: "Properties",
        defaultDataSort: "rank",
        flatDataSort: "rank",
        get defaultLayers() {
          return [PageController.getConfig()?.layer?.name];
        },
        afterOpenInventory: OtherPropertiesLayer.show,
        afterCloseInventory: OtherPropertiesLayer.reset,
        defaultTab: "data",
        mapEnabledTabs: ["data"],
        dataSortByTab: {
          data: ["rank", "propertyName", "propertyIdentifier"],
        },
        searchPlaceholder: "Search by ID, name, or address",
        actionButton: {},
        isGisDataView: true,
      },
      "incentive-programs": {
        name: "Incentive Programs",
        defaultDataSort: "programName",
        flatDataSort: "programName",
        defaultLayers: ["incentivePrograms"],
        defaultTab: "data",
        mapEnabledTabs: [],
        dataSortByTab: { data: ["programName"] },
        searchPlaceholder: "Search by Program Name",
        actionButton: {},
        isGisDataView: true,
      },
    };
  };

  return { get };
};

module.exports = DataViewConfig();

const Session = require("../login/session");
const UserPermissions = require("../login/userPermissions");
const Tree = require("../tree");
const ToolSettings = require("../settings/toolSettings");
const FeatureFlag = require("../settings/featureFlag");
