"use strict";

var SubmitReport = function () {
  const formKey = "cover-letter";
  var loadingPromise;
  var submitFormInited = false;
  var coverLetterDropzone;

  var updateSubmitReport = async function () {
    if (Tree.get("activeTab") !== "submit") {
      return;
    }

    $(".submit-report").hide();
    if (Progeny.activeHasProgeny() && Progeny.activeGroupIsRegulator()) {
      RegionalSubmitReport.updateSubmitReportForRegional();
    } else if (Progeny.activeHasProgeny() && !Progeny.activeGroupIsRegulator()) {
      updateSubmitReportForMs4();
      RegionalView.setRegionalViewDom();
    } else if (Progeny.rootGroupHasProgeny() && Progeny.rootGroupIsRegulator()) {
      updateSubmitReportForRegionalMs4();
    } else {
      updateSubmitReportForMs4();
    }
  };

  var updateSubmitReportForMs4 = async function () {
    $("#ms4-submit-report").show();
    await _renderSubmitTable();
    ScheduleTicker.loadTimeline();
    ScheduleTicker.setTimeline("#navigation-header-container", null);
    $("#navigation-header-container .ticker-container")
      .css("width", "100%")
      .css("margin-left", "0%");
    loadSubmitReportListeners();
    showRunTelrButton();
    showUnsubmitReportButton();
  };

  var updateSubmitReportForRegionalMs4 = async function () {
    $("#ms4-submit-report").show();
    $("#navigation-header-container .ticker-container").hide();
    await _renderSubmitTable();
    loadSubmitReportListeners();
    _renderRegionalMs4SubmitReportHeader();
    $(".submit-table-container .icon-column").hide();
    RegionalView.setRegionalViewMs4Dom();
  };

  var _renderRegionalMs4SubmitReportHeader = function () {
    const groupId = Tree.get("activeGroup", "groupId");
    const groupName = Tree.get("activeGroup", "name");
    const year = AnnualReports.getSelectedYear();
    const overviewKpi = Tree.get("overviewKpi", "data", groupId, year);

    var title = `${groupName} Annual Report`;
    if (overviewKpi.submitted) {
      const subbmitedDate = DateTime.formatIsoString(overviewKpi.submitted.submittedDate);
      if (subbmitedDate) {
        title += ` Submitted on ${subbmitedDate}`;
      }
    }
    $("#ms4-submit-report").find(".submit-report-header-title").html(title).show();
  };

  var _renderSubmitTable = async function () {
    Table.expandSideBar();
    var submitTableData = await getSubmitTableData();
    var submitPanelData = getSubmitPanelData();
    var submitTable = nunjucks.render("report/submit/submitTable.njk", submitTableData);
    var submitPanel = nunjucks.render("report/submit/submitPanel.njk", submitPanelData);
    $(".submit-table-container").html(submitTable);
    $(".submit-panel-container").html(submitPanel);

    submitFormInited = false;
  };

  var getSubmitPanelData = function () {
    var displayToday = DateTime.getTodayDisplayDate({ zeroPadded: true });
    var year = AnnualReports.getSelectedYear();
    var targetReport = getTargetReport();
    var submittedReport = getLatestSubmittedReport();
    var daysToGo = calcDaysToGo(targetReport.deadline);
    var reportingStatus = getReportingStatus(targetReport, submittedReport, daysToGo);
    var lockIconClass, showSubmitSection;
    var submissionEnabled = _isSubmissionEnabled();
    var groupName = Tree.get("activeGroup", "name");
    var rootGroupIsRegulator = Progeny.rootGroupIsRegulator();
    var isAdmin = Session.isAdmin();

    if (reportingStatus === "submitted_locked") {
      lockIconClass = "locked-grey";
      showSubmitSection = false;
    } else if (reportingStatus === "not_submitted_locked") {
      lockIconClass = "locked-grey";
      showSubmitSection = false;
    } else if (reportingStatus === "past_due") {
      lockIconClass = "unlocked-red";
      showSubmitSection = true;
    } else if (reportingStatus === "due_soon") {
      lockIconClass = "unlocked-red";
      showSubmitSection = true;
    } else {
      lockIconClass = "unlocked-grey";
      showSubmitSection = true;
    }

    return {
      displayToday,
      year,
      targetReport,
      submittedReport,
      daysToGo,
      reportingStatus,
      lockIconClass,
      showSubmitSection,
      submissionEnabled,
      groupName,
      rootGroupIsRegulator,
      isAdmin,
    };
  };

  var getReportingStatus = function (targetReport, submittedReport, daysToGo) {
    var reportingStatus = "default";
    if (submittedReport?.submittedOn) {
      reportingStatus = "submitted_locked";
      $(".ticker-container").addClass("blue-text blurred-timeline");
    } else if (!submittedReport && targetReport.locked) {
      reportingStatus = "not_submitted_locked";
    } else {
      if (daysToGo < 90) {
        if (daysToGo < 0) {
          reportingStatus = "past_due";
        } else {
          reportingStatus = "due_soon";
        }
      } else {
        reportingStatus = "default";
      }
    }
    return reportingStatus;
  };

  var getTargetReport = function () {
    var reports = AnnualReports.getYearReports();
    var stormwaterReport = reports.find((report) => report.dataName === "stormwater group");
    return stormwaterReport;
  };

  var getLatestSubmittedReport = function () {
    const reports = getSubmittedReports();
    reports.sort(function (a, b) {
      return (
        DateTime.parseIsoDateString(b.submittedOn) - DateTime.parseIsoDateString(a.submittedOn)
      );
    });

    if (reports.length === 0) {
      return null;
    }

    return reports[0];
  };

  var getSubmittedReports = function () {
    const reports = AnnualReports.getYearReports().filter(function (report) {
      return report.submittedOn !== null;
    });

    return reports;
  };

  var calcDaysToGo = function (dueDateStr) {
    const DAY_MILLISECONDS = 86400000; //milliseconds in a day for converting epoch
    var today = new Date(Date.now());
    var dueDate = new Date(dueDateStr);
    return Math.floor((dueDate.valueOf() - today.valueOf()) / DAY_MILLISECONDS);
  };

  var getSubmitTableData = async function () {
    const submitConfigData = getSubmitConfigData();
    var category1 = submitConfigData.category1;
    var category2 = submitConfigData.category2;

    _addReportsToSubmitTableCategory(category1);
    _addReportsToSubmitTableCategory(category2);

    category1 = await _addProgressToSubmitTableCategory(category1);
    category2 = await _addProgressToSubmitTableCategory(category2);

    const isPastYear = _isPastYear();
    const submissionEnabled = _isSubmissionEnabled();

    return { category1, category2, isPastYear, submissionEnabled };
  };

  var getSubmitConfigData = function () {
    var category1 = null;
    var category2 = null;

    category1 = SubmitConfig.getProgramImpact();
    category1 = customizePermitsByGroup(category1);

    category2 = SubmitConfig.getProgramCompliance();
    category2 = customizePermitsByGroup(category2);

    return { category1, category2 };
  };

  var _addReportsToSubmitTableCategory = function (category) {
    const reports = AnnualReports.getYearReports();
    return category.map(function (item) {
      const report = reports.find(function (report) {
        return report.dataName === item.dataName;
      });

      if (
        !report ||
        (report.hidden && !["bmpProgram", "watershedProgram"].includes(item.dataProgram))
      ) {
        item.disabled = true;
        item.noReportData = true;
        return item;
      }

      _addLinkSummaryDataToSubmitTableItem(report, item);
      _addReportFieldsToSubmitTableItem(report, item);
    });
  };

  var _addLinkSummaryDataToSubmitTableItem = function (report, item) {
    if (AnnualReports.getSelectedYear() < 2020) {
      _setPre2020LinkSummaryProps(item);
    } else {
      if (report.linkSummaryFiles?.length) {
        item.hasLinkSummaryFiles = true;
        item.linkSummaryFiles = LinkSummaryModal.addAbsoluteUrlsToFiles(report.linkSummaryFiles);
      }
    }
  };

  var _setPre2020LinkSummaryProps = function (item) {
    // Only reporting years >= 2020 have link summary enabled
    if (item.linkSummaryEnabled) {
      item.disabled = true;
    }
    item.linkSummaryEnabled = false;
  };

  var _addReportFieldsToSubmitTableItem = function (report, item) {
    item.displayDeadline = report.displayDeadline;
    item.submittedOn = report.submittedOn;
    item.locked = report.locked;
    item.lockedReportDisabled = report.lockedReportDisabled;
    item.displayDataRangeFirst = report.displayDataRangeFirst;
    item.displayDataRangeLast = report.displayDataRangeLast;
    item.reportingYear = report.reportingYear;
    item.annualReportId = report.id;
  };

  var _addProgressToSubmitTableCategory = async function (category) {
    await _fetchOverviewKpiData();

    return category.map(function (item) {
      if (item.reportingYear) {
        const overviewKpi = _getOverviewKpiDataByYear(item.reportingYear);
        const kpiText =
          overviewKpi?.[item.dataProgram] || overviewKpi?.[Misc.camelToSnake(item.dataProgram)];

        if (kpiText) {
          item.progress = kpiText;
        }
      }
      return item;
    });
  };

  var _isSubmissionEnabled = function (year = AnnualReports.getSelectedYear()) {
    var targetReport = getTargetReport();
    if (
      (Progeny.rootGroupHasProgeny() && Progeny.rootGroupIsRegulator()) ||
      // (Progeny.rootGroupHasProgeny() && !Progeny.activeHasProgeny()) ||
      targetReport?.groupId !== Tree.get("activeGroup", "groupId")
    ) {
      return false;
    }

    var reports = AnnualReports.getReports();
    var lockedReportsInSelectedYear = reports.filter(
      (report) => report.reportingYear === year && report.locked,
    );

    return lockedReportsInSelectedYear.length === 0;
  };

  var getDefaultSelectedYear = function (allYears, isRegional = false) {
    const maxPastYear = allYears[1];
    if (!isRegional && maxPastYear && !is90DaysPastDueDate(maxPastYear)) {
      return maxPastYear;
    }
    return allYears[0];
  };

  var _isPastYear = function () {
    var currentYearSelection = AnnualReports.getSelectedYear();
    var maxReportingYear = getMaxReportingYear();
    return currentYearSelection < maxReportingYear;
  };

  var _isMaxPastYear = function () {
    var currentYearSelection = AnnualReports.getSelectedYear();
    var maxReportingYear = getMaxReportingYear();
    return currentYearSelection === maxReportingYear - 1;
  };

  var is90DaysPastDueDate = function (year = AnnualReports.getSelectedYear()) {
    var reports = AnnualReports.getReports();
    if (reports?.length) {
      var stormwaterDueDate = reports.find(
        (report) => report.reportingYear === year && report.dataName === "stormwater group",
      ).deadline;
      var now = Date.now();
      var dueDate = new Date(stormwaterDueDate);
      var dueDatePost90 = dueDate.setDate(dueDate.getDate() + 90);
      return now > dueDatePost90;
    }

    return false;
  };

  var _isNewYearDataCompleted = function () {
    var reports = AnnualReports.getReports();
    var latestStormwaterTrashReports = reports.filter(
      (report) =>
        report.reportingYear === getMaxReportingYear() &&
        (report.dataName === "stormwater group" || report.dataName === "trash group"),
    );
    return latestStormwaterTrashReports.length === 2;
  };

  var getMaxReportingYear = function () {
    var reports = AnnualReports.getReports();
    return Math.max.apply(
      Math,
      reports.map((report) => report.reportingYear),
    );
  };

  var getMaxSubmittedReportingYear = function () {
    var reports = AnnualReports.getReports();
    return Math.max.apply(
      Math,
      reports.filter((report) => report.submittedOn).map((report) => report.reportingYear),
    );
  };

  var loadSubmitReportListeners = function () {
    new SelectTable($("#submitReport .submit-table-container"), {
      onSelect: _updateInputSelection,
    });

    $("#submit-btn").off();
    $("#submit-btn").on("click", _submitReportClick);

    $(".confirm-submit").off();
    $(".confirm-submit").on("click", _confirmSubmit);

    $(".submit-congrats .close-button").off();
    $(".submit-congrats .close-button").on("click", _submitReportClose);

    $("#submitReport").on("click", ".nav-link", _navLinkClick);

    $("#submitReport").off("click", ".edit-link-summary-button", _showLinkSummaryModal);
    $("#submitReport").on("click", ".edit-link-summary-button", _showLinkSummaryModal);

    $("#submitReport").off("click", "#download-cover-letter-btn", downloadCoverLetterButtonClick);
    $("#submitReport").on("click", "#download-cover-letter-btn", downloadCoverLetterButtonClick);

    $("#submitReport").off("change", ".include-cover-letter-check", includeCoverLetterChange);
    $("#submitReport").on("change", ".include-cover-letter-check", includeCoverLetterChange);

    if (!submitFormInited) {
      const $submitPanelContainer = $(".submit-panel-container");
      Form.clearForm(formKey);

      Form.initializeAndLoadListeners($submitPanelContainer, formKey, { isMultiPart: true });
      coverLetterDropzone = Form.initializeDropzone(formKey, $submitPanelContainer, {
        fileChangeCallback: coverLetterFileChange,
      });

      submitFormInited = true;
    }
  };

  var downloadCoverLetterButtonClick = function () {
    const year = AnnualReports.getSelectedYear();

    ReportApiCalls.downloadReportCoverLetterFile(year);
  };

  var coverLetterFileChange = function (files) {
    var hasFileUploaded = files?.length;
    $(".include-cover-letter-check").toggleClass("disabled", hasFileUploaded);
    checkSubmitButton();
  };

  var includeCoverLetterChange = function () {
    $("#submitReport")
      .find(".single-file-upload-container .upload-button")
      .toggleClass("hidden", !this.checked);
    checkSubmitButton();
  };

  const _updateInputSelection = function ($input) {
    const dataName = $input.data("name");
    const isEnabled = $input[0].checked;

    _toggleDataNameInputs(dataName, isEnabled);
    checkSubmitButton();
  };

  var checkSubmitButton = function () {
    const reportCount = $(".submit-table-container input:checked:not([disabled])").length;
    if (reportCount > 0) {
      if (reportCount > 1) {
        $(".selected-report-count-header").text(reportCount + " reports selected");
      } else {
        $(".selected-report-count-header").text(reportCount + " report selected");
      }
    } else {
      $(".selected-report-count-header").text("0 report selected");
    }

    if (reportCount > 0 && checkCoverLetterFile()) {
      $("#submit-btn").removeClass("disabled");
    } else {
      $("#submit-btn").addClass("disabled");
    }
  };

  var checkCoverLetterFile = function () {
    var files = coverLetterDropzone.getFiles();
    var hasFileUploaded = files?.length;
    return !$(".include-cover-letter-check").is(":checked") || hasFileUploaded;
  };

  var _toggleDataNameInputs = function (dataName, isEnabled) {
    const inputs = $(
      `.submit-table-container input[data-name='${dataName}']:visible:not([disabled])`,
    );
    for (const input of inputs) {
      input.checked = isEnabled;
    }
  };

  var _submitReportClick = function () {
    var reportTitles = [];
    var selectedYear = AnnualReports.getSelectedYear();
    var targetReport = getTargetReport();

    $(".submit-table-container input:checked:not([disabled])").each((i, obj) => {
      const title = $(obj).parent().text().trim();
      reportTitles.push(title);
    });

    $("#submit-warning-modal").find(".year-span").html(selectedYear);

    $("#submit-warning-modal")
      .find(".date-range")
      .html(`${targetReport.displayDataRangeFirst} to ${targetReport.displayDataRangeLast}`);

    var files = coverLetterDropzone.getFiles();
    if (files?.length) {
      const file = files[0];
      $("#submit-warning-modal").find(".cover-letter-filename").text(file.name);
      $("#submit-warning-modal").find(".cover-letter").removeClass("default-hidden");
    }

    $("#submit-warning-modal").modal("show");
  };

  var _confirmSubmit = function () {
    $("#submit-warning-modal").modal("hide");

    $("#report-year").text(AnnualReports.getSelectedYear());
    $("#group-name").text(Tree.get("activeGroup", "group"));

    $("#submit-confirmation-modal").modal("show");

    const dataNames = new Set();
    $(".submit-table-container input:checked:not([disabled])").each((i, obj) => {
      dataNames.add($(obj).data("name"));
    });

    _submitReports([...dataNames]);
  };

  var _submitReports = async function (dataNames) {
    const selectedYear = AnnualReports.getSelectedYear();
    var files = coverLetterDropzone.getFiles();
    const formData = Form.toFormData({
      dataNames,
      year: selectedYear,
      files,
    });

    await ReportApiCalls.submitReports(formData);
    Actions.loadAnnualReporting(updateSubmitReport);
  };

  var _submitReportClose = function () {
    $("#submit-confirmation-modal").modal("hide");
  };

  const _navLinkClick = (e) => {
    const PageFunctions = require("../pageFunctions");
    const dataView = $(e.currentTarget).closest("tr").data("view");
    PageFunctions.goToPage(DataViewFunctions.getDefaultTab(dataView), dataView);
  };

  var _showLinkSummaryModal = function (e) {
    const rowData = $(this).closest("tr").data();
    const annualReportId = rowData["annualReportId"];
    const displayName = rowData["displayName"];
    const linkSummaryFiles = getLinkSummaryFiles(annualReportId);
    LinkSummaryModal.renderAndShow({ annualReportId, displayName, linkSummaryFiles });
  };

  var getLinkSummaryFiles = function (annualReportId) {
    var reports = AnnualReports.getReports();
    var report = (reports || []).find((report) => report.id === annualReportId);
    return report.linkSummaryFiles;
  };

  var _getOverviewKpiDataByYear = function (year) {
    const groupId = Tree.get("activeGroup", "groupId");
    const overviewKpi = Tree.get("overviewKpi", "data", groupId, year);
    return overviewKpi;
  };

  var _fetchOverviewKpiData = async function () {
    const currentSelectedYear = AnnualReports.getSelectedYear();
    const groupId = Tree.get("activeGroup", "groupId");

    const dataByYear = Tree.get("overviewKpi", "data", groupId, currentSelectedYear);
    if (!Tree.get("overviewKpi", "isFetching") && !dataByYear) {
      Tree.set(["overviewKpi", "isFetching"], true);
      loadingPromise = ReportApiCalls.getOverviewKpi(groupId, currentSelectedYear).then(
        function (data) {
          Tree.set(["overviewKpi", "data", groupId, currentSelectedYear], data);
          Tree.set(["overviewKpi", "isFetching"], false);
          return data;
        },
      );
    }
    return loadingPromise;
  };

  var showRunTelrButton = function () {
    if (Tree.get("is_2n_admin") && FeatureFlag.enabled("run-telr")) {
      const year = AnnualReports.getSelectedYear();
      if (year >= 2021 && year > getMaxSubmittedReportingYear()) {
        if ($("#run-telr").length === 0) {
          var html = nunjucks.render("report/runTelrButton.html", { year });
          $(".admin-container").append(html);
          $("#run-telr").off();
          $("#run-telr").on("click", runTelrClick);
        }
        return;
      }
    }
    $("#run-telr").remove();
  };

  var runTelrClick = async function () {
    if (Tree.get("is_2n_admin") && FeatureFlag.enabled("run-telr")) {
      Analytics.sendAction(["swTELR_link", "Run_swTELR"]);
      const groupId = Tree.get("activeGroup", "groupId");
      const year = AnnualReports.getSelectedYear();
      await ReportApiCalls.runTelrForGroup(groupId, year);
      showTelrQueueModal();
    }
  };

  var showUnsubmitReportButton = function () {
    if (
      Tree.get("is_2n_admin") &&
      Session.isTestingOrDemoAdminAccount() &&
      FeatureFlag.enabled("unsubmit-report") &&
      _isMaxPastYear() &&
      _isNewYearDataCompleted() &&
      !_isSubmissionEnabled()
    ) {
      if ($("#unsubmit-btn").length === 0) {
        const year = AnnualReports.getSelectedYear();
        const html = nunjucks.render("report/unsubmitReportButton.html", { year });
        $(".admin-container").append(html);
        $("#unsubmit-btn").off();
        $("#unsubmit-btn").on("click", unsubmitButtonClick);
      }
      return;
    }
    $("#unsubmit-btn").remove();
  };

  var unsubmitButtonClick = async function () {
    const year = AnnualReports.getSelectedYear();
    const groupName = Tree.get("activeGroup", "name");
    const groupId = Tree.get("activeGroup", "groupId");

    ModalFunctions.showConfirmWarningModal(
      null,
      async () => {
        await ReportApiCalls.unsubmitReports(groupId, year);
        window.location.reload();
      },
      "Cancel",
      "Confirm",
      `Unsubmit <b>${year}</b> reports for <b>${groupName}</b>? Click "Confirm" to unsubmit or "Cancel" to return.`,
    );
  };

  var showTelrQueueModal = function () {
    $("#telr-modal").modal("toggle");
  };

  var customizePermitsByGroup = function (programs) {
    const yearDropdownSelection = Tree.get("yearDropdownSelection");
    const groupId = Tree.get("activeGroup", "groupId");
    const permitSettingsByGroupId = PermitSettings.getPermitSettingsByGroupId(groupId);

    return programs.map((item) => {
      const newItem = Object.assign({}, item);

      if (yearDropdownSelection >= 2020) {
        newItem.displayName = PermitSettings.getDisplayPermitSectionByDataProgram(
          item.dataProgram,
          {
            includePermit: true,
          },
        );

        if (!newItem.displayName) {
          throw new Error(`No permit section name found for ${JSON.stringify(item)}`);
        }

        if (permitSettingsByGroupId) {
          const settings = permitSettingsByGroupId[newItem.dataProgram];

          if (settings) {
            if (settings.hide) {
              newItem.hide = true;
            }
            if (settings.show) {
              newItem.show = true;
            }
          }
        }
      }

      return newItem;
    });
  };

  return {
    updateSubmitReport,
    _isNewYearDataCompleted,
    _isSubmissionEnabled,
    _submitReportClick,
    _confirmSubmit,
    loadSubmitReportListeners,
    getDefaultSelectedYear,
    getSubmitConfigData,
    customizePermitsByGroup,
    getTargetReport,
    getLatestSubmittedReport,
    getReportingStatus,
    calcDaysToGo,
  };
};

module.exports = SubmitReport();

const Analytics = require("../../general/analytics");
const Tree = require("../../tree");
const DataViewFunctions = require("../dataViewFunctions");
const ReportApiCalls = require("../reportApiCalls");
const Actions = require("../actions");
const ScheduleTicker = require("./scheduleTicker");
const AnnualReports = require("../annualReports");
const SubmitConfig = require("../config/submitConfig");
const Session = require("../../login/session");
const LinkSummaryModal = require("./linkSummaryModal");
const ModalFunctions = require("../modals/modalFunctions");
const Progeny = require("../../login/progeny");
const RegionalSubmitReport = require("./regionalSubmitReport");
const DateTime = require("../dateTime");
const RegionalView = require("../regionalView");
const PermitSettings = require("../settings/permitSettings");
const Form = require("../../general/form");
const Misc = require("../misc");
const FeatureFlag = require("../../settings/featureFlag");
const SelectTable = require("../../general/selectTable");
const Table = require("../mapFunctions/table");
