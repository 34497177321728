class PortalApiCalls {
  static authenticate(secret) {
    return Network.asPromise(Network.api("POST", "/sso/portal", { secret }, { statusCode: null }));
  }

  static getLidSelfInspectionProject() {
    return Network.asPromise(Network.api("GET", "/portal/lid-self-inspection"));
  }

  static saveNewLidSelfInspection(data) {
    return Network.asPromise(Network.apiPostWithFormData("/portal/lid-self-inspection", data));
  }

  static saveLidSelfInspectionConfirmation(data) {
    return Network.asPromise(Network.api("POST", "/portal/lid-self-inspection/confirmation", data));
  }

  static downloadLidSelfInspectionPdf(data) {
    Downloader.fromUrl(`/portal/lid-self-inspection/download-pdf`);
  }

  static getIndcomFacilitySelfInspectionProject() {
    return Network.asPromise(Network.api("GET", "/portal/indcom-facility-self-inspection"));
  }

  static saveNewIndcomFacilitySelfInspection(data) {
    return Network.asPromise(
      Network.apiPostWithFormData("/portal/indcom-facility-self-inspection", data),
    );
  }

  static saveIndcomFacilitySelfInspectionConfirmation(data) {
    return Network.asPromise(
      Network.api("POST", "/portal/indcom-facility-self-inspection/confirmation", data),
    );
  }

  static downloadIndcomFacilitySelfInspectionPdf(data) {
    Downloader.fromUrl(`/portal/indcom-facility-self-inspection/download-pdf`);
  }

  static getFormSettings(data) {
    return Network.asPromise(Network.api("GET", "/portal/form-settings"));
  }
}

module.exports = PortalApiCalls;

const Network = require("../network");
const Downloader = require("../files/downloader");
