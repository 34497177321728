"use strict";

const MuniCatchBasinGeoServerLayer = function () {
  const layerName = "muniCatchBasin";
  const layerKey = "muniCatchBasinLayer";

  var createMarkerFunction = function (feature, latlng) {
    return L.marker(latlng, {
      icon: createIconFunction(feature),
    });
  };

  var createIconFunction = function (feature) {
    return MuniCatchBasinIcon.getIconMarker(
      feature.properties.score,
      feature.properties.routineMaintenanceDue,
      feature.properties.isCatchBasinHighPriority,
    );
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    const muniCatchBasinGeoServerLayer = new GeoServerMapLayer(
      layerName,
      layerKey,
      createMarkerFunction,
      createIconFunction,
    );
    GeoServerLayerFunctions.setLayerInstanceByMapId(layerName, mapId, muniCatchBasinGeoServerLayer);

    muniCatchBasinGeoServerLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    muniCatchBasinGeoServerLayer.loadTreeUpdateListenersForMap(map, mapLayers, mapId);
  };

  var loadLayer = function (mapId = "main") {
    GeoServerLayerFunctions.loadLayerByMapId(layerName, mapId);
  };

  var invalidateLayerData = function (loadingScreen = true) {
    GeoServerLayerFunctions.invalidateLayerDataForMainMap(layerName, loadingScreen);
  };

  var organizeMuniLayerData = function (layerData) {
    return layerData.map(function (data) {
      return getOrganizedProperties(data.properties, data.geometry);
    });
  };

  var getOrganizedProperties = function (data, geometry) {
    const toDos = GeoServerLayerFunctions.prepareToDoData(data, {
      catch_basin_inventory_incomplete: "catchBasinInventoryIncomplete",
      routine_site_visit: "routineSiteVisit",
      routine_repair: "routineRepair",
    });
    RoutineMaintenanceFormTable.processRepairAdditionalInfo(toDos.routineRepair.additionalInfo);

    return {
      condition: data.condition,
      id: data.id,
      idbmp: data.id,
      idBmp: data.id,
      groupId: data.group_id,
      address: data.address,
      bmpName: data.bmp_id,
      geometry: geometry,
      bmpTypeObj: {
        id: data.bmp_type,
        sortOrder: data.bmp_type,
        name: BmpFcsFunctions.getBmpTypeNameByNumber(data.bmp_type),
      },
      bmpDisplayType: BmpFcsFunctions.getBmpTypeNameByNumber(data.bmp_type),
      bmpType: data.bmp_type,
      catchBasinPriority: data.cof_final_text,
      cleanout: data.cleanout,
      drainageAreaAcres: data.bmp_drainarea,
      facility: data.facility,
      fullAddress: Misc.getFullMailingAddress(data.address, data.city, data.state, data.zip),
      imperviousAreaAcres: data.bmp_imparea,
      inletType: data.inlet_type,
      installationDate: data.bmp_creation,
      inProximityTo303dWaterbody: data.in_proximity_to_303d_waterbody,
      isCatchBasinHighPriority: ["high", "very-high"].includes(data.cof_final_text),
      displayCatchBasinPriority: getDisplayCatchBasinPriority(data.cof_final_text),
      isFcs: data.fcs,
      fcs: data.fcs,
      permissions: {
        canBeEdited: BmpFcsFunctions.canEditBmpFcs(data.id, data.phase),
        canCreateRoutineInspection: ToolSettings.getSetting("permissions", "can_inspect_bmps"),
      },
      phase: data.phase,
      implementerType: data.implementer_type,
      responsiblePartyMaintenance: data.responsible_party_maintenance,
      score: data.catch_basin_score,
      structureId: data.structure_identifier,
      totalDepth: data.total_depth,
      sump: data.sump,
      siphon: data.siphon,
      designDepth: data.bmp_designdepth,
      footprintMaterial: data.footprint_material,
      wallMaterial: data.wall_material,
      shape: data.shape,
      length: data.length,
      width: data.width,
      diameter: data.bmp_diam,
      maintenanceObservationStructuralCondition: data.maintenance_observation_structural_condition,
      routineMaintenanceDue: data.todo_is_routine_site_visit,
      routineRepairDue: data.todo_is_routine_repair,
      ...toDos,
      activeMaintenanceIssues: toDos.routineRepair?.additionalInfo ?? [],
      usefulLife: data.useful_life,
      attributed: data.attributed,
      pof: data.pof_text,
      cof: data.cof_final_text,
      catchment_name: data.catchment_name,
      drains_to_rw: data.drains_to_rw,
      drains_to_c: data.drains_to_c,
      zone_names: data.zone_names,
      routine: parseJsonIsoDateArray(data.routine_inspection_dates),
      repair: parseJsonIsoDateArray(data.repair_dates),
      enforcementLevel: toDos.routineRepair.additionalInfo
        ? GeoServerLayerFunctions.getAdditionalInfo(toDos.routineRepair.additionalInfo, "level")
        : null,
      correctiveAction: toDos.routineRepair.additionalInfo
        ? GeoServerLayerFunctions.getAdditionalInfo(toDos.routineRepair.additionalInfo, "key")
        : null,
      cleaned: parseJsonIsoDateArray(data.clean_dates),
      latestCatchBasinDate: DateTime.parseIsoDate(data.latest_catch_basin_date),
    };
  };

  var parseJsonIsoDateArray = function (isoDates) {
    if (!isoDates) {
      return [];
    }

    return JSON.parse(isoDates).map(function (isoDate) {
      return DateTime.parseIsoDate(isoDate);
    });
  };

  var getLayerData = async function (onlineFiltering = false, loadingScreen = true, options = {}) {
    const data = await GeoServerLayerFunctions.getFeaturesForLayer(
      layerName,
      onlineFiltering,
      loadingScreen,
      options,
    );
    return organizeMuniLayerData(data);
  };

  var getDisplayCatchBasinPriority = function (priority) {
    for (const option of BmpFcsInventoryConstants.catchBasinPriorityOptions) {
      if (option.value === priority) {
        return option.name;
      }
    }

    return null;
  };

  var getOfflineData = async function (idbmp) {
    return await ResourceController.get("muniCatchBasin").getById(idbmp);
  };

  return {
    loadLayerListenersForMap,
    loadLayer,
    invalidateLayerData,
    organizeMuniLayerData,
    getLayerData,
    getOfflineData,
  };
};

module.exports = MuniCatchBasinGeoServerLayer();

const BmpFcsFunctions = require("../bmpfcs/bmpFcsFunctions");
const BmpFcsInventoryConstants = require("../bmpfcs/bmpFcsInventoryConstants");
const GeoServerLayerFunctions = require("../mapFunctions/geoServerLayerFunctions");
const GeoServerMapLayer = require("../mapFunctions/geoServerMapLayer");
const Misc = require("../misc");
const MuniCatchBasinIcon = require("./muniCatchBasinIcon");
const ResourceController = require("../offline/resourceController");
const ToolSettings = require("../settings/toolSettings");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
const DateTime = require("../dateTime");
