"use strict";

const IncidentsLayer = function () {
  const ApiCalls = require("../../apiCalls");
  const MapLayer = require("../../mapFunctions/mapLayer");
  const Tree = require("../../tree");

  const layerName = "incidents";
  const layerKey = "incidentsLayer";

  var createIncidentMarker = function (latlng, properties) {
    return L.marker(latlng, {
      pane: "outfallPane",
      icon: IncidentsIcon.getIncidentsMarkerIcon(
        properties,
        MapFunctions.resizeIconsOnZoom(
          Tree.get("zoomLevel"),
          40,
          20,
          "icon-text-large",
          "icon-text-medium",
          [6, 13, 18, 24, 28, 32, 36, 43],
        ),
      ),
    });
  };

  var createIcon = function (properties, zoom) {
    const options = MapFunctions.resizeIconsOnZoom(
      zoom,
      40,
      20,
      "icon-text-large",
      "icon-text-medium",
      [6, 13, 18, 24, 28, 32, 36, 43],
    );
    return IncidentsIcon.getIncidentsMarkerIcon(properties, options);
  };

  var onPopupOpen = function (data, $element) {
    var onPopupDelete = function () {
      deleteIncident(data.id, data.incidentName);
    };

    $element
      .off("click.popupDelete")
      .on("click.popupDelete", ".popup-delete-button", onPopupDelete);

    IncidentHistory.renderIncidentHistory(data.history, data.dateRequested);
  };

  var deleteIncident = function (id, name) {
    MessageModal.showConfirmWarningModal(
      `Delete incident ${name}? Click "Delete" to permanently delete the incident and all associated investigations, photos and files. Click "Cancel" to return.`,
      async function () {
        await ApiCalls.deleteIncident(id);
        incidentLayer.invalidateLayerData();
      },
      "Cancel",
      "Delete",
    );
  };

  var getOptionNameByValue = function (options, value) {
    return options.find((item) => item.value === value)?.name;
  };

  var _getIncidentPopupHtml = function (data) {
    const requestingTypeName = IncidentInventoryConstants.getRequestingTypeOptions().getObjectWith(
      "value",
      data.requestingType,
    )?.name;
    const requestingAffiliationName =
      IncidentInventoryConstants.getRequestingAffiliationOptions().getObjectWith(
        "value",
        data.requestingAffiliation,
      )?.name;

    const popupColor = IncidentsIcon.getIconColor(data.incidentStatus);
    const showNewInvestigationButton = data.incidentStatus === "new";
    const isFieldPersonnel = Session.isFieldPersonnel();
    return nunjucks.render("popups/incidentsPopup.njk", {
      data,
      popupColor,
      showNewInvestigationButton,
      requestingTypeName,
      requestingAffiliationName,
      incidentType: getOptionNameByValue(
        IncidentInvestigationConstants.getIncidentTypeOptions(),
        data.lastInvestigation?.incidentType,
      ),
      enforcementLevel: getOptionNameByValue(
        IncidentInvestigationConstants.getEnforcementLevelOptions(),
        data.lastInvestigation?.enforcementLevel,
      ),
      illicitActivity: getOptionNameByValue(
        IncidentInvestigationConstants.getIllicitActivityOptions(),
        data.illicitActivity,
      ),
      isFieldPersonnel,
    });
  };

  var loadLayerListenersForMap = function (map, mapLayers, mapId) {
    incidentLayer.loadToggledListenerForMap(map, mapLayers, mapId);
    incidentLayer.loadDataUpdatedListenerForMap(map, mapLayers, mapId);
    loadFrontEndFilterListernsForMap(mapId, "incidents");

    if (mapId === "main") {
      incidentLayer.loadTreeUpdateListenersForMap(map, mapLayers);
    }
  };

  var loadFrontEndFilterListernsForMap = function (mapId, layerName) {
    Tree.select("filters").on("update", async function () {
      if (Tree.get("dataView") === layerName) {
        laodLayerWResourceController(mapId, layerName, false);
      }
    });
  };

  var laodLayerWResourceController = async function (mapId, layerName, refreshData = true) {
    var dataPath = Actions.getLayerDataPathByMapId(mapId);
    var filters = Actions.getFiltersByMapId(mapId);

    ApiCalls.checkspatialboxes(filters);

    Tree.set(["layers", layerName, "isFetching"], true);

    const resource = ResourceController.get(layerName);
    const data = await resource.getAll({ filters, refreshData });
    Tree.set(["layers", layerName, dataPath], data);

    Tree.set(["layers", layerName, "isFetching"], false);
    if (mapId !== "modal") {
      const count = Tree.get(["layers", layerName, "data"]).length;
      Actions.setItemCount(count);
    }
  };

  var updateLegendByActiveTab = function (activeTab) {
    $(".legend-incidents .idde-incident-closed")
      .parents("tr")
      .toggle(activeTab !== "todo");
  };

  const incidentLayer = new MapLayer(layerName, layerKey, ApiCalls.getIncidents, {
    createMarkerFunction: createIncidentMarker,
    getPopupHtmlFunction: _getIncidentPopupHtml,
    getPopupDataFunction: ApiCalls.getIncidentPopup,
    onPopupOpenFunction: onPopupOpen,
    clusteringEnabled: Tree.get("dataView") === "dry-weather" ? false : true,
    itemCountEnabled: true,
    createIcon: createIcon,
  });

  return {
    ...incidentLayer,
    loadLayerListenersForMap,
    updateLegendByActiveTab,
    _getIncidentPopupHtml,
  };
};

module.exports = IncidentsLayer();

const IncidentHistory = require("./incidentHistory");
const IncidentInventoryConstants = require("./incidentInventoryConstants");
const IncidentInvestigationConstants = require("../incidentInvestigation/incidentInvestigationConstants");
const IncidentsIcon = require("./incidentsIcon");
const MessageModal = require("../../modals/messageModal");
const MapFunctions = require("../../mapFunctions/mapFunctions");
const Session = require("../../login/session");
const ResourceController = require("../../offline/resourceController");
const Actions = require("../../actions");
