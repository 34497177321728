"use strict";

function UploadValidator() {
  function validate(data) {
    var bytes = 1000000; // Add one MB buffer for non-file data
    var fileCount = 0;

    for (const value of getIterator(data)) {
      if (value instanceof Blob || value instanceof File) {
        bytes += value.size;
        fileCount++;
      }
    }

    validateMaxFileCount(fileCount);
    validateMaxUploadSize(bytes);
  }

  function getIterator(data) {
    if (data && data.values) {
      return data.values();
    }

    return [];
  }

  function validateMaxFileCount(fileCount) {
    const maxFileCount = 100;

    if (fileCount > maxFileCount) {
      throw new UploadLimitError(
        `Uploading ${fileCount} files exceeds the ${maxFileCount} file limit. Please remove ${
          fileCount - maxFileCount
        } files and try again.`,
      );
    }
  }

  function validateMaxUploadSize(bytes) {
    const maxUploadSize = 256000000;

    if (bytes > maxUploadSize) {
      throw new UploadLimitError(
        `Upload size ${(bytes / 1000000).toFixed(1)}MB exceeds the ${
          maxUploadSize / 1000000
        }MB limit. Please remove some files and try again.`,
      );
    }
  }

  function reportError(error, htmlErrorMessage = null) {
    const message = htmlErrorMessage
      ? addErrorMessageEnd(htmlErrorMessage)
      : getErrorMessage(error);

    reportUnexpectedUploadErrors(error);
    MessageModal.showSimpleWarningModal(message, true);

    return false;
  }

  function reportUnexpectedUploadErrors(error) {
    if (error instanceof UploadLimitError) {
      return;
    }

    Sentry.captureException(error);
  }

  function getErrorMessage(error) {
    let message = "The upload has failed.";

    if (error instanceof UploadLimitError) {
      return error.message;
    }

    if (error instanceof ApiError) {
      const response = error.xhrResponse;
      const errorReason = getApiErrorReason(response);
      message = `The upload has failed with error code ${response.status}${
        errorReason ? ` (${errorReason})` : ""
      }.`;
    }

    return addErrorMessageEnd(message);
  }

  function getApiErrorReason(response) {
    const reasonsByCode = {
      0: "network error",
      500: "server error",
    };
    let errorReason = response.statusText.toLowerCase();

    if (["", "error"].includes(errorReason)) {
      errorReason = null;
    }

    if (reasonsByCode[response.status]) {
      errorReason = reasonsByCode[response.status];
    }

    return errorReason;
  }

  function addErrorMessageEnd(message) {
    return (
      message +
      " Please try again later or contact us at <a href='mailto:customersuccess@2ndnaturewater.com'>customersuccess@2ndnaturewater.com</a> for assistance."
    );
  }

  return {
    validate,
    reportError,
  };
}

module.exports = UploadValidator();

const MessageModal = require("../modals/messageModal");
const ApiError = require("../errors/apiError");
const UploadLimitError = require("../errors/uploadLimitError");
const Sentry = require("@sentry/browser");
