"use strict";

const RoutineMaintenanceController = function () {
  const htmlPath = "modals/routineMaintenance";
  const formKey = "routine-maintenance";

  let $modal;
  let tabs;
  let dataStore;

  var showRoutineMaintenanceModal = function (newDataStore, readOnly) {
    dataStore = newDataStore;
    const initialData = dataStore.getInitial();

    tabs = getTabs(initialData);
    const renderData = mapTabProps(tabs);
    $modal = TabbedModal.showTabbedModal(formKey, renderData, tabs, htmlPath, {
      readOnly,
    });
    initializeForm();
    DryWeatherController.checkFlowTabs(dataStore.getWithUpdates());
    setInspectionHeaderText(initialData.bmpFcsInfo);
    setAdditionalModalHandlers();
    CommonModalFunctions.highlightEmptyFormFields(
      $modal,
      initialData.missingFields,
      RoutineMaintenanceConstants.mergeBmpFcsData(dataStore.getWithUpdates()),
    );
  };

  var getTabs = function (data) {
    const GeneralInfo = require("./routineMaintenanceTabs/routineMaintenanceGeneralInfo");
    const Condition = require("./routineMaintenanceTabs/routineMaintenanceCondition");
    const GeneralInfoBmp = require("./routineMaintenanceTabs/routineMaintenanceGeneralInfoBmp");
    const StructuralCondition = require("./routineMaintenanceTabs/routineMaintenanceStructuralCondition");
    const MaintenanceTasks = require("./routineMaintenanceTabs/routineMaintenanceTasks");
    const RoutineRepairFunctionalRepair = require("./routineMaintenanceTabs/routineRepairFunctionalRepair");
    const tabs = [];

    if (BmpFcsFunctions.isPriorityCatchBasinType(data.bmpFcsInfo.bmpType)) {
      tabs.push(GeneralInfo, Condition);
    } else {
      tabs.push(GeneralInfoBmp);

      if (
        data.structuralConditionInletIssues?.length ||
        data.structuralConditionOutletIssues?.length
      ) {
        tabs.push(StructuralCondition);
      }

      tabs.push(MaintenanceTasks);
    }

    DryWeatherController.addDryWeatherTabs(tabs, module.exports);

    if (data.activeMaintenanceIssues?.length) {
      tabs.splice(1, 0, RoutineRepairFunctionalRepair);
    }

    return tabs;
  };

  var mapTabProps = function (tabs) {
    const data = getRoutineMaintenanceData();

    const tabProps = tabs.reduce((props, tab) => {
      return $.extend(true, props, tab.getProps(data));
    }, {});
    return {
      ...data,
      ...tabProps,
      bmpType: data.bmpFcsInfo.bmpType,
    };
  };

  var getHeaderHtml = function (bmpFcsInfo) {
    return nunjucks.render("modals/routineMaintenance/routineMaintenanceHeader.njk", {
      ...bmpFcsInfo,
      assetDisplayName: BmpFcsFunctions.getAssetDisplayName(bmpFcsInfo.bmpType),
    });
  };

  var setInspectionHeaderText = function (bmpFcsInfo) {
    TabbedModal.setHeaderText(getHeaderHtml(bmpFcsInfo));
  };

  var setAdditionalModalHandlers = function () {
    TabbedModal.setCancelHandler(dataStore.discard);
    TabbedModal.setSaveHandler(saveButtonClick, false);
    TabbedModal.setFormHasUpdatesHandler(formHasUpdates);
    TabbedModal.goToPrevUrlOnClose();
  };

  var saveButtonClick = async function () {
    const initialData = dataStore.getInitial();

    TabbedModal.hide(false);
    const result = getResults(initialData);

    ResultModal.renderAndShow(
      [result],
      getHeaderHtml(initialData.bmpFcsInfo),
      () => {
        saveAndCloseModal();
      },
      TabbedModal.show,
      {
        extraButtons: [{ text: "Preview", type: "primary", callback: downloadPreviewPdf }],
        saveButtonText: "Save & Create PDF",
      },
    );

    return false;
  };

  var getResults = function (initialData) {
    const updatedData = getUpdateData(
      dataStore.getInitial(),
      dataStore.getForm(),
      true,
    ).routineMaintenance;

    return MaintenanceResult.getRoutineMaintenanceResultData(
      initialData.bmpFcsInfo.bmpName,
      updatedData?.maintenanceIssues,
      updatedData?.assetAccessible,
    );
  };

  var getUpdateData = function (initial, routineMaintenanceFormData, isNew) {
    const bmpFcsUpdateFormData = routineMaintenanceFormData.bmpFcsUpdates;
    delete routineMaintenanceFormData.bmpFcsUpdates;

    DryWeatherController.processInspectionFormData(initial, routineMaintenanceFormData);
    BmpFcsInventoryModalController.processBmpFormData(bmpFcsUpdateFormData, initial.bmpFcsInfo);

    if (isNew) {
      routineMaintenanceFormData = $.extend(true, {}, initial, routineMaintenanceFormData);
    } else {
      routineMaintenanceFormData = mergeIssueIdsIntoUpdates(initial, routineMaintenanceFormData);
    }

    routineMaintenanceFormData = RoutineMaintenanceFormTable.combineRoutineMaintenanceIssues(
      routineMaintenanceFormData,
      [
        "structuralConditionIssues",
        "structuralConditionInletIssues",
        "structuralConditionOutletIssues",
        "maintenanceTasksIssues",
        "activeMaintenanceIssues",
      ],
    );
    routineMaintenanceFormData.maintenanceIssues =
      RoutineMaintenanceFormTable.cleanRoutineMaintenanceIssues(
        routineMaintenanceFormData.maintenanceIssues,
        !isNew,
      );

    delete routineMaintenanceFormData.bmpFcsInfo;

    return {
      idbmp: initial.bmpFcsInfo.id,
      routineMaintenance: $.isEmptyObject(routineMaintenanceFormData)
        ? []
        : routineMaintenanceFormData,
      bmpFcsUpdates: $.isEmptyObject(bmpFcsUpdateFormData) ? [] : bmpFcsUpdateFormData,
    };
  };

  var saveAndCloseModal = async function () {
    await Form.getReadyToSavePromise(formKey);
    await dataStore.save();
    TabbedModal.hide();
  };

  var mergeIssueIdsIntoUpdates = function (initial, updates) {
    updates.id = initial.id;

    addIssueIds(initial, updates, "structuralConditionIssues");
    addIssueIds(initial, updates, "structuralConditionInletIssues");
    addIssueIds(initial, updates, "structuralConditionOutletIssues");
    addIssueIds(initial, updates, "maintenanceTasksIssues");
    addIssueIds(initial, updates, "activeMaintenanceIssues");

    return updates;
  };

  var addIssueIds = function (initial, updates, key) {
    for (const i in updates[key]) {
      updates[key][i].id = initial[key][i].id;
    }
  };

  var formHasUpdates = function () {
    return !Form.isFormEmpty(formKey);
  };

  var getRoutineMaintenanceData = function () {
    const data = dataStore.getWithUpdates();
    return RoutineMaintenanceConstants.mergeBmpFcsData(data);
  };

  var getExistingInspectionData = function () {
    return dataStore.getInitial();
  };

  var getFormData = function (path) {
    return Form.getFormDataAtPath(formKey, path);
  };

  var setFormData = function (path, data) {
    return Form.manuallySetFormDataField(formKey, path, data);
  };

  var unsetFormData = function (path) {
    return Form.manuallyUnsetField(formKey, path);
  };

  var downloadPreviewPdf = async function () {
    InspectionPreview.preview(async function () {
      await Form.getReadyToSavePromise(formKey);
      return getUpdateData(dataStore.getInitial(), dataStore.getForm(), dataStore.isNew());
    }, ApiCalls.previewMuniBmpPdf);
  };

  var initializeForm = function () {
    Form.initializeAndLoadListeners($modal, formKey, { dataStore });

    Form.initializeDropzone(formKey, $modal, {
      newFiles: dataStore.getForm().files,
      existingFiles: dataStore.getInitial().files ?? [],
      downloadCallback: ApiCalls.downloadBmpFcsFile,
    });
  };

  return {
    showRoutineMaintenanceModal,
    getRoutineMaintenanceData,
    getInspectionRenderData: getRoutineMaintenanceData,
    getExistingInspectionData,
    saveAndCloseModal,
    getUpdateData,
    getFormData,
    setFormData,
    unsetFormData,
  };
};

module.exports = RoutineMaintenanceController();

const BmpFcsFunctions = require("./bmpFcsFunctions");
const BmpFcsInventoryModalController = require("./bmpFcsInventoryModalController");
const CommonModalFunctions = require("../modals/commonModalFunctions");
const Form = require("../general/form");
const MaintenanceResult = require("../general/results/maintenanceResult");
const ResultModal = require("../general/resultModal");
const RoutineMaintenanceConstants = require("./routineMaintenanceConstants");
const RoutineMaintenanceFormTable = require("../general/routineMaintenanceFormTable");
const TabbedModal = require("../general/tabbedModal");
const DryWeatherController = require("../illicitDischarge/dryWeatherScreening/dryWeatherController");
const InspectionPreview = require("../general/inspectionPreview");
const ApiCalls = require("../apiCalls");
