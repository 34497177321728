"use strict";

function DataExportConfig() {
  var getTypeOptions = function () {
    const typeOptions = [
      {
        value: "excel",
        name: "Excel",
        enabled: true,
        handler: "excel",
      },
      {
        value: "shapefile",
        name: "Shapefile",
        enabled: true,
        handler: "postgis",
      },
      {
        value: "shapefile-geoserver",
        name: "Shapefile",
        enabled: true,
        handler: "geoserver",
      },
      {
        value: "csv",
        name: "CSV",
        enabled: true,
        handler: "geoserver",
      },
      {
        value: "geojson",
        name: "GeoJSON",
        enabled: true,
        handler: "geoserver",
      },
      {
        value: "kml",
        name: "KML",
        enabled: true,
        handler: "geoserver",
      },
      {
        value: "gml",
        name: "GML",
        enabled: true,
        handler: "geoserver",
      },
    ];

    return filterOptions(typeOptions);
  };

  var filterOptions = function (typeOptions) {
    const optionsNew = [];
    const exportsEnabled = DataViewFunctions.getCurrentDataViewProperty("exportsEnabled");
    if (exportsEnabled) {
      for (var i in typeOptions) {
        if (exportsEnabled.includes(typeOptions[i].value)) {
          optionsNew.push(typeOptions[i]);
        }
      }
    }
    return optionsNew;
  };

  return {
    getTypeOptions,
  };
}

module.exports = DataExportConfig();

const DataViewFunctions = require("../dataViewFunctions");
