"use strict";

const Network = function () {
  const ajaxCache = {};

  var api = function (method, path, data = null, additionalArgs = {}) {
    assertNotTesting(path);

    UploadValidator.validate(data);

    return $.ajax({
      url: getApiUrl(path),
      type: method,
      data: data,
      headers: getHeaders(),
      statusCode: {
        401: onInvalidToken,
        403: onInvalidToken,
      },
      ...additionalArgs,
    });
  };

  var cacheFirstApi = function (method, path, data = null, additionalArgs = {}) {
    const cacheKey = JSON.stringify({ url: getApiUrl(path), method, data });

    if (cacheKey in ajaxCache) {
      return ajaxCache[cacheKey];
    }

    return api(method, path, data, additionalArgs).done(function (responseData, status, jqHXR) {
      ajaxCache[cacheKey] = jqHXR;
    });
  };

  var getApiUrl = function (path) {
    return Config.get().apiUrl + path;
  };

  var apiPostWithFormData = function (path, formData, progressCallback = null, options = {}) {
    if (!(formData instanceof FormData)) {
      formData = Form.toFormData(formData);
    }

    return api("post", path, formData, {
      contentType: false,
      processData: false,
      xhr: addXhrProgress(progressCallback),
      ...options,
    });
  };

  var onInvalidToken = function () {
    SingleSignOn.warnAndRefresh(
      `Your login has expired and your last action may not have saved. You will be redirected to the sign in screen.`,
    );
  };

  async function fetchFrom(url, options) {
    let response;

    try {
      response = await fetch(url, options);
    } catch (e) {
      response = { ok: false, status: 0, body: null };
    }

    if (!response.ok) {
      throw new NetworkError(response.status, response.body);
    }

    return response;
  }

  var postTo = function (url, dataObject, progressCallback = () => {}) {
    assertNotTesting(url);
    UploadValidator.validate(dataObject);

    return asPromise(
      $.ajax({
        url,
        type: "post",
        data: Form.toFormData(dataObject),
        contentType: false,
        processData: false,
        xhr: addXhrProgress(progressCallback),
        ...noLoadingScreen(),
      }),
    );
  };

  function assertNotTesting(path) {
    if (window.jestTestsRunning === true && !$.ajax._isMockFunction) {
      throw Error(
        `Trying to send an Ajax call to ${path} from a test. Api calls must be mocked in tests.`,
      );
    }
  }

  function getHeaders() {
    return {
      Authorization: "Bearer " + TokenManager.loadToken(),
      "X-Requested-With": "XMLHttpRequest",
      "X-2N-Tool": ToolFunctions.getActiveTool(), // our non-standard HTTP header
    };
  }

  function addXhrProgress(progressCallback) {
    return function () {
      var myXhr = $.ajaxSettings.xhr();
      if (progressCallback && myXhr.upload) {
        myXhr.upload.addEventListener("progress", progressCallback, false);
      }
      return myXhr;
    };
  }

  function noLoadingScreen(loadingScreen = false) {
    return { global: loadingScreen };
  }

  function noLoadingScreenWhenFalse(loadingScreen, options = {}) {
    if (loadingScreen) {
      return options;
    }

    return { ...options, ...noLoadingScreen() };
  }

  var asPromise = function (apiRequest) {
    return new Promise((resolve, reject) => {
      return apiRequest.done(resolve).fail((response) => {
        reject(new ApiError(response));
      });
    });
  };

  return {
    api,
    getHeaders,
    getApiUrl,
    apiPostWithFormData,
    cacheFirstApi,
    fetchFrom,
    postTo,
    noLoadingScreen,
    noLoadingScreenWhenFalse,
    asPromise,
  };
};

module.exports = Network();

const ApiError = require("./errors/apiError");
const Config = require("./config");
const Form = require("./general/form");
const NetworkError = require("./errors/networkError");
const TokenManager = require("./login/tokenManager");
const ToolFunctions = require("./toolFunctions");
const UploadValidator = require("./general/uploadValidator");
const SingleSignOn = require("./login/sso");
