"use strict";

const IncidentInventoryConstants = function () {
  var getIncidentInventoryDefaults = function (asset = null) {
    const defaults = {
      locationInfo: {},
      generalInfo: {},
      requestingAffiliation: Session.isCdot() ? "dot-staff" : "ms4-staff",
    };

    if (asset) {
      defaults.asset = asset;
      defaults.requestingType = "dry-weather-screen";
    }

    return defaults;
  };

  var getRequestingTypeOptions = function () {
    return FormSettings.getDropdownOptions("requesting-type");
  };

  var getRequestingAffiliationOptions = function () {
    return FormSettings.getDropdownOptions("requesting-affiliation");
  };

  var addIncidentNewToDoDisplayNames = function (allData) {
    for (const data of allData) {
      data.requestingTypeDisplayName = getRequestingTypeOptions().getObjectWith(
        "value",
        data.requestingType,
      )?.name;
      data.requestingAffiliationDisplayName = getRequestingAffiliationOptions().getObjectWith(
        "value",
        data.requestingAffiliation,
      )?.name;
    }

    return allData;
  };

  var addIncidentToDoFilterOptions = function (props) {
    props.requestingAffiliationOptions = getRequestingAffiliationOptions();
    props.requestingTypeOptions = getRequestingTypeOptions();
    props.incidentTypeOptions = $.extend(
      [],
      IncidentInvestigationConstants.getIncidentTypeOptions(),
    );
    props.incidentTypeOptions.push({ name: "Unknown", value: "unknown" });

    return props;
  };

  var loadToDoListeners = function ($container) {
    $container.off("click", ".new-incident-investigation");
    $container.on("click", ".new-incident-investigation", newIncidentInvestigationClick);
  };

  var newIncidentInvestigationClick = function () {
    const incidentId = $(this).parents("tr").data("id");
    IncidentInvestigationController.loadIncidentInvestigation({ incidentId });
  };

  return {
    getIncidentInventoryDefaults,
    addIncidentNewToDoDisplayNames,
    addIncidentToDoFilterOptions,
    getRequestingTypeOptions,
    getRequestingAffiliationOptions,
    loadToDoListeners,
  };
};

module.exports = IncidentInventoryConstants();

const IncidentInvestigationConstants = require("../incidentInvestigation/incidentInvestigationConstants");
const Session = require("../../login/session");
const FormSettings = require("../../settings/formSettings");
const IncidentInvestigationController = require("../incidentInvestigation/incidentInvestigationController");
