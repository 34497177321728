"use strict";

const IncentiveProgramsController = function () {
  const render = async function (view = "list") {
    const options = {
      dataView: "incentive-programs",
      layer: {
        g2: true,
        name: "incentivePrograms",
        list: {
          defaultSortKey: "identifiedValue",
          defaultSortReversed: true,
          preparedDataFunction: _getPreparedData,
          template: "esg/incentivePrograms/incentiveProgramsList.njk",
          afterRenderFunction: _afterListRenderFunction,
          listeners: [],
        },
        map: { baseMap: "Gray" },
      },
      navToggle: {
        view: view.length === 0 ? "list" : view,
      },
      breadcrumbs: {
        previousPage: {
          page: "Portfolio",
          route: "/portfolio",
        },
        currentPage: "Cost Recovery Programs",
      },
      constants: ["regions", "states", "programTypes", "compTypes"],
      sideBar: {
        itemCount: true,
        isFlatList: false,
        dataFunction: () => {},
        onRowClick: () => {},
        filtersTemplate: "esg/incentivePrograms/incentiveProgramsFilters.njk",
      },
      afterRenderFunction: afterRenderFunction,
    };
    await PageController.render(
      "incentive-programs",
      "esg/incentivePrograms/incentivePrograms.njk",
      () => {},
      options,
    );
  };

  const _getPreparedData = function (programs) {
    _sortBy(programs);
    return {
      rows: programs,
      options: {
        programsTotal: programs?.length,
        swManagedTotal: programs?.sumValues("swManaged"),
        identifiedValueTotal: programs?.sumValues("identifiedValue"),
        pollManagedTotal: programs?.sumValues("pollManaged"),
        propertyCountTotal: programs?.sumValues("propertyCount"),
      },
    };
  };

  const _sortBy = function (programs) {
    const alphaSorter = SortUtilities.alphaSorter;
    const numberSorter = SortUtilities.numberSorter;
    const booleanSorter = SortUtilities.booleanSorter;
    var sortersDict = {
      programName: alphaSorter,
      programType: alphaSorter,
      compType: alphaSorter,
      compRecurring: booleanSorter,
      propertyCount: numberSorter,
      identifiedValue: numberSorter,
      swManaged: numberSorter,
      pollManaged: numberSorter,
    };
    DataList.sortWithDict(programs, sortersDict);
  };

  const _afterListRenderFunction = function (rowsAndOptions) {
    _renderSummaryBar(rowsAndOptions);
  };

  const _renderSummaryBar = function (rowsAndOptions) {
    const $summaryBar = $(".summary-bar");
    const html = nunjucks.render(
      "esg/incentivePrograms/incentiveProgramsListSummaryBar.njk",
      rowsAndOptions,
    );
    $summaryBar.html(html);
  };

  const afterRenderFunction = function () {
    NavToggle.showToggle(false);
    MultipleSelect.init();
    FilterSummary.enable({ esg: true });
  };

  return {
    render,
    _sortBy,
  };
};

module.exports = IncentiveProgramsController();

const PageController = require("./pageController");
const DataList = require("../mapFunctions/dataList");
const SortUtilities = require("../general/sortUtilities");
const MultipleSelect = require("../general/multipleSelect");
const FilterSummary = require("../filters/filterSummary");
const NavToggle = require("./navToggle");
