"use strict";

function Downloader() {
  var fromUrl = function (urlPath, additionalParams) {
    const form = document.createElement("form");
    form.action = Config.get().apiUrl + urlPath;
    form.method = "POST";
    form.style.display = "none";

    const input = document.createElement("input");
    input.name = "token";
    input.value = TokenManager.loadToken();
    form.appendChild(input);

    for (const paramKey in additionalParams) {
      const additionalInput = document.createElement("input");
      additionalInput.name = paramKey;
      additionalInput.value = additionalParams[paramKey];
      form.appendChild(additionalInput);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  var fromBlob = function (blob, fileName = "") {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
  };

  var listenToDropzone = function (dropzone, existingFiles, apiCallback) {
    dropzone.setDownloadHandler(function (filename) {
      const file = existingFiles.find((fileObj) => fileObj?.filename === filename);

      if (!file) {
        throw new Error(`No associated file ID found for filename "${filename}".`);
      }

      apiCallback(file.id);
    });
  };

  return { fromUrl, fromBlob, listenToDropzone };
}

module.exports = Downloader();

const Config = require("../config");
const TokenManager = require("../login/tokenManager");
