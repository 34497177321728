"use strict";

const ResultModal = function () {
  var $modal;
  var inited = false;
  var props;
  var saveResultHandler;
  var closeResultHandler;

  var init = function () {
    $modal = $("#routine-result-modal");
    loadDomListeners();
    inited = true;
  };

  var loadDomListeners = function () {
    $modal.off("click", ".save-button", saveButtonClick);
    $modal.on("click", ".save-button", saveButtonClick);
    $modal.off("click", ".cancel-button", cancelButtonClick);
    $modal.on("click", ".cancel-button", cancelButtonClick);
  };

  var loadExtraButtonListeners = function (extraButtons) {
    for (const i in extraButtons) {
      const button = extraButtons[i];

      if (button.callback) {
        const $button = $modal.find(`.modal-footer button[data-index="${i}"]`);
        $button.off("click");
        $button.on("click", button.callback);
      }
    }
  };

  /*
    @resultsData: [{ greenMessage: "text", redMessage: "text", issuesMessages: ["text", ...] }, ...]
  */
  var renderAndShow = function (
    resultsData,
    headerHtml,
    saveHandler,
    closeHandler,
    {
      saveButtonText = "Save",
      cancelButtonText = "Cancel",
      extraButtons = [], // { text: "Button Text", type: "primary", callback: () => {} }
    } = {},
  ) {
    if (!inited) init();
    saveResultHandler = saveHandler;
    closeResultHandler = closeHandler;
    props = { resultsData, headerHtml, extraButtons, saveButtonText, cancelButtonText };

    const html = nunjucks.render("modals/resultModal.njk", props);
    $modal.html(html);
    loadExtraButtonListeners(extraButtons);
    $modal.modal("show");
    Analytics.sendScreenView("modal", "routine-result-modal");
  };

  var cancelButtonClick = function () {
    if (closeResultHandler) {
      close();
      closeResultHandler();
    } else {
      throw new Error("Close handler was not configured");
    }
  };

  var saveButtonClick = function () {
    if (saveResultHandler) {
      saveResultHandler();
      close();
    } else {
      throw new Error("Save handler was not configured");
    }
  };

  var close = function () {
    // Clear content
    $modal.modal("hide");
    inited = false;
  };

  return {
    renderAndShow,
  };
};

module.exports = ResultModal();

const Analytics = require("./analytics");
